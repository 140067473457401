

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, FormSection, reduxForm } from 'redux-form'
import { renderField, renderTextareaField, renderSelectField, renderCheckbox, renderDateField, renderMembers, renderCombobox, renderMultiselect } from '../../shared/components/form-field/ReduxFormFields';
import { getDates } from '../../shared/utils/helpers.js';

// Actions
import { checkAuth } from '../../actions/sessionActions';
import { setStartDate, setEndDate, setBankId } from '../../actions/globalParamsActions';

class FilterForm extends React.Component {

  constructor(props) {
    super(props);
    this.handleFilterFieldChange = this.handleFilterFieldChange.bind(this);
    var dates = getDates();
    var bank_id = ((new URLSearchParams(window.location.search)).get("bank_id"));
    var booked_at_start = ((new URLSearchParams(window.location.search)).get("booked_at_start"));
    var booked_at_end = ((new URLSearchParams(window.location.search)).get("booked_at_end"));
    this.state = {
      bank_id: bank_id ? bank_id : null,
      booked_at_start: booked_at_start ? booked_at_start : dates.startDate,
      booked_at_end: booked_at_end ? booked_at_end : dates.endDate
    }
  } 

  componentDidMount() {
    const {setStartDate, setEndDate, setBankId} = this.props;
    setStartDate(this.state.booked_at_start);
    setEndDate(this.state.booked_at_end);
    setBankId(this.state.bank_id);
    if(this.state.bank_id!=null && this.state.bank_id!='0') {
      this.props.setFilterParams(this.state);
    }
  }

  handleFilterFieldChange(e) {
    const {setStartDate, setEndDate, setBankId} = this.props;
    if(e.target.name=='booked_at_start') {
      setStartDate(e.target.value);
    }
    if(e.target.name=='booked_at_end') {
      setEndDate(e.target.value);
    }
    if(e.target.name=='bank_id') {
      setBankId(e.target.value);
    }
    this.setState({[e.target.name]: e.target.value}, () => {
      if( (Date.parse(this.state.booked_at_start) <= Date.parse(this.state.booked_at_end)) && this.state.bank_id!=null && this.state.bank_id!='0') {
        this.props.setFilterParams(this.state);
        this.props.history.push({
            pathname: '/analytic/'+this.props.location.pathname.split('/').pop(),
            search: "?" + new URLSearchParams(this.state).toString()
        });
      }
      else {
        alert('Incorrect filter values!');
      }
    });
 } 

  render() {
    return(
      <div className="d-flex mb-3">
        <div className="form-group mr-2">
          <label>Choose Bank:</label>
          <select type="date" className="form-control" name="bank_id" value={this.state.bank_id} onChange={(e)=>{this.handleFilterFieldChange(e)}}>
            <option value="0">---</option>
            <option value="1">DKB</option>
          </select>
        </div>
        <div className="form-group mr-2">
          <label>Date from:</label>
          <input type="date" placeholder="Date from" className="form-control" value={this.state.booked_at_start} name="booked_at_start" onChange={(e)=>{this.handleFilterFieldChange(e)}}/>
        </div>
        <div className="form-group mr-2">
          <label>Date to:</label>
          <input type="date" placeholder="Date to" className="form-control" value={this.state.booked_at_end} name="booked_at_end" onChange={(e)=>{this.handleFilterFieldChange(e)}}/>
        </div>
      </div>
    )
  }
  
}
function mapStateToProps(state) {
  console.log('---state');
  console.log(state)
    return {
      isMobile: state.device.isMobile,
      session: state.session,
      globalParams: state.globalParams      
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({checkAuth, setStartDate, setEndDate, setBankId}, dispatch);
export default connect(
        mapStateToProps,
        mapDispatchToProps
        )(FilterForm);