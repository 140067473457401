import React from 'react';

class DataTableNoDataComponent extends React.Component  {
  render() {
    return (
      <div className="rdt_ProgressComponent"><div>There are no records to display</div></div>
    )
  }
}

export default DataTableNoDataComponent;