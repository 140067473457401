// Dependencies
import axios from 'axios';
import { API_URL, X_API_KEY, X_API_VERSION } from '../conf.js';
import { setCookie, getCookie } from '../shared/utils/cookies';
import { axiosInterceptors } from '../shared/utils/axiosInterceptors';

// Action Types
import {
  GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_ERROR,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_ERROR,
  ADD_COUNTRY_REQUEST,
  ADD_COUNTRY_SUCCESS,
  ADD_COUNTRY_ERROR,
  DELETE_COUNTRY_REQUEST,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_ERROR,
  UPDATE_COUNTRY_REQUEST,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_ERROR
} from './actionTypes';

// Base Actions
import { request, received, error } from '../shared/redux/baseActions';

const getCountry = (params) => dispatch => {
  dispatch(request(GET_COUNTRY_REQUEST));
  var code = '';
  if (typeof (params) !== 'undefined') {
    code = params;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: API_URL + '/countries/' + code,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_COUNTRY_SUCCESS, response.data)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_COUNTRY_ERROR));
    });
};

const getCountries = (params) => async dispatch => {
  dispatch(request(GET_COUNTRIES_REQUEST));
  var perPage = 10;
  var page = 1;
  console.log('GET_COUNTRIES');
  console.log(params);


  if (getCookie('_countries')) {
    const countries = JSON.parse(getCookie('_countries'))
    return await dispatch(received(GET_COUNTRIES_SUCCESS, {items: countries}))
  }


  var url = API_URL + '/countries?';

  if (typeof (params) != 'undefined') {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    console.log(queryString);
    url += queryString;
  }

  // Axios Data
  const axiosData = {
    method: 'GET',
    url: url,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);

  return axiosInstance(axiosData)
    .then(response => {
      setCookie('_countries', JSON.stringify(response.data['items']), 3)
      return dispatch(received(GET_COUNTRIES_SUCCESS, {items: response.data['items'], meta: response.data["meta"]}))
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_COUNTRIES_ERROR));
    });
};

const addCountry = (params) => dispatch => {
  dispatch(request(ADD_COUNTRY_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('ADD_COUNTRY');
  console.log(params);
  params.address_attributes = params.address
  params.contact_attributes = params.contact
  // Axios Data
  const axiosData = {
    method: 'POST',
    url: API_URL + '/countries',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: params
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(ADD_COUNTRY_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(ADD_COUNTRY_ERROR, err.response));
    });
};

const updateCountry = (params) => dispatch => {
  dispatch(request(UPDATE_COUNTRY_REQUEST));
  var code = '';
  if (typeof (params) !== 'undefined') {
    code = params;
  }
  console.log('UPDATE_COUNTRY');
  console.log(params);
  params = { ...params, ...{ address_attributes: params.address } }
  params = { ...params, ...{ contact_attributes: params.contact } }
  // Axios Data
  const axiosData = {
    method: 'PATCH',
    url: API_URL + '/countries/' + params.code,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: params
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(UPDATE_COUNTRY_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(UPDATE_COUNTRY_ERROR, err.response));
    });
};

const deleteCountry = (code) => dispatch => {
  dispatch(request(DELETE_COUNTRY_REQUEST));
  // Axios Data
  const axiosData = {
    method: 'DELETE',
    url: API_URL + '/countries/' + code,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(DELETE_COUNTRY_SUCCESS, response.data)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(DELETE_COUNTRY_ERROR, err.response));
    });
};

const resetCountry = () => dispatch => {
  dispatch(received(ADD_COUNTRY_SUCCESS, []))
};

export { getCountry, getCountries, addCountry, updateCountry, resetCountry, deleteCountry };
