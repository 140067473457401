import React, {Component} from 'react';
import { useHistory } from 'react-router-dom'
import debounce from 'lodash/debounce';
import { PropTypes, array, bool } from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form'
import Address from '../../shared/components/form-section/Address';
import Contact from '../../shared/components/form-section/Contact';
import Shipping from '../../shared/components/form-section/Shipping';
import Tabs from '../../shared/components/tabs/Tabs';

import { Form, Input, Button, Card, CardColumns, CardTitle, CardBody, CardHeader, Col, Row, Label } from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';

import SimpleLineIcon from 'react-simple-line-icons';
import {
  renderField,
  renderNumberField,
  renderTextareaField,
  renderSelectField,
  renderCheckbox,
  renderDateField,
  renderCombobox,
  renderMultiselect,
  renderRadioGroup
} from '../../shared/components/form-field/ReduxFormFields';
import { UploadFile } from '../../shared/components/form-field/UploadFile';

import { reset } from 'redux-form';
import { SubmissionError } from 'redux-form'
import { stopSubmit } from 'redux-form'

import { getCoupon, addCoupon, updateCoupon, resetCoupon } from '../../actions/couponActions';
import { getCategories } from '../../actions/categoryActions';
import { getCountries } from '../../actions/countryActions';
import { getBrands } from '../../actions/brandActions';
import { getBanks } from '../../actions/bankActions'
import { checkAuth } from '../../actions/sessionActions';
import { validate } from './validate';

import './CouponForm.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../shared/redux/configureStore';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Radio} from "@material-ui/core";

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

// Configuring Redux Store
const store = configureStore(window.initialState);

const messages = {
  emptyFilterList: 'No results. Try another query'
}

const renderDescription = (name, label) => {
  return (
      <Field
        name={name}
        type="text"
        component={renderTextareaField}
        label={label}
        className="form-control"
        asterisk="*"
      />
    )
}

const renderTitle = (name, label, asterisk) => {
  return (
      <Field
        name={name}
        type="text"
        component={renderField}
        label={label}
        className="form-control"
        asterisk={asterisk}
      />
  )
}

const withHistory = (Component) => {
  return props => <Component {...props} history={useHistory()} />
}

class CouponForm extends Component {

  constructor(props) {
    super(props);
    this.form = React.createRef();

    this.timer = null;
    this.state = {
      id: this.props.match.params.id,
      open: false,
      create: false,
      categories: null,
      countries: null,
      brands: null,
      selectedBrand: '',
      selectedUnit: '',
      brandSearchResult: null,
      countrySearchResult: null,
      categorySearchResult: null,
      bankSearchResult: null,
      targeted: null,

      onlineStatus: null,

      couponIsLoaded: false,
      descriptionEN: null,
      descriptionDE: null,
    }
    this.submit = this.submit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleUnitChange = this.handleUnitChange.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
    this.handleBrandSearch = this.handleBrandSearch.bind(this);
    this.handleCountrySearch = this.handleCountrySearch.bind(this);
    this.handleCategorySearch = this.handleCategorySearch.bind(this);
    this.handleTargetedChange = this.handleTargetedChange.bind(this);
    this.handleBankSearch = this.handleBankSearch.bind(this)
    this.handleTranslate = this.handleTranslate.bind(this);

    this.handleOnlineStatusChange = this.handleOnlineStatusChange.bind(this);

    this.handleChangeDescriptionEN = this.handleChangeDescriptionEN.bind(this)
    this.handleChangeDescriptionDE = this.handleChangeDescriptionDE.bind(this)
  }

  componentDidMount(props) {
    console.log('componentDidMount');
    console.log(props);
    const {getCoupon, addCoupon, updateCoupon, getCategories, getCountries, getBrands, getBanks } = this.props;
    if(this.state.id) {
      this.setState({couponIsLoaded: false})
      getCoupon(this.state.id)
      this.setState({couponIsLoaded: true})
    }
    else {
      this.setState({targeted: false});
      this.setState({couponIsLoaded: true})
    }
    getCategories();
    getCountries();
    getBrands();
    getBanks()
  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate')
    console.log(this.props);
    if(!this.props.match.params.id) {
      if(!this.state.create) {
        //this.props.dispatch(reset('couponForm'));  // requires form name
        this.props.initialize();
        store.dispatch(resetCoupon());
        this.setState({
          create: true,
          categories: null,
          countries: null,
          brands: null
        });
        this.setState({banks: null})
      }
    }
    if(this.props.match.params.id) {
      if(this.state.categories==null && this.props?.coupon?.categories) {
        this.setState({categories: this.props.coupon.categories.map(item=>item.id)});
        this.props.change('categories', this.props.coupon.categories.map(category => ({ id: category.id, name: category.name?.en || category.name })));
      }
      if(this.state.countries==null && this.props?.coupon?.countries) {
        console.log(this.props?.coupon?.countries ,'this.props?.coupon?.countries');

        this.setState({countries: this.props.coupon.countries.map(item=>item.code)});
        this.props.change('countries', this.props.coupon.countries.map(country => ({ code: country.code, name: country.name?.en || country.name })));
      }
      if(this.state.brands==null && this.props?.coupon?.brands) {
        this.setState({brands: this.props.coupon.brands.map(item=>item.id)});
        this.props.change('brands', this.props.coupon.brands.map(brand => ({ id: brand.id, name: brand.name })));
      }
      if(this.state.banks==null && this.props.coupon?.banks) {
        this.setState({banks: this.props.coupon.banks.map(item=>item.id)});
        this.props.change('banks', this.props.coupon.banks.map(bank => ({ id: bank.id, name: bank.name })));
      }
    }
  }

  handleBankSearch = debounce((searchTerms) => {
    this.bankSearch(searchTerms);
  }, 500);

  bankSearch(searchTerms) {
    console.log('handleBankSearch');
    var params = {}
    var search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getBanks(params)).then((result) => {
      var bankSearchResult = result.payload.items.map(bank => ({ id: bank.id, name: bank.name }));
      this.setState({bankSearchResult: bankSearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleChangeDescriptionEN(event) {
    event.persist()
    this.setState({ descriptionEN: event.target.value })
  }

  handleChangeDescriptionDE(event) {
    event.persist()
    this.setState({ descriptionDE: event.target.value })
  }


  async handleTranslate(e) {
    e.preventDefault();
    const de = this.state.descriptionDE

    const myHeaders = new Headers();
    myHeaders.append("accept", "*/*");

    const formdata = new FormData();
    formdata.append("key", "318ae2802b754541b3fd2a1ac403c525");
    formdata.append("text", de);
    formdata.append("target_lang", "en");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    };

    const request = await fetch(`https://brandportal.benfido.io/actions/base-module/api/translate`, requestOptions);

    const { translations } = await request.json();

    // this.form.current.querySelector('textarea[name="description.en"]').value = translations[0]?.text || ''

    this.setState({ descriptionEN: translations[0]?.text || '' })
  }

  // Save Coupon
  submit(values) {
    var action;

    console.log(this.state.descriptionEN, 'this.state.descriptionEN')
    console.log(this.state.descriptionDE, 'this.state.descriptionDE')

    values.description = {
      en: this.state.descriptionEN,
      de: this.state.descriptionDE
    }

    values.description_translations = {
      en: this.state.descriptionEN,
      de: this.state.descriptionDE
    }

    // values.description.en = this.state.descriptionEN

    if(typeof(values.active)=='undefined') {
      values.active = false;
    }

    if(values.categories!=null && values.categories.length>0) {
      values.category_ids = values.categories.map(item=>item.id)
    }
    else {
      values.category_ids = [null];
    }

    if(values.countries!=null && values.countries.length>0) {
      values.country_codes = values.countries.map(item=>item.code)
      values.country_ids = values.countries.map(item=> item.id)
    }
    else {
      values.country_codes = [null];
      values.country_ids = [null];
    }

    if(values.brands!=null && values.brands.length>0) {
      values.brand_ids = values.brands.map(item=>item.id)
    }
    else {
      values.brand_ids = [null];
    }

    if(this.state.banks!=null && values.banks.length>0) {
      values.bank_ids = values.banks.map(item=>item.id)
    }
    else {
      values.bank_ids = [null];
    }

    if(this.state.selectedBrand!=null) {
      values.brand_id = values.brand.id;
    }
    else {
      values.brand_id = null;
    }

    if (values.title.de && !values.title.en) {
      values.title.en = values.title.de
    }

    console.log(values , 'values');

    if(this.state.create) {
      action = addCoupon(values);
    }
    else {
      action = updateCoupon(values);
    }
    store.dispatch(action).then((result) => {
      if(typeof(result)!='undefined') {
        this.setState({open: true});
        setTimeout(() => {
          window.location.href = window.location.origin + '/coupons'
        }, 500)
      }
  	}).catch((error) => {
  		throw new SubmissionError({_error:  error });
  	});
  }

  handleClose() {
    this.setState({open: false});
  }

  handleUnitChange(e) {
    this.setState({selectedUnit: e=='' ? null : e});
  }

  handleBrandChange(e) {
    this.setState({selectedBrand: e=='' ? null : e});
    if(typeof(e.id)=='undefined') {
      this.handleBrandSearch(e);
    }
  }

  handleBrandSearch = debounce((searchTerms) => {
    this.brandSearch(searchTerms);
  }, 500);

  brandSearch(searchTerms) {
    console.log('handleBrandSearch');
    var params = {}
    var search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getBrands(params)).then((result) => {
      var brandSearchResult = result.payload.items.map(brand => ({ id: brand.id, name: brand.name }));
      this.setState({brandSearchResult: brandSearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleCountrySearch = debounce((searchTerms) => {
    this.countrySearch(searchTerms);
  }, 500);

  countrySearch(searchTerms) {
    console.log('handleCountrySearch');
    var params = {}
    var search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getCountries(params)).then((result) => {
      var countrySearchResult = result.payload.items.map(country => ({ code: country.code, name: country.name?.en || country.name }));
      this.setState({countrySearchResult: countrySearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleCategorySearch = debounce((searchTerms) => {
    this.categorySearch(searchTerms);
  }, 500);

  categorySearch(searchTerms) {
    console.log('handleCategorySearch');
    var params = {}
    var search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getCategories(params)).then((result) => {
      var categorySearchResult = result.payload.items.map(category => ({ id: category.id, name: category.name?.en || category.name }));
      this.setState({categorySearchResult: categorySearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleTargetedChange(e) {
    this.setState({targeted: e.target.checked});
  }

  handleOnlineStatusChange(onlineStatus) {
    console.log('---handleOnlineStatusChange')
    console.log(onlineStatus)
    this.setState({onlineStatus})
  }

  render() {

    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

    const { error, handleSubmit, pristine, reset, submitting, message, categories, countries, brands, banks, coupon } = this.props

    console.log('---render')


    if (
      (!this.state.descriptionEN && !this.state.descriptionDE) &&
      coupon?.description && this.state.couponIsLoaded && this.state.id
    ) {
      this.setState({ descriptionEN: coupon.description.en })
      this.setState({ descriptionDE: coupon.description.de })
    }


    var targeted = false;
    if(this.state.targeted==null && typeof(coupon)=='undefined') {
      targeted = false;
    }
    else if(this.state.targeted==null && typeof(coupon)!='undefined') {
      targeted = coupon.targeted ? true : false;
    }
    else {
      targeted = this.state.targeted;
    }
    console.log('---targeted');
    console.log(targeted);

    var selectedBrand = '';
    var selectedUnit = '';
    if(typeof(coupon)!='undefined') {
      selectedBrand = coupon.brand.id;
      selectedUnit = coupon.unit;
    }
    if(this.state.selectedBrand==null) {
      selectedBrand = '';
    }
    else if(this.state.selectedBrand!='') {
      selectedBrand = this.state.selectedBrand;
    }
    if(this.state.selectedUnit==null) {
      selectedUnit = '';
    }
    else if(this.state.selectedUnit!='') {
      selectedUnit = this.state.selectedUnit;
    }

    var categoriesArr = categories.map(category => ({ id: category.id, name: category.name?.en || category.name }));
    if(this.state.categorySearchResult!=null) {
      categoriesArr = this.state.categorySearchResult;
    }
    if(typeof(coupon)!='undefined' && typeof(coupon.categories)!='undefined' && coupon.categories!=null) {
      var couponCategories = coupon.categories;
      for(var i=0; i<couponCategories.length; i++) {
        var category = couponCategories[i];
        const found = categoriesArr.some(item => item.id === category.id);
        if(!found) {
          categoriesArr = categoriesArr.concat({id: category.id, name: category.name?.en || category.name});
        }
      }
    }
    categoriesArr = categoriesArr.concat({id: 0, name: '...'});


    console.log(countries, 'countries')
    var countriesArr = countries.map(country => ({ id: country.id, code: country.code, name: country.name?.en || country.name }));
    if(this.state.countrySearchResult!=null) {
      countriesArr = this.state.countrySearchResult;
    }
    if(typeof(coupon)!='undefined' && typeof(coupon.countries)!='undefined' && coupon.countries!=null) {
      var couponCountries = coupon.countries;
      for(var i=0; i<couponCountries.length; i++) {
        var country =  couponCountries[i];
        const found = countriesArr.some(item => item.id === country.id);
        if(!found) {
          countriesArr = countriesArr.concat({id: country.id, code: country.code, name: country.name?.en || country.name});
        }
      }
    }
    countriesArr = countriesArr.concat({code: '', name: '...'});

    var brandsArr = brands.map(brand => ({ id: brand.id, name: brand.name }));
    if(this.state.brandSearchResult!=null) {
      brandsArr = this.state.brandSearchResult;
    }
    if(typeof(coupon)!='undefined' && typeof(coupon.brands)!='undefined' && coupon.brands!=null) {
      var couponBrands = coupon.brands;
      for(var i=0; i<couponBrands.length; i++) {
        var brand = couponBrands[i];
        const found = brandsArr.some(item => item.id === brand.id);
        if(!found) {
          brandsArr = brandsArr.concat({id: brand.id, name: brand.name});
        }
      }
    }
    brandsArr = brandsArr.concat({id: 0, name: '...'});

    var banksArr = banks.map(bank => ({ id: bank.id, name: bank.name }));
    if(this.state.bankSearchResult!=null) {
      banksArr = this.state.bankSearchResult;
    }
    if(typeof(coupon)!='undefined' && typeof(coupon.banks)!='undefined' && coupon.banks!=null) {
      var couponBanks = coupon.banks;
      for(var i=0; i<couponBanks.length; i++) {
        var bank =  couponBanks[i];
        const found = banksArr.some(item => item.id === bank.id);
        if(!found) {
          banksArr =  banksArr.concat({id: bank.id, name: bank.name});
        }
      }
    }
    banksArr = banksArr.concat({id: 0, name: '...'});

    var brandsSingleSelectArr = brands.map(brand => ({ value: brand.id, text: brand.name }));

    let onlineStatus
    if (coupon != null && this.state.onlineStatus == null) {
      onlineStatus = coupon.online ? 'online' : 'offline'
    } else onlineStatus = this.state.onlineStatus

    const modules = {
      toolbar: [
        [{ 'header': [2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
      ],
    }

    return (
      <div className="animated fadeIn mt-5 form-sections">
        <h1 className="h3 text-uppercase">Coupon</h1>
        <form onSubmit={handleSubmit(this.submit)} ref={this.form}>
          <Row>
            <Col xs="12" lg="6">
              <Card>
                <CardBody>
                    <Card>
                      <CardBody>
                        <CardTitle className="d-inline">Offer</CardTitle>
                        <Field name="targeted" component={renderCheckbox} color="primary" label="Targeted" onChange={(event)=>this.handleTargetedChange(event)} />
                        {/*<Row>
                          <Col xs="12">
                            <div className="d-block d-sm-flex align-items-center">
                              <Label className="mr-3 pb-1">Valid</Label>
                              <Field name="online" component={renderCheckbox} color="primary" label="Online" />
                              <Field name="offline" component={renderCheckbox} color="primary" label="Offline" />
                            </div>
                          </Col>
                        </Row>*/}


                        <div className="flex align-items-center">
                          <Label className="mr-3">Valid</Label>
                          <Field name="onlineStatus" className="flex flex-row align-items-center" component={renderRadioGroup}
                                 defaultValue={onlineStatus}
                                 onChange={this.handleOnlineStatusChange}>
                            <FormControlLabel value="offline" control={<Radio color="primary" />} label="Offline" />
                            <FormControlLabel value="online" control={<Radio color="primary" />} label="Online" />
                          </Field>
                        </div>


                        <Row>
                          <Col xs="12">
                            <Field
                              name="website"
                              type="text"
                              component={renderField}
                              label="Website"
                              className="form-control"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            <Field
                              name="brand"
                              type="select"
                              component={renderCombobox}
                              label="Brand - type to search Brand e.g. Adidas"
                              data={brandsArr}
                              defaultValue={selectedBrand}
                              valueField="id"
                              textField="name"
                              filter={true}
                              className="p-0 pr-2"
                              onChange={(event) => this.handleBrandChange(event)}
                              disabled={[{id:0, name: '...'}]}
                              asterisk="*"
                            />
                          </Col>
                        </Row>
                        {this.state.couponIsLoaded &&
                          <Row className="mt-3 mb-3 translations">
                            <Col xs="12" className="pr-4 relative">
                              <Tabs tabs={[
                                {label: 'DE', items: [
                                  renderTitle('title.de', 'Title', '*'),
                                  // renderDescription('description.de', 'Description')
                                    <div className="mb-2 mr-2"><label>Description <span className="text-danger">*</span></label>
                                      <div>
                                        <textarea name="description.de"
                                                  value={this.state.descriptionDE}
                                                  placeholder="Description"
                                                  onChange={(e) => this.handleChangeDescriptionDE(e)}
                                                  className="form-control"/>
                                      </div>
                                    </div>,
                                ]},
                                {label: 'EN', items: [
                                  renderTitle('title.en', 'Title'),
                                  // renderDescription('description.en', 'Description')
                                    <div className="mb-2 mr-2"><label>Description <span className="text-danger">*</span></label>
                                      <div>
                                        <textarea name="description.en"
                                                  value={this.state.descriptionEN}
                                                  placeholder="Description"
                                                  onChange={(e) => this.handleChangeDescriptionEN(e)}
                                                  className="form-control"/>
                                      </div>
                                    </div>,
                                ]},
                              ]} />

                              <a className={'btn btn-primary'} style={{
                                position: 'absolute',
                                top: 0,
                                right: '1.5rem',
                                color: 'white',
                                cursor: 'pointer'
                              }}
                                 onClick={this.handleTranslate}
                              >Translate</a>
                            </Col>
                          </Row>
                        }
                        <Row className="mb-2">
                          <Col xs="12" lg="12">
                            <Label>Logo</Label><br />
                            <Field component={UploadFile} name='logo' accept='.jpg, .png' className="mb-3" />
                            {typeof(coupon)!='undefined' && coupon.logo!=null && !this.state.create &&
                              <img src={coupon.logo} className="img-thumbnail brnd-logo d-block" />
                            }
                          </Col>
                          <Col xs="12" lg="12">
                            <br />
                            <Label>Logo 2x</Label><br />
                            <Field component={UploadFile} name='logo_2x' accept='.jpg, .png' className="mb-3" />
                             {typeof(coupon)!='undefined' && coupon.logo_2x!=null && !this.state.create &&
                              <img src={coupon.logo_2x} className="img-thumbnail brnd-logo d-block" />
                            }
                          </Col>
                        </Row>

                        <Label>Countries - <em>Leave the field empty if you want the offer to be valid for all Countries.</em></Label>
                        <Field
                            name="countries"
                            component={renderMultiselect}
                            data={countriesArr}
                            value={[]}
                            valueField="code"
                            textField="name"
                            filter={false}
                            onSearch={this.handleCountrySearch}
                            messages={messages}
                            disabled={[{code:'', name: '...'}]}
                        />

                        <br /><br />
                        <Label className="mt-1">Type to search Country e.g. Germany</Label>


                        <Field name="featured" component={renderCheckbox} color="primary" label="Featured. Appears on top of lists." />
                        <Row>
                          <Col xs="6">
                            <Field
                              name="start_date"
                              type="date"
                              component={renderDateField}
                              label="Start date"
                              className="form-control"
                            />
                          </Col>
                          <Col xs="6">
                            <Field
                              name="end_date"
                              type="date"
                              component={renderDateField}
                              label="End date"
                              className="form-control"
                            />
                          </Col>
                        </Row><br /><br />
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle>Coupon fields</CardTitle>
                        <Row>
                          <Col xs="6">
                            <Field
                              name="code"
                              type="text"
                              component={renderField}
                              label="Code"
                              className="form-control"
                              asterisk="*"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6">
                            <Field
                              name="amount"
                              component={renderNumberField}
                              label="Amount in %"
                              className="form-control"
                              asterisk="*"
                            />
                          </Col>
                          {/*<Col xs="6">*/}
                          {/*  <Field*/}
                          {/*    name="unit"*/}
                          {/*    type="select"*/}
                          {/*    component={renderSelectField}*/}
                          {/*    options={[{value: '%', text: '%'}, {value: '€', text: '€'}]}*/}
                          {/*    defaultValue={selectedUnit}*/}
                          {/*    label="Unit"*/}
                          {/*    className="form-control"*/}
                          {/*    onChange={(event) => this.handleUnitChange(event.target.value)}*/}
                          {/*    asterisk="*"*/}
                          {/*  />*/}
                          {/*</Col>*/}
                        </Row>
                      </CardBody>
                    </Card>
                </CardBody>
              </Card>
            </Col>
            {targeted &&
            <Col xs="12" lg="6">
              <Card>
                <CardBody>
                  <Card>
                    <CardBody>
                      <CardTitle>Targeting</CardTitle>
                      <div className="d-flex align-items-center">
                        <div><label className="mb-2 mr-3 MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">Gender</label></div>
                          <Field name="male" component={renderCheckbox} color="primary" label="Male" />
                          <Field name="female" component={renderCheckbox} color="primary" label="Female" />
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="mb-2 mr-2">Age From</div>
                        <Field
                          name="start_age"
                          type="number"
                          component={renderField}
                          label={null}
                          className="form-control"
                        />
                        <div className="mb-2 mr-2">To</div>
                        <Field
                          name="end_age"
                          type="number"
                          component={renderField}
                          label={null}
                          className="form-control"
                        />
                      </div>

                      <br />
                      <Label>Banks - <em>Leave the field empty if you want the offer to be valid for all Banks.</em></Label>
                      <Field
                        name="banks"
                        component={renderMultiselect}
                        data={banksArr}
                        value={[]}
                        valueField="id"
                        textField="name"
                        filter={false}
                        onSearch={this.handleBankSearch}
                        messages={messages}
                        disabled={[{id:0, name: '...'}]}
                      />
                      <Label className="mt-1">Type to search Banks e.g. DKB</Label>
                      <br /><br />

                      <Label>Categories - <em>Leave the field empty if you want the offer to be valid for all Categories.</em></Label>
                      <Field
                        name="categories"
                        component={renderMultiselect}
                        data={categoriesArr}
                        value={[]}
                        valueField="id"
                        textField="name"
                        filter={false}
                        onSearch={this.handleCategorySearch}
                        messages={messages}
                        disabled={[{code:'', name: '...'}]}
                      />
                      <Label className="mt-1">Type to search Category e.g. Electronics</Label>
                      <br /><br />
                      <Label>Brands - <em>Leave the field empty if you want the offer to be valid for all Brands.</em></Label>
                      <Field
                        name="brands"
                        component={renderMultiselect}
                        data={brandsArr}
                        value={[]}
                        valueField="id"
                        textField="name"
                        filter={false}
                        onSearch={this.handleBrandSearch}
                        messages={messages}
                        disabled={[{id:0, name: '...'}]}
                      />
                      <Label className="mt-1">Type to search Brands e.g. Adidas</Label>
                      <br /><br />
                      <Row>
                        <Col xs="12" lg="6">
                          <Field
                            name="turnover_by_brand"
                            component={renderNumberField}
                            label="Min. turnover by brand in the last 6m"
                            className="form-control"
                          />
                        </Col>
                        <Col xs="12" lg="6">
                          <Field
                            name="frequency_by_brand"
                            component={renderNumberField}
                            label="Frequency by brand in the last 6m"
                            className="form-control"
                          />
                        </Col>
                      </Row>
                      <br/>
                      <Row>
                        <Col xs="12" lg="6">
                          <Field
                            name="turnover_by_category"
                            component={renderNumberField}
                            label="Min. turnover by category in the last 6m"
                            className="form-control"
                          />
                        </Col>
                        <Col xs="12" lg="6">
                          <Field
                            name="frequency_by_category"
                            component={renderNumberField}
                            label="Frequency by category in the last 6m"
                            className="form-control"
                          />
                        </Col>
                      </Row>
                      {/*
                      <Card>
                        <CardBody>
                          <CardTitle className="d-inline">Parameters</CardTitle>
                          <div className="d-flex mb-4">
                            <Field
                              name="vat"
                              type="number"
                              component={renderField}
                              label="VAT"
                              className="form-control"
                            />
                            <Field
                              name="tolerance"
                              type="number"
                              component={renderField}
                              label="Tolerance"
                              className="form-control"
                            />
                          </div>
                        </CardBody>
                      </Card>
                      */}
                      {/*<Shipping />*/}
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
            }
            <Col xs="12">
              {!!error && <div className="form-error mt-3">{error && <strong>{error}</strong>}</div>}
              <div className={'text-white'}>
                <Field name="active" component={renderCheckbox} label="Active" />
                <button type="submit" className="btn btn-primary mb-5">Submit</button>
              </div>
            </Col>
          </Row>
        </form>
        <Snackbar open={this.state.open} autoHideDuration={2000} message="Saved" onClose={() => this.setState({open: false})}
          action={
            <React.Fragment>
              <a className="text-white cursor-pointer mr-2" aria-label="close" onClick={this.handleClose}><i className="cil-x"></i></a>
            </React.Fragment>
          }
        />
      </div>
    )
  }
}

CouponForm.propTypes = {
  pristine: PropTypes.bool,
  message: PropTypes.string,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  coupon: PropTypes.object,
  brands: PropTypes.array,
  banks: PropTypes.array,
  categories: PropTypes.array,
  countries: PropTypes.array,
  session: PropTypes.any
};

function mapStateToProps(state) {
  console.log('STATE');
  console.log(state);
  if (
    !state?.form?.couponForm?.fieldsIsOverwritten &&
    ((state?.form?.couponForm?.values?.title_translations?.de && !state?.form?.couponForm?.values?.title?.de) ||
      (state?.form?.couponForm?.values?.title_translations?.en && !state?.form?.couponForm?.values?.title?.en))
  ) {
    state.form.couponForm.values.title = state.form.couponForm.values.title_translations
    state.form.couponForm.values.description = state.form.couponForm.values.description_translations

    state.form.couponForm.fieldsIsOverwritten = true
  }
    return {
      //formData: state.coupons,
      initialValues: typeof(state.couponForm)!=='undefined' ? state.couponForm.coupon : null,
      coupon: typeof(state.couponForm)!=='undefined' ? state.couponForm.coupon : null,
      categories: (typeof(state.categories)!=='undefined' && typeof(state.categories.result)!=='undefined') ? state.categories.result.items : [],
      countries: (typeof(state.countries)!=='undefined' && typeof(state.countries.result)!=='undefined') ? state.countries.result.items : [],
      brands: (typeof(state.brands)!=='undefined' && typeof(state.brands.result)!=='undefined') ? state.brands.result.items : [],
      banks: (typeof(state.banks)!=='undefined' && typeof(state.banks.result)!=='undefined') ? state.banks.result.items : [],
      session: state.session
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({getCoupon, addCoupon, updateCoupon, resetCoupon,  getCategories, getCountries, getBrands, getBanks, checkAuth}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
   form: 'couponForm', // a unique identifier for this form,
   enableReinitialize: true,
   validate
})(withHistory(CouponForm)))