// Action Types
import {
        GET_COUPON_SUCCESS, GET_COUPONS_SUCCESS, ADD_COUPON_SUCCESS
        } from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function couponReducer(state = initialState, action) {
  switch (action.type) {
    
    /*
    case GET_COUPON_SUCCESS:
    {
      const {payload: coupon} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        coupon
      });
    }
    break;
    */
    
    case GET_COUPONS_SUCCESS:
    {
      const {payload: result} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        result
      });
    }
    break;
    
    /*
    case ADD_COUPON_SUCCESS:
    {
      const {payload: coupons} = action;
      return getNewState(state, {
        coupons
      });
    }
    break;
    */
 
  default:
      return state;
}
};