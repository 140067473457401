import React, { Component } from 'react'
import { Card, CardBody } from 'reactstrap'

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

class MapSection extends Component {
	constructor(props) {
		super(props)
		const { brand } = props

		const { latitude = 0, longitude = 0 } = brand || {};

		console.log([latitude, longitude])

		this.state = {
			mapToken: process.env.REACT_APP_MAP_APIKEY || "pk.eyJ1IjoiYmVuZmlkbyIsImEiOiJja3Vnd3c4YXcxcm1lMnFtdGYweDNieGpxIn0.oTGgM2oP0SG15a5ZAzeidQ",
			map: null,
			marker: null,
			// zoom: 8,
			coordinates: [longitude, latitude],
			zip: null,
			city: null,
			street: null,
			country: null,

			mapboxgl: window.mapboxgl,
		}

		this.onSelectLocation = this.onSelectLocation.bind(this)
		this.initMarker = this.initMarker.bind(this)

		console.log('process.env')
		console.log(process.env)
	}

	componentDidMount() {
		const mapboxgl = this.state.mapboxgl
		mapboxgl.accessToken = this.state.mapToken

		const map = new this.state.mapboxgl.Map({
			container: 'brand-map',
			style: 'mapbox://styles/mapbox/light-v10',
			center: this.state.coordinates,
			zoom: 12,
		})

		const geocoder = new MapboxGeocoder({
			accessToken: mapboxgl.accessToken,
			mapboxgl: this.state.mapboxgl,
			marker: true,
			limit: 10,
			placeholder: 'Please enter the address of your branch here',
			getItemValue: this.onSelectLocation
		})

		map.addControl(geocoder)

		map.addControl(new mapboxgl.NavigationControl())

		this.setState({ map })
	}

	initMarker(coordinates) {
		const marker = new this.state.mapboxgl.Marker({
			draggable: true
		})
			.setLngLat(coordinates)
			.addTo(this.state.map)

		const onDragEnd = () => {
			const { lng, lat } = marker.getLngLat()
			this.setState({ coordinates: [lng, lat] })
		}

		marker.on('dragend', onDragEnd)

		this.setState({ marker })
	}

	onSelectLocation(location) {
		console.log(location);
		const coordinates = location.center

		if (!this.state.marker) {
			this.initMarker(coordinates)
		} else {
			this.state.marker.setLngLat(coordinates)
		}

		this.setState({ coordinates });

		(location.context || []).forEach(contextItem => {
			if (contextItem?.id?.includes('postcode')) this.setState({ zip: contextItem?.text })
			if (contextItem?.id?.includes('place')) this.setState({ city: contextItem?.text })
			if (contextItem?.id?.includes('country')) this.setState({ country: contextItem?.text })
		})

		if (location?.properties?.address) this.setState({ street: location?.properties?.address })

		return location.place_name
	}

	render() {
		return (
			<Card>
				<CardBody>
					<div id="brand-map" className={'brand-map'}></div>

					{this.state.coordinates &&
					<pre className="coordinates">
						Longitude: {this.state.coordinates[0]}
						<br/>
						Latitude: {this.state.coordinates[1]}
					</pre>}

					<div className={'flex justify-end'}>
						<button type="button" className="btn btn-primary mt-3"
										disabled={!this.state.coordinates}
										onClick={() => this.props.useLocationParams(
											this.state.coordinates,
											this.state.zip,
											this.state.city,
											this.state.street,
											this.state.country,
											)}
						>
							Use location
						</button>
					</div>
				</CardBody>
			</Card>
		)
	}
}

export default MapSection