// Action Types
import {GET_PAYMENT_SUCCESS, GET_PAYMENTS_SUCCESS, ADD_PAYMENT_SUCCESS, ADD_PAYMENT_ERROR, UPDATE_PAYMENT_ERROR} from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function paymentFormReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PAYMENT_SUCCESS: {
      const {payload: payment} = action;
      return getNewState(state, {payment});
      break;
    }
    case ADD_PAYMENT_SUCCESS: {
      const {payload: payment} = action;
      return getNewState(state, {payment});
      break;
    }
    case ADD_PAYMENT_ERROR: {
      const {payload: payment} = action;
      return getNewState(state, {payment});
      break;
    }
    case UPDATE_PAYMENT_ERROR: {
      const {payload: payment} = action;
      return getNewState(state, {payment});
      break;
    }
    default:
      return state;
  }
}
