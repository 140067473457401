import React, {Component} from 'react';
import { PropTypes, array, bool } from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form'
import Address from '../../shared/components/form-section/Address';
import Contact from '../../shared/components/form-section/Contact';

import { Form, Input, Button, Card, CardColumns, CardTitle, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';
import debounce from 'lodash/debounce'
import DropdownList from 'react-widgets/lib/DropdownList'

import SimpleLineIcon from 'react-simple-line-icons';
import {
  renderField,
  renderTextareaField,
  renderSelectField,
  renderCheckbox,
  renderDateField,
  renderRadioGroup,
  renderNumberField, renderCombobox
} from '../../shared/components/form-field/ReduxFormFields';

import { Radio, RadioGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { reset } from 'redux-form';
import { SubmissionError } from 'redux-form'
import { stopSubmit } from 'redux-form'

import { getTransaction, addTransaction, updateTransaction, resetTransaction } from '../../actions/transactionActions';
import { getBrands } from '../../actions/brandActions';
import { getBanks } from '../../actions/bankActions';
import { checkAuth } from '../../actions/sessionActions';
import { validate } from './validate';

import './TransactionForm.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../shared/redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

class TransactionForm extends Component {

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleBankChange = this.handleBankChange.bind(this)

    this.currency = React.createRef();
    this.commission = React.createRef();

    this.state = {
      id: this.props.match.params.id,
      open: false,
      create: false,
      brands: null,
      selectedBrand: '',
      banks: [],
      selectedBank: null,
      status: null,
      brandSearchResult: null,
      currency: '12412'
    }
  }

  componentDidMount(props) {
    console.log('componentDidMount');
    console.log(props);
    console.log(this.state.id);
    const {getTransaction, addTransaction, updateTransaction, getBrands } = this.props;
    if(this.state.id) {
      getTransaction({id: this.state.id, bank_id: this.props.match.params.bank_id});
    } else {
      const { getBanks } = this.props
      getBanks()
    }
    getBrands()
  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate')
    console.log(this.props);
    if(!this.props.match.params.id) {
      if(!this.state.create) {
        //this.props.dispatch(reset('transactionForm'));  // requires form name
        this.props.initialize();
        store.dispatch(resetTransaction());
        this.setState({create: true});
        this.setState({brands: null});
        this.setState({id: null });
      }
    } else {
      if(this.state.open) {
        window.location.href = `/transactions/transactions?bank_id=${this.props.match.params.bank_id}`;
      }
    }
  }

  handleBankChange(e) {
    console.log('---handleBankChange')
    if(e.id!='' && e.id>0) {
      this.setState({selectedBank: e.id})
    }
    else {
      this.setState( {
        selectedBank: null
      })
    }
  }

  handleBrandChange(e) {
    this.setState({selectedBrand: e=='' ? null : e})
    if(typeof(e.id)=='undefined') {
      this.handleBrandSearch(e)
    }
  }

  handleBrandSearch = debounce((searchTerms) => {
    this.brandSearch(searchTerms)
  }, 500)

  brandSearch(searchTerms) {
    console.log('handleBrandSearch')
    let params = {}
    let search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim())
    Object.assign(params, search);
    store.dispatch(getBrands(params)).then((result) => {
      let brandSearchResult = result.payload.items.map(brand => ({ id: brand.id, name: brand.name }));
      this.setState({brandSearchResult: brandSearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  // Save Transaction
 async submit(values) {
    console.log('values:')
    console.log(values)
    let action

    if(this.state.brands!=null) {
      values.brand_ids = values.brands.map(item=>item.id)
    }
    else {
      values.brand_ids = [];
    }




    values.bank_id = this.state.selectedBank

    if (this.state.id) {
      values.brand_id = values.brand
    } else {
      if(this.state.selectedBrand != null && values.brand) {
        values.brand_id = values.brand.id
      }
      else {
        values.brand_id = null;
      }
    }


    if(this.state.create) {
      console.log('addTransaction')
      console.log(values)
      values = {
        ...values,
        type: 'deposit',
        currency:  this.currency.current.value,
        commission : this.commission.current.value
      }
      action = addTransaction(values);
    }
    else {
      action = updateTransaction({ id: values.id, status: values.status }, this.props.match.params.bank_id);
    }

    store.dispatch(action).then((result) => {
      if(typeof(result)!='undefined') {
        if(this.state.create && result?.payload?.data) {
          const data = {...result?.payload?.data, status: values.status }
          store.dispatch(updateTransaction({
            id: values.id,
            status: values.status,
          }, values.bank_id))
          this.setState({open: true});
          window.location.href = `/transactions/transactions?bank_id=${values.bank_id}`;

        }

        if(!this.state.create) {
          this.setState({open: true});
          window.location.href = `/transactions/transactions?bank_id=${this.props.match.params.bank_id}`;
        }
      }
    }).catch((error) => {
      throw new SubmissionError({_error:  error })
    })
  }

  handleClose() {
    this.setState({open: false});
  }

  handleStatusChange(e) {
    this.setState({status: e=='' ? null : e});
  }



  render() {
    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

    const { error, handleSubmit, pristine, reset, submitting, message, brands, transaction, banks } = this.props

    console.log(this.props);

    let status = ''
    if(transaction!=null && this.state.status==null) {
      status = transaction.status;
    } else {
      status = this.state.status;
    }

    let brandsArr = brands
      .map(brand => ({ id: brand.id, name: brand.name }))
    if(this.state.brandSearchResult!=null) {
      brandsArr = this.state.brandSearchResult
    }
    brandsArr = brandsArr.concat({id: 0, name: '...'})

    const banksArr = (banks || []).map(bank => ({ id: bank.id, name: bank.name }))

    return (
      <div className="animated fadeIn mt-5">
        <form onSubmit={handleSubmit(this.submit)}>
          <Row>
            <Col xs="12" lg="12">
              <Card>
                <CardHeader><i className="fa fa-align-justify"></i> Transaction</CardHeader>

                <CardBody>
                  <Row className="form-sections">
                    {!this.state.id && <Col xs="12" lg="6">
                      <FormSection className="form-section">
                        <Card>
                          <CardBody>
                            <CardTitle className="d-inline">Transaction details</CardTitle>


                            <div className="mb-2 mr-2">
                              <label>Bank id <span className="text-danger">*</span></label>
                              <DropdownList filter
                                            placeholder="Choose Bank"
                                            data={[{id:'0', name: 'Choose Bank'}].concat(banksArr)}
                                            valueField="id"
                                            textField="name"
                                            defaultValue={{id: this.state.selectedBank!=null ? this.state.selectedBank: 0, name: (this.state.selectedBank!=null && banksArr.length>0) ? banksArr.find(b => b.id == this.state.selectedBank).name : 'Choose Bank'}}
                                            onChange={this.handleBankChange}
                                            className="mb-4"
                              />
                            </div>

                            {/*<Field*/}
                            {/*  name="type"*/}
                            {/*  component={renderField}*/}
                            {/*  label="Type"*/}
                            {/*  className="form-control"*/}
                            {/*  asterisk="*"*/}
                            {/*/>*/}
                            {/*<br/>*/}

                            <Row>
                              <Col xs="6">
                                <Field
                                  name="amount"
                                  component={renderField}
                                  label="Amount"
                                  type={'text'}
                                  className="form-control"
                                  asterisk="*"
                                />
                              </Col>
                              <Col xs="6">

                                {/*<Field*/}
                                {/*  name="currency"*/}
                                {/*  type={'text'}*/}
                                {/*  asterisk="*"*/}
                                {/*  component={renderField}*/}
                                {/*  label="Currency"*/}
                                {/*  defaultValue={'currency'}*/}
                                {/*  className="form-control"*/}
                                {/*/>*/}

                                <div className="mb-2 mr-2">
                                  <label>Currency <span
                                    className="text-danger">*</span>
                                  </label>
                                  <div>
                                    <input name="currency" ref={this.currency} type="text" defaultValue={'EUR'} className="form-control" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <br/>

                            <Field
                              name="brand"
                              type="select"
                              component={renderCombobox}
                              label="Brand - type to search Brand e.g. Adidas"
                              data={brandsArr}
                              defaultValue={this.state.selectedBrand}
                              valueField="id"
                              textField="name"
                              filter={true}
                              className="p-0 pr-2"
                              onChange={(event) => this.handleBrandChange(event)}
                              disabled={[{id: 0, name: '...'}]}
                              asterisk="*"
                            />
                            <br/>

                            <Field
                              name="booked_at"
                              type="date"
                              component={renderDateField}
                              label="Booked at"
                              className="form-control"
                              asterisk="*"
                            />
                            <br/>

                            <div className="mb-2 mr-2">
                              <label>Commission </label>
                              <div>
                                <input ref={this.commission} name="commission" type="text" className="form-control" min="-999999.99"
                                          max="999999.99" step="0.01" defaultValue={'0.00'}/>
                                </div>
                            </div>
                            <br/>

                            <Field
                              name="net_amount"
                              type="text"
                              component={renderField}
                              label="Net amount"
                              className="form-control"
                              asterisk="*"
                            />
                            <br/>

                            <Field
                              name="customer_id"
                              type="text"
                              component={renderField}
                              label="Customer id"
                              className="form-control"
                              asterisk="*"
                            />
                            <br/>

                            <Field
                              name="offer_id"
                              type="text"
                              component={renderField}
                              label="Offer id"
                              className="form-control"
                              asterisk="*"
                            />
                            <br/>

                            <Field
                              name="reference"
                              component={renderField}
                              label="Reference"
                              className="form-control"
                              asterisk="*"
                            />
                            <br/>

                            <Field
                              name="payment_id"
                              type="text"
                              component={renderField}
                              label="Payment id"
                              className="form-control"
                            />
                            <br/>

                            <Field
                              name="notes"
                              type="text"
                              component={renderTextareaField}
                              label="Notes"
                              className="form-control"
                            />

                            <Field
                                name="online"
                                type="checkbox"
                                component={renderCheckbox}
                                label="Online"
                                className="form-control"
                            />

                          </CardBody>
                        </Card>
                      </FormSection>
                    </Col>}


                    <Col xs="12" lg="6">
                      <FormSection className="form-section">
                        <Card>
                          <CardBody>
                            <CardTitle className="d-inline">Transaction status</CardTitle>
                            <Field name="status" className="mt-3" component={renderRadioGroup} defaultValue={status} onChange={this.handleStatusChange}>
                              {
                                  !this.props.match.params.id &&  <FormControlLabel value="pending" control={<Radio color="primary" />} label="Pending" />
                              }
                              <FormControlLabel value="confirmed" control={<Radio color="primary" />} label="Confirmed" />
                              <FormControlLabel value="declined" control={<Radio color="primary" />} label="Declined" />
                            </Field>
                          </CardBody>
                        </Card>
                      </FormSection>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12">
                      {!!error && <div className="form-error mt-3">{error && <strong>{error}</strong>}</div>}
                      <button type="submit" className="btn btn-primary mt-5"
                              disabled={(!this.state.selectedBank && !this.state.id) || pristine || submitting}>
                        Submit</button>
                    </Col>
                  </Row>
                </CardBody>


              </Card>
            </Col>
          </Row>
        </form>
        <Snackbar open={this.state.open} autoHideDuration={2000} message="Saved" onClose={() => this.setState({open: false})}
          action={
            <React.Fragment>
              <a className="text-white cursor-pointer mr-2" aria-label="close" onClick={this.handleClose}><i className="cil-x"></i></a>
            </React.Fragment>
          }
        />
      </div>
    )
  }
}

TransactionForm.propTypes = {
  pristine: PropTypes.bool,
  message: PropTypes.string,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  transaction: PropTypes.object,
  brands: PropTypes.array,
  session: PropTypes.any
};

function mapStateToProps(state) {
  console.log('STATE');
  console.log(state);
    return {
      //formData: state.transactions,
      initialValues: typeof(state.transactionForm)!=='undefined' ? state.transactionForm.transaction : null,
      transaction: typeof(state.transactionForm)!=='undefined' ? state.transactionForm.transaction : null,
      brands: (typeof(state.brands)!=='undefined' && typeof(state.brands.result)!=='undefined') ? state.brands.result.items : [],
      banks: (typeof(state.banks)!=='undefined' && typeof(state.banks.result)!=='undefined') ? state.banks.result.items : [],
      session: state.session
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({getTransaction, addTransaction, updateTransaction, resetTransaction, getBrands, getBanks, checkAuth}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
   form: 'transactionForm', // a unique identifier for this form,
   enableReinitialize: true,
   validate
})(TransactionForm))