import axios from 'axios';
import { GET_ACCOUNT_DETAILS_SUCCESS, GET_ACCOUNT_DETAILS_ERROR } from './actionTypes';
import { API_URL, X_API_KEY, X_API_VERSION } from '../conf.js';
import { getCookie, setCookie } from '../shared/utils/cookies';
import { createAccountToken } from '../shared/utils/account';
import { axiosInterceptors } from '../shared/utils/axiosInterceptors';

export function getAccountDetailsSuccess(payload) {
  return { type: GET_ACCOUNT_DETAILS_SUCCESS, payload: payload }
}

export function getAccountDetailsError() {
  return { type: GET_ACCOUNT_DETAILS_ERROR }
}

export function getAccountDetails() {
  return function (dispatch) {
    // Axios Data
    const axiosData = {
      method: 'GET',
      url: API_URL + '/account/details',
      headers: {
        //Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Api-Key': X_API_KEY,
        'X-Api-Version' : X_API_VERSION,
        'Authorization': 'Bearer ' + getCookie("_str")
      }
    };
    //const axiosInstance = axios.create();
    //axiosInterceptors(axiosInstance);
    return axios(axiosData)
      .then(response => {
        //setCookie("_ud", JSON.stringify(response.data), -1);
        setCookie("_ud", createAccountToken(response.data), -1);
        dispatch(getAccountDetailsSuccess(response.data));
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log('AXIOS ERROR:', err.response);
        dispatch(getAccountDetailsError());
      });
  };
}