// Action Types
import {GET_CASHBACK_SUCCESS, GET_CASHBACKS_SUCCESS, ADD_CASHBACK_SUCCESS, ADD_CASHBACK_ERROR, UPDATE_CASHBACK_ERROR} from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function cashbackFormReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CASHBACK_SUCCESS: {
      const {payload: cashback} = action;
      return getNewState(state, {cashback});
      break;
    }
    case ADD_CASHBACK_SUCCESS: {
      const {payload: cashback} = action;
      return getNewState(state, {cashback});
      break;
    }
    case ADD_CASHBACK_ERROR: {
      const {payload: cashback} = action;
      return getNewState(state, {cashback});
      break;
    }
    case UPDATE_CASHBACK_ERROR: {
      const {payload: cashback} = action;
      return getNewState(state, {cashback});
      break;
    }
    default:
      return state;
  }
}
