import React, {Component} from 'react';


export default function PaymentShow(props) {

    let {payment} = props;
    return (
      <div className="container payments-modal">
        <div className="row">
          <div className="col-12 col-sm-12">
            <h1>Payment details</h1>
          </div>
        </div>

        <div className="row card">
          <div className="col-12 col-sm-12">
            <table className="table mt-4">
              <tbody>
                <tr>
                  <th className="w-50">Brand:</th><td>{payment.brand!=null ? payment.brand.name : '---'}</td>
                </tr>
                <tr>
                  <th className="w-50">Description:</th><td>{payment.description}</td>
                </tr>
                <tr>
                  <th className="w-50">Notes:</th><td>{payment.notes}</td>
                </tr>
                <tr>
                  <th>Reference:</th><td>{payment.reference}</td>
                </tr>
                <tr>
                  <th>Amount:</th><td>{payment.amount}</td>
                </tr>
                <tr>
                  <th>Booked at:</th><td>{payment.booked_at ? payment.booked_at.substring(0,10) : '---'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>




        {payment.customer!=null &&
          <div>
            <div className="row mt-4">
              <div className="col-12 col-sm-12">
                <h1>Customer details</h1>
              </div>
            </div>
            <table className="table mt-2">
              <tbody>
              <tr>
                <th className="w-50">ID:</th><td>{payment.customer.id}</td>
              </tr>
              <tr>
                <th className="w-50">Role:</th><td>{payment.customer.role}</td>
              </tr>
              <tr>
                <th className="w-50">Locale:</th><td>{payment.customer.locale}</td>
              </tr>
              <tr>
                <th className="w-50">Active:</th><td>{payment.customer.active ? 'Yes':'No'}</td>
              </tr>
              <tr>
                <th>Created at:</th><td>{payment.customer.created_at ? payment.customer.created_at.substring(0,10) : '---'}</td>
              </tr>
              <tr>
                <th>Updated at:</th><td>{payment.customer.updated_at ? payment.customer.updated_at.substring(0,10) : '---'}</td>
              </tr>
              </tbody>
            </table>
          </div>
        }
      </div>
    )
}