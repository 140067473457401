import React, {Component} from 'react';
import { Link} from 'react-router-dom';
import { PropTypes, array, bool } from 'prop-types';
import { Form, Input, Button, Badge, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialCheckbox from '../../shared/components/material-checkbox/MaterialCheckbox';
import SimpleLineIcon from 'react-simple-line-icons';
import CountryCombobox from '../../shared/components/searchable-field/CountryCombobox';
import CategoryCombobox from '../../shared/components/searchable-field/CategoryCombobox';
import DataTableProgressComponent from '../../shared/components/widget/DataTableProgressComponent';
import DataTableNoDataComponent from '../../shared/components/widget/DataTableNoDataComponent';
import DropdownList from 'react-widgets/lib/DropdownList';
import 'react-widgets/dist/css/react-widgets.css';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import './Modal.css';
import './ContractTypes.css';
import ContractTypeShow from './ContractTypeShow';

import memoize from 'memoize-one';
import DataTable, { createTheme } from 'react-data-table-component';

import { checkAuth } from '../../actions/sessionActions';
import { getContractType, getContractTypes, deleteContractType } from '../../actions/contractTypeActions';

import { SubmissionError } from 'redux-form'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Redux Store
import configureStore from '../../shared/redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

const columns = memoize((handleEditClick, handleShowClick, handleDeleteClick) => [
  {
    name: 'ID',
    selector: 'id',
    sortable: true,
    center: true,
    grow: 0
  },
  {
    name: 'Name EN',
    selector: 'name',
    sortable: true,
    grow: 4
  },
  {
    name: 'Type',
    selector: 'parent_id',
    sortable: true,
    cell: (row) => row.parent_id==null ? 'Parent' : 'Subtype',
    grow: 2
  },
  {
    name: 'Sub Types',
    selector: 'sub_types',
    left: true,
    sortable: true,
    cell: (row) =>
      typeof(row.sub_types)!='undefined' ?
        <div  key={"st-"+row.id}
              className="d-flex flex-column w-100 mt-2 mb-2">
          {row.sub_types.map(st=>(
            <div>&raquo;
              <a href={"/contract-types/edit/" + st.id}
                 id={st.id}
                 onClick={handleEditClick}
              >
                {st.name?.en || st.name}
              </a>
              <br />
            </div>
          ))}
        </div>
        : '',
    grow: 4
  },
  {
    name: 'Actions',
    sortable: false,
    left: true,
    cell: (row) => <div key={"a-"+row.id}>
                     <button className="btn btn-sm btn-light mr-2" onClick={handleEditClick} id={row.id}><i className="icon-pencil" onClick={handleEditClick} id={row.id}></i></button>
                     <button className="btn btn-sm btn-light mr-2" onClick={handleDeleteClick} id={row.id}><i className="icon-trash" id={row.id}></i></button>
                     <button className="btn btn-sm btn-light mr-2" onClick={handleShowClick} id={row.id}><i className="icon-chart" onClick={handleShowClick} id={row.id}></i></button>
                   </div>,
    ignoreRowClick: true,
    grow: 2
  }
]);


const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

class ContractTypes extends Component {

  state = {
    data: [],
    loading: false,
    totalRows: 0,
    perPage: 50,
    sort: false,
    search: null,
    modalOpen: false,
    contractType: null,
    modalTitle: null,
    error: null,
    isFetched: false
  };
  
  constructor(props) {
    super(props);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleShowClick = this.handleShowClick.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.onSearchableInputChange = this.onSearchableInputChange.bind(this);
  }
  
  componentDidMount() {
    this.setState({ loading: true });
    console.log('componentDidMount');
    const { perPage } = this.state;
    console.log(perPage);
    var params = {}
    params.per_page = perPage;
    store.dispatch(getContractTypes(params)).then((result) => {
      this.setState({
        data: result.payload.items,
        totalRows: result.payload.items.length,
        loading: false,
        isFetched: true
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalTitle: 'Error!',
        modalOpen: true
      });
    });
    /* JWT is stored in memory */
    /*
    const {session} = this.props
    if(typeof(session.jwt)=='undefined') {
      sessionStorage.removeItem('jwt');
      this.setState({session: null});
      this.props.history.push({pathname: '/login'});
      window.location.href="/login";
    }
    */
  }

  handleQueryChange(event) {
    console.log('handleQueryChange');
    console.log(event.target.value)
    var search = {}
    search['search[name]'] = encodeURIComponent(event.target.value);
    this.setState({search: search});
  }
  
  handleFindContractTypes = async (e) => {
    e.preventDefault();
    console.log('handleFindContractTypes');
    console.log(this.state.search);
    if(this.state.search && this.state.search.toString().length>1) {
      this.setState({ loading: true });
      var params = {}
      params.per_page = this.state.perPage;
      if(this.state.sort) {
        Object.assign(params, this.state.sort);
      }
      if(this.state.search) {
        Object.assign(params, this.state.search);
      }
      store.dispatch(getContractTypes(params)).then((result) => {
        this.setState({
          loading: false,
          data: result.payload.items,
          totalRows: result.payload.items.length
      });
      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
        this.setState({
          error: error,
          modalOpen: true
        });
      }); 
    }
  }

  handlePageChange = async page => {
    console.log('handlePageChange');
    this.setState({ loading: true });
    const { perPage } = this.state;
    var params = {}
    params.per_page = perPage;
    params.page = page;
    if(this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getContractTypes(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        totalRows: result.payload.items.length
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    }); 

  }

  handlePerRowsChange = async (perPage, page) => {
    console.log('handlePerRowsChange');
    this.setState({ loading: true });
    var params = {}
    params.per_page = perPage;
    params.page = page;
    if(this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getContractTypes(params)).then((result) => {
      console.log('getContractTypes(params)');
      console.log(result.payload);
      this.setState({
        loading: false,
        data: result.payload.items,
        totalRows: result.payload.items.length,
        perPage: perPage,
    });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    }); 
  }

  handleSort = async (column, sortDirection) => {
    console.log('handleSort');
    this.setState({ loading: true });
    var params = {}
    params.per_page = this.state.perPage;
    var sort = {}
    sort['order['+column.selector+']'] = sortDirection;
    Object.assign(params, sort);
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getContractTypes(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        totalRows: result.payload.items.length,
        sort: sort
    });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  };
  
  handleEditClick(event) {
    console.log('handleEditClick');
    event.preventDefault()
    this.props.history.push({pathname: '/contract-types/edit/'+event.target.id});
  }

  handleShowClick(event) {
    console.log('handleShowClick');
    store.dispatch(getContractType(event.target.id)).then((result) => {
      if(typeof(result)!='undefined') {
        var contractType = result.payload;
        this.setState({
          contractType: contractType,
          modalTitle: contractType.name?.en || contractType.name
        })
        this.setState({modalOpen: true});
      }
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    }); 
  }

  handleDeleteClick(event) {
    console.log('handleDeleteClick');
    if(window.confirm('Are you sure you want to delete this contractType?')) {
      store.dispatch(deleteContractType(event.target.id)).then((result) => {
        if(typeof(result)!='undefined') {
          var contractType = result.payload;
          window.location.reload();
        }
      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
        this.setState({
          error: error,
          modalOpen: true
        });
      }); 
    }
  }

  onSearchInputChange = (evt,field) => {
    console.log('---evt')
    console.log(evt);

    const search = Object.assign({}, this.state.search);
    if(typeof(field)!='undefined') {
      if(field=='active') {
        if(evt=='Active') {
          var evt = {
            target: {name: 'active', value: '', checked: true}
          }
        }
        if(evt=='Inactive') {
          var evt = {
            target: {name: 'active', value: '', checked: false}
          }
        }
        if(evt=='Status') {
          var evt = {
            target: {name: 'active', value: '', checked: null}
          }
        }
      }
      if(field=='featured') {
        if(evt=='Yes') {
          var evt = {
            target: {name: 'featured', value: '', checked: true}
          }
        }
        if(evt=='No') {
          var evt = {
            target: {name: 'featured', value: '', checked: false}
          }
        }
        if(evt=='Any' || evt=='Featured') {
          var evt = {
            target: {name: 'featured', value: '', checked: null}
          }
        }
      }
    }
    if(evt.target.value!='') {
      search['search['+evt.target.name+']'] = encodeURIComponent(evt.target.value);
    }
    else if(evt.target.name=='active' && evt.target.checked!=null) {
      search['search['+evt.target.name+']'] = evt.target.checked;
    }
    else if(evt.target.name=='active' && evt.target.checked==null) {
      delete search['search['+evt.target.name+']'];
    }
    else if(evt.target.name=='featured' && evt.target.checked!=null) {
      search['search['+evt.target.name+']'] = evt.target.checked;
    }
    else if(evt.target.name=='featured' && evt.target.checked==null) {
      delete search['search['+evt.target.name+']'];
    }
    else {
      delete search['search['+evt.target.name+']'];
    }
    this.setState({search});
  }

  onSearchableInputChange(name, value) {
    console.log('onSearchableInputChange');
    const search = Object.assign({}, this.state.search);
    if(value!='') {
      search['search['+name+']'] = encodeURIComponent(value);
    }
    else if(name=='active') {
      search['search['+name+']'] = value; // true/false
    }
    else {
      delete search['search['+name+']'];
    }
    this.setState({search});
  };

  handleModalOpen() {
    this.setState({modalOpen: true});
  }

  handleModalClose() {
    this.setState({modalOpen: false})
  }

  handleModalOpen() {
    this.setState({modalOpen: true});
  }

  handleModalClose() {
    this.setState({modalOpen: false})
  }

  render() {
    
    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

    const { loading, data, totalRows } = this.state

    let contentTypesArr = data.filter(item=>(item.parent_id==null))

    const handleChange = (state) => {
      // You can use setState or dispatch with something like Redux so we can use the retrieved data
      console.log('Selected Rows: ', state.selectedRows);
    };

    console.log('---isFetched')
    console.log(this.state.isFetched);

    if(!this.state.isFetched) {
      return (<p>Loading...</p>)
    }
      
    return (
      <div className="animated fadeIn mt-5">
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          id="modal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{timeout: 500}}
          >
          <Fade in={this.state.modalOpen}>
            <div className="material-modal-content">
              <h4 id="transition-modal-title" className="mb-4">{this.state.modalTitle} <i className="cil-x cursor-pointer float-right" onClick={this.handleModalClose}></i></h4>
              <div id="transition-modal-description">
                {this.state.error!=null
                  ? <div>{this.state.error.toString()}</div>
                  : <ContractTypeShow contractType={this.state.contractType} />
                }
              </div>
            </div>
          </Fade>
        </Modal>
        <Row>
          <Col xs="12" lg="12">
            <div className="d-flex mb-4 justify-content-end">
              <Link to="/contract-types/new">
              <Button color="secondary" className="bg-white text-dark">
                <i className="cil-plus text-dark"></i>
              </Button>
            </Link>
            </div>  
          </Col>
          <Col xs="12" lg="12">
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center justify-content-between">
                  <div><i className="fa fa-align-justify"></i> ContractTypes list</div>
                  <div>
                    <Form className="form-inline float-right text-right"onSubmit={this.handleFindContractTypes}>
                      <Input type="search" name="name" className="mr-2" placeholder="ContractType Name" onChange={this.onSearchInputChange} />
                      {/*
                      <DropdownList
                        name="active"
                        onChange={(e)=>this.onSearchInputChange(e, 'active')}
                        data={['Status', 'Active', 'Inactive']}
                        placeholder="Status"
                        className="status-dropdown p-0 pr-2 text-left"
                      />
                      <DropdownList
                        name="featured"
                        onChange={(e)=>this.onSearchInputChange(e, 'featured')}
                        data={['Featured', 'Any', 'Yes', 'No']}
                        placeholder="Featured"
                        className="status-dropdown text-left"
                      />
                      */}
                      <a onClick={(e)=>this.handleFindContractTypes(e)} className="search-btn ml-1 cursor-pointer btn btn-outline-secondary"><i className="icon-magnifier"></i></a>  
                    </Form>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                  <DataTable
                    noHeader={false}
                    columns={columns(this.handleEditClick, this.handleShowClick, this.handleDeleteClick)}
                    onSelectedRowsChange={this.updateState}
                    data={contentTypesArr}
                    theme="solarized"
                    progressPending={loading}
                    /*pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={this.state.totalRows}
                    paginationRowsPerPageOptions={[50,100,150]}*/
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    onSort={this.handleSort}
                    sortServer
                    selectableRows
                    selectableRowsComponent={MaterialCheckbox}
                    selectableRowsComponentProps={selectProps}
                    onSelectedRowsChange={handleChange}
                    progressComponent={<DataTableProgressComponent />}
                    noDataComponent={<DataTableNoDataComponent />}
                  />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

ContractTypes.propTypes = {
  session: PropTypes.any
}

function mapStateToProps(state) {
  console.log('STATE')
  console.log(state);
    return {
      //contractTypes: state.contractTypes,
      //data: state.contractTypes.items,
      session: state.session
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({getContractType, getContractTypes, checkAuth}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractTypes)