import React, {Component} from 'react';
import { Card, CardBody, CardHeader, CardTitle, CardText, Progress } from 'reactstrap';

class Widget extends Component {
	render() {
		return (
			<Card className={this.props.classes}>
			  <CardBody>
			    <div className="h1 text-muted text-right mb-2">
			      <i className={this.props.icon}></i>
			    </div>
			    <div className="h4 mb-0">{this.props.header}</div>
			    <small className="text-muted text-uppercase font-weight-bold">{this.props.children}</small>
			    <Progress className="progress progress-xs mt-3 mb-0" color={this.props.progressColor} value={this.props.progressValue} />
			  </CardBody>
			</Card>
		)
	}
}

export default Widget;