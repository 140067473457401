// Action Types
import {GET_CONTRACT_SUCCESS, GET_CONTRACTS_SUCCESS, ADD_CONTRACT_SUCCESS, ADD_CONTRACT_ERROR, UPDATE_CONTRACT_ERROR} from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function contractFormReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONTRACT_SUCCESS: {
      console.log('GET_CONTRACT_SUCCESS')
      const {payload: contract} = action;
      return getNewState(state, {contract});
      break;
    }
    case ADD_CONTRACT_SUCCESS: {
      const {payload: contract} = action;
      return getNewState(state, {contract});
      break;
    }
    case ADD_CONTRACT_ERROR: {
      const {payload: contract} = action;
      return getNewState(state, {contract});
      break;
    }
    case UPDATE_CONTRACT_ERROR: {
      const {payload: contract} = action;
      return getNewState(state, {contract});
      break;
    }
    default:
      return state;
  }
}
