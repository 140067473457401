import {API_URL, X_API_KEY, X_API_VERSION} from '../conf';
import {getCookie} from '../shared/utils/cookies';
import axios from 'axios';
import {axiosInterceptors} from '../shared/utils/axiosInterceptors';
import querystring from 'querystring';

export const exportFunction = ({ selectedBank, search = {} }) => {
    if(!search) search = {}

    console.log(search['search[online]']);

    const {
        "search[start_date]": startDate = '',
        "search[end_date]": endDate = '',
        "search[customer]": customer = '',
        "search[brand]": brand = '',
        "search[reference]": reference = '',
        "search[type]": type = '',
        "search[status]" : status = '',
    } = search

    const data = {
        ...(startDate && {'start_date': startDate }),
        ...(endDate && {'end_date': endDate }),
        ...(type && {'type': type }),
        ...(status && {'status': status }),
        ...(brand && {'brand_id': brand }),
        ...(customer && {'customer_id': customer}),
        ...(reference && {'reference': reference}),
        ...(search['search[online]'] && { online: search['search[online]']})
    }

    let queryParams = querystring.stringify(data);

    const axiosData = {
        method: 'GET',
        url: `${API_URL}/transactions/export?bank_id=${selectedBank}&${queryParams}`,
        headers: {
            //Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'X-Api-Key': X_API_KEY,
            'X-Api-Version' : X_API_VERSION,
            'Authorization': 'Bearer ' + getCookie("_str")
        }
    };
    const axiosInstance = axios.create();
    axiosInterceptors(axiosInstance);
    return axiosInstance(axiosData)
        .then(response => (response.data))
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log('AXIOS ERROR:', err.response);
        });
};