import { GET_USERS_SUCCESS, GET_USERS_ERROR, GET_USERS_REQUEST} from '../actions/actionTypes'

const initialState = {
  list      : [],
  error     : null,
  isLoading : false
};

export default (state = initialState, action = {}) => {
  const { type, data } = action;

  switch (type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        isLoading : true
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        list      : data,
        isLoading : false
      };
    case GET_USERS_ERROR:
      return {
        ...state,
        error     : data,
        isLoading : false
      };
    default:
      return state;
  }
};

