// Action Types
import {
        GET_CATEGORY_SUCCESS, GET_CATEGORIES_SUCCESS, ADD_CATEGORY_SUCCESS
        } from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {
  categories: []
};

export default function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    
    /*
    case GET_CATEGORY_SUCCESS:
    {
      const {payload: category} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        category
      });
    }
    break;
    */

    case GET_CATEGORIES_SUCCESS:
    {
      const {payload: result} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        result
      });
    }
    break;
    
    /*
    case ADD_CATEGORY_SUCCESS:
    {
      const {payload: categories} = action;
      return getNewState(state, {
        categories
      });
    }
    break;
    */
    
  default:
      return state;
}
};