import React, {memo, useCallback, useEffect, useState} from 'react';
import {Card, CardBody} from 'reactstrap';

function Contact({ brand, newBrand = false, getDataChild, error ={} }) {
    const { contact } = brand || {};
    let {
        email = '',
        fax = '',
        first_name = '',
        last_name = '' ,
        phone = '',
        position = '',
        title = ''
        } = contact || {};

    const [ list, setList ] = useState({
        email,
        fax,
        first_name,
        last_name,
        phone,
        position,
        title
    });

    const handleChange = useCallback((e) => {
        e.persist()
        const { target } = e;
        const { name , value, checked } = target;

        setList(item => ({...item, [name]: name === 'meinplus' ? checked : value }))
    }, []);

    useEffect(() => {
        getDataChild(list);
    }, [ list ]);

    if(newBrand && Object.keys(brand).length > 0) {
        email = brand['contact[email]'] || ''
        fax = brand['contact[fax]'] || ''
        first_name = brand['contact[first_name]'] || ''
        last_name = brand['contact[last_name]'] || ''
        phone = brand['contact[phone]'] || ''
        position = brand['contact[position]'] || ''
        title = brand['contact[title]'] || ''
    }

    return (
        <Card>
            <CardBody>
                <div className="card-title">Contact</div>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="mb-2 mr-2">
                            <label>Position </label>
                            <div>
                                <input onChange={handleChange} name="contact[position]" type="text" className="form-control"
                                        defaultValue={position}
                                />

                                {  (Object.keys(error).length > 0 && error['contact.position']) &&
                                    <span style={{
                                        position: 'absolute',
                                        marginTop: '-4px',
                                        color: 'red'
                                    }}> { error['contact.position'][0] } </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="mb-2 mr-2">
                            <label>Title </label>
                            <div>
                                <input onChange={handleChange} name="contact[title]" type="text" className="form-control" defaultValue={title}/>
                                {  (Object.keys(error).length > 0 && error['contact.title']) &&
                                    <span style={{
                                        position: 'absolute',
                                        marginTop: '-4px',
                                        color: 'red'
                                    }}> { error['contact.title'][0] } </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="mb-2 mr-2"><label>First name </label>
                            <div>
                                <input onChange={handleChange} name="contact[first_name]" type="text" className="form-control"
                                        defaultValue={first_name} />

                                {  (Object.keys(error).length > 0 && error['contact.first_name']) &&
                                    <span style={{
                                        position: 'absolute',
                                        marginTop: '-4px',
                                        color: 'red'
                                    }}> { error['contact.first_name'][0] } </span>
                                }
                                </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="mb-2 mr-2">
                            <label>Last name </label>
                            <div>
                                <input onChange={handleChange} name="contact[last_name]" type="text" className="form-control"
                                        defaultValue={last_name} />

                                {  (Object.keys(error).length > 0 && error['contact.last_name']) &&
                                    <span style={{
                                        position: 'absolute',
                                        marginTop: '-4px',
                                        color: 'red'
                                    }}> { error['contact.last_name'][0] } </span>
                                }
                                </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="mb-2 mr-2">
                            <label>Email </label>
                            <div>
                                <input onChange={handleChange} name="contact[email]" type="text" className="form-control" defaultValue={email}/>
                                {  (Object.keys(error).length > 0 && error['contact.email']) &&
                                    <span style={{
                                        position: 'absolute',
                                        marginTop: '-4px',
                                        color: 'red'
                                    }}> { error['contact.email'][0] } </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="mb-2 mr-2">
                            <label>Phone </label>
                            <div>
                                <input onChange={handleChange} name="contact[phone]" type="text" className="form-control" defaultValue={phone}/>
                                {  (Object.keys(error).length > 0 && error['contact.phone']) &&
                                    <span style={{
                                        position: 'absolute',
                                        marginTop: '-4px',
                                        color: 'red'
                                    }}> { error['contact.phone'][0] } </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="mb-2 mr-2">
                            <label>Fax </label>
                            <div>
                                <input onChange={handleChange} name="contact[fax]" type="text" className="form-control" defaultValue={fax}/>
                                {  (Object.keys(error).length > 0 && error['contact.fax']) &&
                                    <span style={{
                                        position: 'absolute',
                                        marginTop: '-4px',
                                        color: 'red'
                                    }}> { error['contact.fax'][0] } </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default memo(Contact);