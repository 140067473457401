import axios from 'axios';
import { CHECK_AUTH, LOG_OUT, LOG_OUT_SUCCESS, LOG_OUT_ERROR, LOG_IN_SUCCESS, LOG_IN_ERROR, REFRESH_SUCCESS, REFRESH_ERROR } from './actionTypes';
import { API_URL, X_API_KEY, X_API_VERSION } from '../conf.js';
import { getCookie, setCookie } from '../shared/utils/cookies';
import { axiosInterceptors } from '../shared/utils/axiosInterceptors';

export function loginSuccess(payload) {
  return { type: LOG_IN_SUCCESS, payload: payload }
}

export function loginError() {
  return { type: LOG_IN_ERROR }
}

export function logoutSuccess(payload) {
  return { type: LOG_OUT_SUCCESS, payload: payload }
}

export function logoutError() {
  return { type: LOG_OUT_ERROR }
}

export function refreshSuccess(payload) {
  return { type: REFRESH_SUCCESS, payload: payload }
}

export function refreshError() {
  return { type: REFRESH_ERROR }
}

export function logInUser(credentials) {
  return function (dispatch) {

    /*
    return sessionApi.login(credentials).then(response => {
      
      if(typeof(response.token)=='undefined' || response.token=='') {
        dispatch(loginError());
      }
      else {
        //sessionStorage.setItem('jwt', response.jwt);
        setCookie("jwt", response.token, -1);
        dispatch(loginSuccess(response.token));
      }
    }).catch(error => {
      throw(error);
    });
    */

    // Axios Data
    const axiosData = {
      method: 'POST',
      url: API_URL + '/account/authenticate',
      headers: {
        //Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Api-Version' : X_API_VERSION,
        'X-Api-Key': X_API_KEY
      },
      data: { grant_type: "password", email: credentials.email, password: credentials.password }
    };
    //const axiosInstance = axios.create();
    //axiosInterceptors(axiosInstance);
    return axios(axiosData)
      .then(response => {
        //setCookie("_str", response.data.token, -1);
        setCookie("_str", response.data.access_token, -1);
        setCookie("_strf", response.data.refresh_token, -1);
        setCookie("_strexp", expirationDate());
        dispatch(loginSuccess(response.data.access_token));
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log('AXIOS ERROR:', err.response);
        dispatch(loginError());
      });
  };

}

export function logOutUser() {
  
/*
  sessionStorage.removeItem('_str');
  sessionStorage.removeItem('_strf');
  setCookie("_str", "", -1);
  setCookie("_strf", "", -1);
  return { type: LOG_OUT }
*/
  return function (dispatch) {

    // Axios Data
    const axiosData = {
      method: 'DELETE',
      url: API_URL + '/account/sign_out',
      headers: {
        //Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Api-Version' : X_API_VERSION,
        'X-Api-Key': X_API_KEY,
        'Authorization': 'Bearer ' + getCookie("_str")
      },
      data: { refresh_token: getCookie("_strf"), forced: true }
    };
    //const axiosInstance = axios.create();
    //axiosInterceptors(axiosInstance);
    return axios(axiosData)
      .then(response => {
        sessionStorage.removeItem('_str');
        sessionStorage.removeItem('_strf');
        sessionStorage.removeItem('_strexp');
        setCookie("_str", "", -1);
        setCookie("_strf", "", -1);
        setCookie("_strexp", "", -1);
        dispatch(logoutSuccess(response.data));
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log('AXIOS ERROR:', err.response);
        dispatch(logoutError(err.response));
      });
  };

}

export function signOutUser() {

  return function (dispatch) {

    // Axios Data
    const axiosData = {
      method: 'DELETE',
      url: API_URL + '/account/sign_out',
      headers: {
        //Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Api-Version' : X_API_VERSION,
        'X-Api-Key': X_API_KEY,
        'Authorization': 'Bearer ' + getCookie("_str")
      },
      data: { refresh_token: getCookie("_strf"), forced: true }
    };
    //const axiosInstance = axios.create();
    //axiosInterceptors(axiosInstance);
    return axios(axiosData)
      .then(response => {
        console.log('---signOutUser success');
        sessionStorage.removeItem('_str');
        sessionStorage.removeItem('_strf');
        sessionStorage.removeItem('_strexp');
        setCookie("_str", "", -1);
        setCookie("_strf", "", -1);
        setCookie("_strexp", "", -1);
        dispatch(logoutSuccess(response.data));
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log('AXIOS ERROR:', err.response);
        dispatch(logoutError(err.response));
      });
  };

}

  export function refreshToken() {
  
  return function (dispatch) {

    // Axios Data
    const axiosData = {
      method: 'POST',
      url: API_URL + '/account/authenticate',
      headers: {
        //Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Api-Version' : X_API_VERSION,
        'X-Api-Key': X_API_KEY,
        'Authorization': 'Bearer ' + getCookie("_str")
      },
      data: { grant_type: 'refresh_token', refresh_token: getCookie("_strf") }
    };
    //const axiosInstance = axios.create();
    //axiosInterceptors(axiosInstance);
    return axios(axiosData)
      .then(response => {
        setCookie("_str", response.data.access_token, -1);
        setCookie("_strexp", expirationDate());
        dispatch(refreshSuccess(response.data));
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log('AXIOS ERROR:', err.response);
        dispatch(refreshError(err.response));
      });
  };


}

export function checkAuth() {
  return { type: CHECK_AUTH }
}

function expirationDate() {
  var expDate = new Date();
  expDate.setMinutes(expDate.getMinutes()+60);
  return expDate/1000;
}