import React from 'react';

function CreateBrandAccount(props) {
    return (
        <div>
            asfasf
        </div>
    );
}

export default React.memo(CreateBrandAccount);