// Action Types
import {
        GET_TRANSACTION_SUCCESS, GET_TRANSACTIONS_SUCCESS, ADD_TRANSACTION_SUCCESS
        } from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function transactionReducer(state = initialState, action) {
  switch (action.type) {
    
    /*
    case GET_TRANSACTION_SUCCESS:
    {
      const {payload: transaction} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        transaction
      });
    }
    break;
    */
    
    case GET_TRANSACTIONS_SUCCESS:
    {
      const {payload: result} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        result
      });
    }
    break;
    
    /*
    case ADD_TRANSACTION_SUCCESS:
    {
      const {payload: transactions} = action;
      return getNewState(state, {
        transactions
      });
    }
    break;
    */
 
  default:
      return state;
}
};