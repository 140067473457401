import * as types from '../actions/actionTypes';
import initialState from './initialState';
//import {browserHistory} from 'react-router';

import {setCookie, getCookie} from '../shared/utils/cookies';


export default function sessionReducer(state = initialState.session, action) {
  switch(action.type) {
    case types.LOG_IN_SUCCESS:
      //browserHistory.push('/')
      console.log('ACTION');
      console.log(action)
      setCookie('_lo', 0 , 1);
      return Object.assign({}, state, {success: true, error: null});
      //return !!sessionStorage.jwt
      break;
    case types.LOG_IN_ERROR:
      return Object.assign({}, state, {error: 'Incorrect email or password!'});
      //browserHistory.push('/')
      //return !!sessionStorage.jwt
      break;
    case types.REFRESH_SUCCESS:
      //browserHistory.push('/')
      console.log('ACTION');
      console.log(action)
      setCookie('_lo', 0 , 1);
      return Object.assign({}, state, {success: true, error: null});
      //return !!sessionStorage.jwt
      break;
    case types.REFRESH_ERROR:
      return Object.assign({}, state, {error: 'Incorrect refresh_token!'});
      //browserHistory.push('/')
      //return !!sessionStorage.jwt
      break;
    case types.LOG_OUT:
      //browserHistory.push('/')
      setCookie('_lo', 1 , 1);
      return !!sessionStorage._str
      break;
    case types.CHECK_AUTH:
      //browserHistory.push('/')
      return Object.assign({}, state, {forceLogout: getCookie("_lo")});
      break;
    default: 
      return state;
  }
}

