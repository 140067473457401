import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Card, CardBody, Col, Row} from 'reactstrap';
import DataTable from 'react-data-table-component';
import MaterialCheckbox from '../../shared/components/material-checkbox/MaterialCheckbox';
import DataTableProgressComponent from '../../shared/components/widget/DataTableProgressComponent';
import DataTableNoDataComponent from '../../shared/components/widget/DataTableNoDataComponent';
import memoize from 'memoize-one';
import {getCsv, getInvoices, getSepa} from '../../actions/invoicesList';
import {Link} from 'react-router-dom';
import Select from '@material-ui/core/Select';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem} from '@material-ui/core';
import {getBrands} from '../../actions/brandActions';
import configureStore from '../../shared/redux/configureStore';
import BrandCombobox from '../../shared/components/searchable-field/BrandCombobox';
const store = configureStore(window.initialState);

const Sepa = () => {
    return (
        <svg width="25" style={{ margin: '0 5px' }} viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg">
            <title/>

            <g id="xxx-word">

                <path className="cls-1" d="M325,105H250a5,5,0,0,1-5-5V25a5,5,0,1,1,10,0V95h70a5,5,0,0,1,0,10Z"/>

                <path className="cls-1" d="M325,154.83a5,5,0,0,1-5-5V102.07L247.93,30H100A20,20,0,0,0,80,50v98.17a5,5,0,0,1-10,0V50a30,30,0,0,1,30-30H250a5,5,0,0,1,3.54,1.46l75,75A5,5,0,0,1,330,100v49.83A5,5,0,0,1,325,154.83Z"/>

                <path className="cls-1" d="M300,380H100a30,30,0,0,1-30-30V275a5,5,0,0,1,10,0v75a20,20,0,0,0,20,20H300a20,20,0,0,0,20-20V275a5,5,0,0,1,10,0v75A30,30,0,0,1,300,380Z"/>

                <path className="cls-1" d="M275,280H125a5,5,0,1,1,0-10H275a5,5,0,0,1,0,10Z"/>

                <path className="cls-1" d="M200,330H125a5,5,0,1,1,0-10h75a5,5,0,0,1,0,10Z"/>

                <path className="cls-1" d="M325,280H75a30,30,0,0,1-30-30V173.17a30,30,0,0,1,30-30h.2l250,1.66a30.09,30.09,0,0,1,29.81,30V250A30,30,0,0,1,325,280ZM75,153.17a20,20,0,0,0-20,20V250a20,20,0,0,0,20,20H325a20,20,0,0,0,20-20V174.83a20.06,20.06,0,0,0-19.88-20l-250-1.66Z"/>

                <path className="cls-1" d="M168.48,217.48l8.91,1a20.84,20.84,0,0,1-6.19,13.18q-5.33,5.18-14,5.18-7.31,0-11.86-3.67a23.43,23.43,0,0,1-7-10,37.74,37.74,0,0,1-2.46-13.87q0-12.19,5.78-19.82t15.9-7.64a18.69,18.69,0,0,1,13.2,4.88q5.27,4.88,6.64,14l-8.91.94q-2.46-12.07-10.86-12.07-5.39,0-8.38,5t-3,14.55q0,9.69,3.2,14.63t8.48,4.94a9.3,9.3,0,0,0,7.19-3.32A13.25,13.25,0,0,0,168.48,217.48Z"/>

                <path className="cls-1" d="M179.41,223.15l9.34-2q1.68,7.93,12.89,7.93,5.12,0,7.87-2a6.07,6.07,0,0,0,2.75-5,7.09,7.09,0,0,0-1.25-4q-1.25-1.85-5.35-2.91l-10.2-2.66a25.1,25.1,0,0,1-7.73-3.11,12.15,12.15,0,0,1-4-4.9,15.54,15.54,0,0,1-1.5-6.76,14,14,0,0,1,5.31-11.46q5.31-4.32,13.59-4.32a24.86,24.86,0,0,1,12.29,3,13.56,13.56,0,0,1,6.89,8.52l-9.14,2.27q-2.11-6.05-9.84-6.05-4.49,0-6.86,1.88a5.83,5.83,0,0,0-2.36,4.77q0,4.57,7.42,6.41l9.06,2.27q8.24,2.07,11.05,6.11a15.29,15.29,0,0,1,2.81,8.93,14.7,14.7,0,0,1-5.92,12.36q-5.92,4.51-15.33,4.51a28,28,0,0,1-13.89-3.32A16.29,16.29,0,0,1,179.41,223.15Z"/>

                <path className="cls-1" d="M250.31,236h-9.77L224.1,182.68h10.16l12.23,40.86L259,182.68h8Z"/>

            </g>

        </svg>
    )
}

const XmlFile = () => {
    return (
        <svg width="25" style={{ margin: '0 5px' }} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 56 56" xmlSpace="preserve" fill="#000000" stroke="#000000">

            <g id="SVGRepo_bgCarrier" strokeWidth="0"/>

            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.784"/>

            <g id="SVGRepo_iconCarrier">
                <g>
                    <path style={{fill: '#ffffff'}} d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074 c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"/>
                    <polygon style={{fill: '#ffffff'}} points="37.5,0.151 37.5,12 49.349,12 "/>
                    <path style={{fill: '#000000'}} d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"/> <g>
                    <path style={{fill: '#ffffff'}} d="M19.379,48.105L21.936,53h-1.9l-1.6-3.801h-0.137L16.576,53h-1.9l2.557-4.895l-2.721-5.182h1.873 l1.777,4.102h0.137l1.928-4.102H22.1L19.379,48.105z"/>
                    <path style={{fill: '#ffffff'}} d="M31.998,42.924h1.668V53h-1.668v-6.932l-2.256,5.605h-1.449l-2.27-5.605V53h-1.668V42.924h1.668 l2.994,6.891L31.998,42.924z"/> <path style={{fill: '#ffffff'}} d="M37.863,42.924v8.832h4.635V53h-6.303V42.924H37.863z"/> </g>
                    <path  style={{fill: '#000000'}} d="M15.5,24c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l6-6 c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-6,6C16.012,23.902,15.756,24,15.5,24z"/>
                    <path style={{fill: '#000000'}}d="M21.5,30c-0.256,0-0.512-0.098-0.707-0.293l-6-6c-0.391-0.391-0.391-1.023,0-1.414 s1.023-0.391,1.414,0l6,6c0.391,0.391,0.391,1.023,0,1.414C22.012,29.902,21.756,30,21.5,30z"/>
                    <path style={{fill: '#000000'}}d="M33.5,30c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l6-6 c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-6,6C34.012,29.902,33.756,30,33.5,30z"/>
                    <path style={{fill: '#000000'}} d="M39.5,24c-0.256,0-0.512-0.098-0.707-0.293l-6-6c-0.391-0.391-0.391-1.023,0-1.414 s1.023-0.391,1.414,0l6,6c0.391,0.391,0.391,1.023,0,1.414C40.012,23.902,39.756,24,39.5,24z"/>
                    <path style={{fill: '#000000'}} d="M24.5,32c-0.11,0-0.223-0.019-0.333-0.058c-0.521-0.184-0.794-0.755-0.61-1.276l6-17 c0.185-0.521,0.753-0.795,1.276-0.61c0.521,0.184,0.794,0.755,0.61,1.276l-6,17C25.298,31.744,24.912,32,24.5,32z"/>
                </g>
            </g>

        </svg>
    )
}

const styleBtn = {
    border: 'unset',
    padding: 0
}

const columns = memoize((handleSepa, handleCsv) => [
    {
        name: 'Invoice number',
        selector: 'id',
        sortable: true,
        grow: 2
    },
    {
        name: 'Brand Name',
        selector: 'name',
        sortable: true,
        grow: 4,
        cell: (row) => <span>{ row?.brand?.name } </span>
    },
    {
        name: 'Date',
        selector: 'created_at',
        sortable: true,
        grow: 4,
        cell: (row) => <span>{ row?.created_at?.split('T')[0] } </span>
    },
    {
        name: 'View',
        selector: row => {
            return <Link to={`/invoices/${row.id}`}>Details</Link>
        },
        sortable: false,
        right: false,
        grow: 1
    },
]);


function Invoices(props) {
    const [ data, setData ] = useState([]);
    const [loading, setLoading] = useState(true);
    const [invoiceData , setInvoiceData] = useState('Invoice number sort - ask');
    const [createData , setCreateData] = useState('Created data sort - ask');
    const [ bank, setBank ] = useState(null);
    const [ modal, setModal ] = useState(false);
    const calendar = useRef(null);
    const [getParams, setGetParams] = useState({
        page: 1,
        per_page: 10
    });
    const [ meinPlus, setMeinPlus ] = useState(false);

    const fetchInvoices = useCallback(async (e) => {
        if(e) e.preventDefault();
        const brand_name = bank ? { 'search[brand]': bank } : {}
        // const invoice_number =  invoiceData ? { 'order[id]': invoiceData.split('-')[1].trim() } : {}
        // const created_data = createData ? { 'order[created_at]': createData.split('-')[1].trim() } : {}

        const paramsList = {
            ...(Object.values(brand_name).length && brand_name),
            ...getParams
            // ...(Object.values(invoice_number).length && invoice_number),
            // ...(Object.values(created_data).length && created_data)
        };

        const request = await getInvoices(paramsList);
        setData(request);
        setLoading(false);
    },[ invoiceData, createData, bank, getParams ]);

    // const getBanks = useCallback(async () => {
    //     await store.dispatch(getBrands()).then((result) => {
    //         setBanks(result)
    //     }).catch((error) => {
    //         console.log(error)
    //     });
    // },[])

    useEffect(() => {
        fetchInvoices();
    }, [ getParams ]);

    const handleSepa = useCallback(async (e) => {
        const date = calendar?.current.value ? {'date': calendar?.current.value  } : {}
        e.preventDefault();

        const params = {
            ...date,
             meinplus: meinPlus
        }
        const sepaFileContent = await getSepa(params);

        console.log(sepaFileContent)

        if(sepaFileContent) {
            const blob = new Blob([sepaFileContent], { type: 'application/xml' });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `sepa_${date.date}.xml`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }, [ meinPlus ]);

    const handleBank = useCallback((name, value) => {
        setBank(value || '');
    },[ bank ]);

    const downloadCSV = useCallback((data, filename) => {
        const csvContent = `data:text/csv;charset=utf-8,${data}`;
        const encodedUri = encodeURI(csvContent);

        return {
            href: encodedUri,
            download: filename
        }

    }, []);

    const handlePerRowsChange = useCallback((per_page) => {
        setGetParams(item => {
            return {
                ...item,
                per_page
            }
        });
    }, [ getParams ]);

    const handlePageChange = useCallback((page) => {
        setGetParams(item => {
            return {
                ...item,
                page
            }
        });
    }, [ getParams ]);

    const handleMeinPlus = useCallback(({ target }) => {
        const check = target.checked;

        console.log(check);
        setMeinPlus(() => check);
    }, []);

    const handleCsv = useCallback(async (e) => {
        const date = calendar?.current.value ? {'date': calendar?.current.value  } : {}
        e.preventDefault();

        const params = {
            ...date,
            meinplus: meinPlus
        }

        const csv = await getCsv(params);

        if(csv) {
            const linkCsv = downloadCSV(csv, `csv-${date?.date}.csv`);
            const downloadLink = document.createElement('a');
            downloadLink.href = linkCsv.href;
            downloadLink.download = linkCsv.download;
            downloadLink.click();
        }
    }, [ meinPlus ]);

    const { items = [] , meta = {} } = data;

    console.log(data);

    return (
        <section>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Dialog

                open={modal}
                onClose={() => setModal(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogContent>
                    <DialogContentText color={'initial'} align={'center'}>
                       Please choose the invocing date and <br/>  click the icon for download
                    </DialogContentText>

                    <DialogContent className={'flex align-items-center justify-content-center mt-4'}>
                        <label className={'flex align-items-center mb-0 mr-3'}>
                            <input type="checkbox" className={'mr-2'} defaultChecked={meinPlus} onChange={handleMeinPlus}/>
                            MeinPlus
                        </label>
                        <input  ref={calendar} name="name" type="date" defaultValue={new Date().toISOString().substr(0, 10)}
                                    className="mr-2 form-control" style={{
                                        width: 200
                            }}/>

                            {/*<div className={'ml-auto flex align-items-center'}>*/}
                                <button className={'btn'} style={styleBtn} onClick={handleSepa}>
                                    <XmlFile />
                                </button>

                                <button className={'btn'} style={styleBtn} onClick={handleCsv}>
                                    <Sepa />
                                </button>
                            {/*</div>*/}
                        </DialogContent>
                </DialogContent>
            </Dialog>
            <Row>
                <Col>
                    <Card>
                        <div className="card-header">
                            <div className="d-flex align-items-center justify-content-between">
                                <div style={{
                                    width: '90px'
                                }}><i className="fa fa-align-justify"></i>Invoices list</div>
                                <div style={{
                                    width: '100%'
                                }}>
                                    <form className="form-inline float-right text-right --brand-search-form"
                                      style={{
                                          width: '100%'
                                      }}
                                    >

                                        <BrandCombobox
                                            className="w-200"
                                            onChange={handleBank}
                                        />
                                        {/*<Select inputProps={{*/}
                                        {/*    name: 'Invoice number sort - ask',*/}
                                        {/*    value: 'ask',*/}
                                        {/*}}/>*/}

                                        {/*<select name="" id="" ref={invoiceNumber}>*/}
                                        {/*    <option selected value="asc">ASC</option>*/}
                                        {/*    <option value="desc">DESC</option>*/}
                                        {/*</select>*/}
                                        {/*<input  name="name" placeholder="Invoice number" type="search"*/}
                                        {/*       className="mr-2 form-control" />*/}

                                        {/*<input ref={brandName} name="name" placeholder="Brand Name" type="search"*/}
                                        {/*       className="mr-2 form-control" />*/}

                                        <button className="search-btn ml-1 mr-auto cursor-pointer btn btn-outline-secondary" onClick={fetchInvoices}>
                                            <i className="icon-magnifier"/>
                                        </button>

                                        {/*<input ref={createdData} name="name" placeholder="Created Data" type="search"*/}
                                        {/*       className="mr-2 form-control" />*/}

                                        {/*<input  ref={calendar} name="name" type="date" defaultValue={new Date().toISOString().substr(0, 10)}*/}
                                        {/*       className="mr-2 form-control" />*/}


                                        {/*<Select*/}
                                        {/*        value={invoiceData}*/}
                                        {/*        onChange={(e) => setInvoiceData(e.target.value)}*/}
                                        {/*        className={'mr-2'}*/}
                                        {/*        style={{*/}
                                        {/*            background: '#f0f3f5',*/}
                                        {/*            padding: '0 10px',*/}
                                        {/*        }}>*/}
                                        {/*    <MenuItem value={'Invoice number sort - ask'}>Invoice number sort - Ask</MenuItem>*/}
                                        {/*    <MenuItem value={'Invoice number sort - desk'}>Invoice number sort - Desk</MenuItem>*/}
                                        {/*</Select>*/}

                                        {/*<Select*/}
                                        {/*    value={createData}*/}
                                        {/*    onChange={(e) => setCreateData(e.target.value)}*/}
                                        {/*        className={'mr-2'}*/}
                                        {/*        style={{*/}
                                        {/*            background: '#f0f3f5',*/}
                                        {/*            padding: '0 10px',*/}
                                        {/*        }}>*/}
                                        {/*    <MenuItem value={'Created data sort - ask'}>Created data sort - Ask</MenuItem>*/}
                                        {/*    <MenuItem value={'Created data sort - desk'}>Created data sort - Desk</MenuItem>*/}
                                        {/*</Select>*/}

                                        {/*<button className={'btn'} style={styleBtn} onClick={handleSepa}>*/}
                                        {/*    <XmlFile />*/}
                                        {/*</button>*/}

                                        {/*<button className={'btn'} style={styleBtn} onClick={handleCsv}>*/}
                                        {/*    <Sepa />*/}
                                        {/*</button>*/}

                                        <button className={'btn btn-primary ml-auto'} onClick={(e) => {
                                            e.preventDefault();
                                            setModal(true);
                                        }}>
                                            Generate SEPA Debit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <CardBody>
                            <DataTable
                                noHeader={true}
                                columns={columns(handleSepa,handleCsv)}
                                // onSelectedRowsChange={this.updateState}
                                data={items}
                                theme="solarized"
                                progressPending={loading}
                                pagination
                                paginationServer
                                paginationTotalRows={meta?.total_items}
                                paginationRowsPerPageOptions={[10, 50, 100]}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                // onSort={this.handleSort}
                                sortServer
                                selectableRows
                                // selectableRowsComponent={MaterialCheckbox}
                                // selectableRowsComponentProps={selectProps}
                                // onSelectedRowsChange={handleChange}
                                progressComponent={<DataTableProgressComponent />}
                                noDataComponent={<DataTableNoDataComponent />}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </section>
    );
}

export default React.memo(Invoices);