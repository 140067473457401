import React from 'react';

class DataTableProgressComponent extends React.Component  {
  render() {
    return (
      <div className="rdt_ProgressComponent"><div>Loading...</div></div>
    )
  }
}

export default DataTableProgressComponent;