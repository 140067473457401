import {API_URL, X_API_KEY} from '../conf';
import {getCookie} from '../shared/utils/cookies';
import {axiosInterceptors} from '../shared/utils/axiosInterceptors';
import axios from 'axios';

const getInvoices = (params = {}) => {
    const queryString = new URLSearchParams(params).toString();

    let url = API_URL + `/invoices/${queryString && '?' + queryString}`;

    // Axios Data
    const axiosData = {
        method: 'GET',
        url: url,
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': X_API_KEY,
            'Authorization': 'Bearer ' + getCookie("_str")
        }
    }

    const axiosInstance = axios.create()
    axiosInterceptors(axiosInstance)

    return axiosInstance(axiosData)
        .then(response => response?.data)
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log('AXIOS ERROR:', err.response || err)
            return {}
        });
}

const getInvoiceId = (id = '') => {

    let url = API_URL + '/invoices/' + id;

    // Axios Data
    const axiosData = {
        method: 'GET',
        url: url,
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': X_API_KEY,
            'Authorization': 'Bearer ' + getCookie("_str")
        }
    }

    const axiosInstance = axios.create()
    axiosInterceptors(axiosInstance)

    return axiosInstance(axiosData)
        .then(response => response?.data)
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log('AXIOS ERROR:', err.response || err)
            return {}
        });
}

const getSepa = (params = {date: '2024-01-11' }) => {
    const queryString = new URLSearchParams(params).toString();

    let url = API_URL + `/invoices/sepa${queryString && '?' + queryString}`;

    console.log(params);

    // Axios Data
    const axiosData = {
        method: 'get',
        url: url,
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': X_API_KEY,
            'Authorization': 'Bearer ' + getCookie("_str")
        }
    }

    const axiosInstance = axios.create()
    axiosInterceptors(axiosInstance)

    return axiosInstance(axiosData)
        .then(response => response?.data)
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log('AXIOS ERROR:', err.response || err)
            return {}
        });
}


const getCsv = (params = {}) => {
    const queryString = new URLSearchParams(params).toString();

    let url = API_URL + `/invoices/csv${queryString && '?' + queryString}`;

    // Axios Data
    const axiosData = {
        method: 'GET',
        url: url,
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': X_API_KEY,
            'Authorization': 'Bearer ' + getCookie("_str")
        }
    }

    const axiosInstance = axios.create()
    axiosInterceptors(axiosInstance)

    return axiosInstance(axiosData)
        .then(response => response?.data)
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log('AXIOS ERROR:', err.response || err)
            return {}
        });
}


export { getInvoices, getSepa, getCsv, getInvoiceId };