import React, {useCallback, useEffect, useState} from 'react';
// import {getUsersList} from '../../actions/brandUpdateActions';
import {usersActions} from '../../actions/usersActions';
import {useDispatch, useSelector} from 'react-redux';
import {isLoadingUsersSelector, usersSelector} from '../../selectors/users';

import {Button, Card, CardBody, CardHeader, Col, Form, Input, Row} from 'reactstrap';
import DataTable from 'react-data-table-component';
import MaterialCheckbox from '../../shared/components/material-checkbox/MaterialCheckbox';
import DataTableProgressComponent from '../../shared/components/widget/DataTableProgressComponent';
import DataTableNoDataComponent from '../../shared/components/widget/DataTableNoDataComponent';
import memoize from 'memoize-one';
import {deleteAdmin} from '../../actions/brandUpdateActions';
import BrandCombobox from '../../shared/components/searchable-field/BrandCombobox';

function SeeBrand(props) {
    const dispatch = useDispatch();
    const { items = [], meta = {} } = useSelector(usersSelector);
    const usersLoading = useSelector(isLoadingUsersSelector);

    const [getParams, setGetParams] = useState({
        page: 1,
        per_page: 10
    });

    const columns = memoize((handleEditClick, handleShowClick, handleDeleteClick) => [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            grow: 0
        },
        {
            name: 'Brand id',
            selector: 'brand_id',
            sortable: true,
            grow: 5
        },
        {
            name: 'Active',
            selector: (row) => row.active ? 'active' : '-',
            sortable: true,
            grow: 0
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true,
            grow: 10
        },
        {
            name: 'Created at',
            selector: (row) => row?.created_at.split('T')[0],
            sortable: true,
            grow: 10,
        },
        {
            name: 'Updated at',
            selector: (row) => row?.updated_at.split('T')[0],
            sortable: true,
            grow: 10
        },
        {
            name: 'Actions',
            sortable: false,
            left: true,
            cell: (row) => <div>
                <button className="btn btn-sm btn-light mr-2" onClick={() => handleEditClick(row.id)} id={row.id}>
                    <i className="icon-pencil"/>
                </button>
                <button className="btn btn-sm btn-light mr-2" onClick={() => handleDeleteClick(row.id)} id={row.id}><i className="icon-trash" id={row.id}></i></button>
                {/*<button className="btn btn-sm btn-light mr-2" onClick={handleShowClick} id={row.id}><i className="icon-chart" onClick={handleShowClick} id={row.id}></i></button>*/}
            </div>,
            ignoreRowClick: true,
            grow: 4
        }
    ]);

    const fetchUsersList = useCallback(async () => {
       dispatch(usersActions(getParams));
    }, [ getParams ]);

    useEffect(() => {
        fetchUsersList();
    }, [ getParams ])

    const handleBrandSearch = useCallback((name, id) => {
        setGetParams(item => ({
            ...item,
            'search[brand]': id !== 0 ? id : ''
        }))
    }, [ getParams ]);

    const handleEditClick = (brand) => {
        props.history.push({pathname: '/brand-accounts/edit/' + brand});
    };
    const handleShowClick = () => console.log('2');

    const handleDeleteClick = async (id) => {
        // feature/create-new-brand-section
        await deleteAdmin(id);

        await fetchUsersList()
    }

    const handlePerRowsChange = useCallback((per_page) => {
        setGetParams(item => {
            return {
                ...item,
                per_page
            }
        });
    }, [ getParams ]);

    const handlePageChange = useCallback((page) => {
        setGetParams(item => {
            return {
                ...item,
                page
            }
        });
    }, [ getParams ]);



    return (
        <div className="animated fadeIn mt-5">
            <Row>
                <Col xs="12" lg="12">
                    <Card>
                        <CardHeader>
                            <div className="d-flex align-items-center justify-content-between">
                                <div><i className="fa fa-align-justify"></i>Users list</div>

                                {/*<Input type="search"*/}
                                {/*       name="name"*/}
                                {/*       className="mr-2"*/}
                                {/*       style={{*/}
                                {/*           width: 200*/}
                                {/*       }}*/}
                                {/*       placeholder="Brand Name"*/}
                                {/*       onChange={handleBrandSearch}*/}
                                {/*/>*/}

                                <BrandCombobox onChange={handleBrandSearch} className="w-200" />
                            </div>
                        </CardHeader>
                        <CardBody>

                            <DataTable
                                noHeader={false}
                                columns={columns(handleEditClick, handleShowClick, handleDeleteClick)}
                                // onSelectedRowsChange={this.updateState}
                                data={items}
                                theme="solarized"
                                progressPending={usersLoading}
                                pagination
                                paginationServer
                                paginationTotalRows={meta?.total_items}
                                paginationRowsPerPageOptions={[10, 50, 100]}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                // onSort={this.handleSort}
                                sortServer
                                selectableRows
                                // selectableRowsComponent={MaterialCheckbox}
                                // selectableRowsComponentProps={selectProps}
                                // onSelectedRowsChange={handleChange}
                                progressComponent={<DataTableProgressComponent />}
                                noDataComponent={<DataTableNoDataComponent />}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default React.memo(SeeBrand);