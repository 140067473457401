export function setStorageItem(name, value) {
  localStorage.setItem(name, value)
}

export function removeStorageItem(name) {
  localStorage.removeItem(name)
}

export function getStorageItem(name) {
  return localStorage.getItem(name)
}