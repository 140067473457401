// Action Types
import {
        GET_COUNTRY_SUCCESS, GET_COUNTRIES_SUCCESS, ADD_COUNTRY_SUCCESS
        } from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {
  countries: []
};

export default function countriesReducer(state = initialState, action) {
  switch (action.type) {
    
    /*
    case GET_COUNTRY_SUCCESS:
    {
      const {payload: country} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        country
      });
    }
    break;
    */

    case GET_COUNTRIES_SUCCESS:
    {
      const {payload: result} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        result
      });
    }
    break;
    
    /*
    case ADD_COUNTRY_SUCCESS:
    {
      const {payload: countries} = action;
      return getNewState(state, {
        countries
      });
    }
    break;
    */
    
  default:
      return state;
}
};