import React, {memo, useCallback, useEffect, useState} from 'react';
import {getPayoutCurrent, getPayouts, getPayoutsCsv, getPayoutsXml} from '../../actions/paymentActions';
import memoize from 'memoize-one';
import {Link} from 'react-router-dom';
import {CardBody, CardHeader, Col, Fade} from 'reactstrap';
import DropdownList from 'react-widgets/lib/DropdownList';
import DataTable from 'react-data-table-component';
import DataTableProgressComponent from '../../shared/components/widget/DataTableProgressComponent';
import DataTableNoDataComponent from '../../shared/components/widget/DataTableNoDataComponent';

function PayoutsView({ match : { params : { id } } }) {
    const [payouts, setPayouts] = useState([]);

    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        fetchData()
    }, [ id ]);

    const fetchData = useCallback(async () => {
        const currentBank = new URL(window.location.href).searchParams.get('bank_id') || null
        await setLoading(true)
        const data = await getPayoutCurrent(id, currentBank);

        await setPayouts(data);
        await setLoading(false);
    }, [ id ]);

    const columns = memoize((handleEditClick, handleShowClick) => [
        {
            name: 'ID',
            selector: 'id',
            sortable: false,
            center: true,
            grow: 0
        },
        {
            name: 'Created at',
            selector: 'created_at',
            sortable: false,
            center: false,
            grow: 1
        },
        {
            name: 'Updated at',
            selector: 'updated_at',
            sortable: false,
            right: false,
            grow: 1
        },
        // {
        //     name: 'Download',
        //     selector: 'download',
        //     sortable: false,
        //     right: false,
        //     grow: 1,
        //     cell: row => (
        //         <div>
        //             <a
        //                 // href={sepa?.csv?.href} download={sepa?.csv?.download} title={sepa?.csv?.download ? '1.csv' : 'no file'}
        //                 onClick={handleCsv}
        //                 style={{
        //                     cursor: 'pointer'
        //                 }}>
        //                 <Sepa />
        //             </a>
        //
        //             <a
        //                 style={{
        //                     cursor: 'pointer'
        //                 }}
        //                 onClick={handleXml}>
        //                 <XmlFile />
        //             </a>
        //         </div>
        //     )
        // }
    ]);

    let { transactions: items, created_at, updated_at } = payouts;

    items = items?.map(item => {
        return {
            ...item,
            created_at: created_at.split('T')[0],
            updated_at: updated_at.split('T')[0]
        }
    })

    return (
        <div className={'card'}>
            <div style={{
                position: 'relative'
            }}>
                <Fade in={items?.length > 0 && !loading}>
                    { <div id="payments-table">
                        <CardBody>
                            {/*<Col>*/}
                            {/*    <h4 style={{*/}
                            {/*        marginBottom: '20px'*/}
                            {/*    }}>Details view for payout</h4>*/}
                            {/*</Col>*/}
                            <DataTable
                                title={'Below the transactions\n'}
                                columns={columns()}
                                data={items}
                                theme="solarized"
                                sortServer
                                progressComponent={<DataTableProgressComponent />}
                                noDataComponent={<DataTableNoDataComponent />}
                            />
                        </CardBody>
                    </div> }
                </Fade>

                {
                    loading &&  <span className={'span-payouts'}>
                        Loading ...
                    </span>
                }

                <Fade in={!items?.length > 0 && !loading}>
                    <span className={'span-payouts'}>
                         Payouts not yet
                    </span>
                </Fade>
            </div>
        </div>
    );
}

export default memo(PayoutsView);