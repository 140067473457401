import React, { useCallback, useEffect, useState } from 'react'
import { getCustomerInfo } from '../../actions/customerActions';
import Fade from '@material-ui/core/Fade';

const CustomerInfo = ({ match: { params } }) => {
    const [data, setData] = useState({});
    const [loading,setLoading] = useState(true);

    const handleCustomer = useCallback(async (params) => {
        setLoading(true)
        const response = await getCustomerInfo(params);


        await setData(response);
        await setLoading(false);
    }, []);

    useEffect(() => {
        handleCustomer(params)

        return () => setLoading(false);
    }, []);

    return (
        <div className='row'>
            <div className='col-12 col-lg-12'>
                <div className='card'>
                    <div class="card-header">
                        <i class="fa fa-align-justify"></i> Customer details
                    </div>

                    <div className='card-body'>
                        <div className='row'>
                            <div class="col-12 col-lg-6">
                                <fieldset class="p-2 pb-5 pl-5 pr-5">
                                    <legend class="d-inline">User Details</legend>
                                    <div>
                                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0px, 1fr))', gap: '70px' }}>
                                            <div style={{ fontSize: 14 }}>
                                                <p>Active</p>
                                                <p>Business Client</p>
                                                <p>ID</p>
                                                <p>GUID</p>
                                                <p>Username</p>
                                                <p>Alias</p>
                                                <p>Member</p>
                                                <p>Create at</p>
                                                <p>Update at</p>
                                                <p>Genger</p>
                                                <p>Birtday</p>
                                            </div>
                                            <div>
                                                <p>{JSON.stringify(data?.active) || '-'}</p>
                                                <p>-</p>
                                                <p>{data?.id || '-'}</p>
                                                <p>{data?.guid || '-'}</p>
                                                <p>
                                                    {(data?.profile?.first_name || "-") + ' ' + (data?.profile?.last_name || '-')}
                                                </p>
                                                <p>-</p>
                                                <p>{JSON.stringify(data?.membership)}</p>
                                                <p>{data?.created_at?.split('T')[0] || '-'}</p>
                                                <p>{data?.updated_at?.split('T')[0] || '-'}</p>
                                                <p>{data?.profile?.gender || '-'}</p>
                                                <p> {data?.profile?.birthday || '-'} </p>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>

                    <Fade in={loading} mountOnEnter unmountOnExit>
                        <span style={{
                            position: 'fixed',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            marginLeft: 150,
                            color: 'black',
                            background: 'white',
                            zIndex: 100,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 40,
                            textAlign: 'center'
                        }}>Loading ...</span>
                    </Fade>
                </div>
            </div>
        </div>

    )
}

export default CustomerInfo;