import React, {Component} from 'react';
import { useHistory } from 'react-router-dom'
import debounce from 'lodash/debounce';
import { PropTypes, array, bool } from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form'
import Address from '../../shared/components/form-section/Address';
import Contact from '../../shared/components/form-section/Contact';
import Shipping from '../../shared/components/form-section/Shipping';
import Tabs from '../../shared/components/tabs/Tabs';

import { Form, Input, Button, Card, CardColumns, CardTitle, CardBody, CardHeader, Col, Row, Label } from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';

import SimpleLineIcon from 'react-simple-line-icons';
import { renderField, renderNumberField, renderTextareaField, renderSelectField, renderCheckbox, renderDateField, renderMembers, renderCombobox, renderMultiselect, renderRadioGroup } from '../../shared/components/form-field/ReduxFormFields';
import { UploadFile } from '../../shared/components/form-field/UploadFile';

import { reset } from 'redux-form';
import { SubmissionError } from 'redux-form'
import { stopSubmit } from 'redux-form'

import { getCashback, addCashback, updateCashback, resetCashback } from '../../actions/cashbackActions';
import { getCategories } from '../../actions/categoryActions';
import { getCountries } from '../../actions/countryActions';
import { getBrands } from '../../actions/brandActions';
import { getBanks } from '../../actions/bankActions';
import { checkAuth } from '../../actions/sessionActions';
import { validate } from './validate';

import './CashbackForm.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../shared/redux/configureStore';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Radio} from "@material-ui/core";

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

// Configuring Redux Store
const store = configureStore(window.initialState);


const messages = {
  emptyFilterList: 'No results. Try another query'
}

const renderDescription = (name, label) => {
  return (
      <Field
        name={name}
        type="text"
        component={renderTextareaField}
        label={label}
        className="form-control"
        asterisk="*"
      />
    )
}

const renderTitle = (name, label, asterisk) => {
  return (
      <Field
        name={name}
        type="text"
        component={renderField}
        label={label}
        className="form-control"
        asterisk={asterisk}
      />
  )
}

const renderTerms = (name, label, asterisk) => {
  return (
      <Field
        name={name}
        type="text"
        component={renderTextareaField}
        label={label}
        className="form-control"
        asterisk={asterisk}
      />
  )
}

const withHistory = (Component) => {
  return props => <Component {...props} history={useHistory()} />
}

class CashbackForm extends Component {

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      id: this.props.match.params.id,
      open: false,
      create: false,
      categories: null,
      countries: null,
      brands: null,
      selectedBrand: '',
      selectedUnit: '',
      brandSearchResult: null,
      countrySearchResult: null,
      categorySearchResult: null,
      bankSearchResult: null,
      targeted: null,
      offline: null,

      onlineStatus: null,

      cashbackIsLoaded: false,
      descriptionEN: null,
      descriptionDE: null,
      termDE: null,
      termEN : null
    }
    this.submit = this.submit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleUnitChange = this.handleUnitChange.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
    this.handleBrandSearch = this.handleBrandSearch.bind(this);
    this.handleCountrySearch = this.handleCountrySearch.bind(this);
    this.handleCategorySearch = this.handleCategorySearch.bind(this);
    this.handleBankSearch = this.handleBankSearch.bind(this);
    this.handleTargetedChange = this.handleTargetedChange.bind(this);
    this.handleOfflineChange = this.handleOfflineChange.bind(this);
    this.handleTranslate = this.handleTranslate.bind(this);

    this.handleOnlineStatusChange = this.handleOnlineStatusChange.bind(this);
    this.handleChangeTermEN = this.handleChangeTermEN.bind(this);
    this.handleChangeTermDE = this.handleChangeTermDE.bind(this);
    this.handleChangeDescriptionEN = this.handleChangeDescriptionEN.bind(this)
    this.handleChangeDescriptionDE = this.handleChangeDescriptionDE.bind(this)
  }

  async componentDidMount(props) {
    console.log('componentDidMount');
    console.log(props);

    const {getCashback, addCashback, updateCashback, getCategories, getCountries, getBrands, getBanks } = this.props;
    if(this.state.id) {
      this.setState({cashbackIsLoaded: false})
      await getCashback(this.state.id)
      this.setState({cashbackIsLoaded: true})
    }
    else {
      this.setState({targeted: false});
      this.setState({cashbackIsLoaded: true})
    }

    getCategories();
    getCountries();
    getBrands();
    getBanks();
  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate')
    console.log(this.props);
    if(!this.props.match.params.id) {
      if(!this.state.create) {
        //this.props.dispatch(reset('cashbackForm'));  // requires form name
        this.props.initialize();
        store.dispatch(resetCashback());
        this.setState({create: true});
        this.setState({categories: null});
        this.setState({countries: null});
        this.setState({brands: null});
        this.setState({banks: null});
      }
    }
    if(this.props.match.params.id) {
      if(this.state.categories==null && this.props.cashback?.categories) {
        this.setState({categories: this.props.cashback.categories.map(item=>item.id)});
        this.props.change('categories', this.props.cashback.categories.map(category => ({ id: category.id, name: category.name?.en || category.name })));
      }
      if(this.state.countries==null && this.props.cashback?.countries) {
        this.setState({countries: this.props.cashback.countries.map(item=>item.code)});
        this.props.change('countries', this.props.cashback.countries.map(country => ({ id: country.id, code: country.code, name: country.name?.en || country.name })));
      }
      if(this.state.brands==null && this.props.cashback?.brands) {
        this.setState({brands: this.props.cashback.brands.map(item=>item.id)});
        this.props.change('brands', this.props.cashback.brands.map(brand => ({ id: brand.id, name: brand.name })));
      }
      if(this.state.banks==null && this.props.cashback?.banks) {
        this.setState({banks: this.props.cashback.banks.map(item=>item.id)});
        this.props.change('banks', this.props.cashback.banks.map(bank => ({ id: bank.id, name: bank.name })));
      }

    }
  }

  handleChangeDescriptionEN(event) {
    event.persist()
    this.setState({ descriptionEN: event.target.value })
  }

  handleChangeTermEN(event) {
    event.persist()
    this.setState({ termEN: event.target.value })
  }

  handleChangeTermDE(event) {
    event.persist()
    this.setState({ termDE: event.target.value })
  }

  handleChangeDescriptionDE(event) {
    event.persist()
    this.setState({ descriptionDE: event.target.value })
  }

  async handleTranslate(e) {
    e.preventDefault();
    const de = this.state.descriptionDE
    const termDe = this.state.termDE
    // encodeURIComponent(termDe)
    const myHeaders = new Headers();
    myHeaders.append("accept", "*/*");

    const formdata = new FormData();
    formdata.append("key", "318ae2802b754541b3fd2a1ac403c525");
    formdata.append("text", termDe);
    formdata.append("target_lang", "en");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    };

    if(termDe) {
      const requestTerm = await fetch(`https://brandportal.benfido.io/actions/base-module/api/translate`, requestOptions);
      const { translations : translationsTerm = []} = await requestTerm.json();
      this.setState({ termEN: translationsTerm[0]?.text || '' })
    }

    if(de) {
      const request = await fetch(`https://brandportal.benfido.io/actions/base-module/api/translate`, requestOptions);

      const { translations = [] } = await request.json();

      this.setState({ descriptionEN: translations[0]?.text || '' })
    }
  }

  // Save Cashback
  submit(values) {
    var action;

    values.description = {
      en: this.state?.descriptionEN,
      de: this.state?.descriptionDE
    }

    values.description_translations = {
      en: this.state?.descriptionEN,
      de: this.state?.descriptionDE
    }

    values.terms = {
      en: this.state?.termEN,
      de: this.state?.termDE
    }

    values.terms_translations = {
      en: this.state?.termEN,
      de: this.state?.termDE
    }

    if(typeof(values.active)=='undefined') {
      values.active = false;
    }

    if(values.categories!=null && values.categories.length>0) {
      values.category_ids = values.categories.map(item=>item.id)
    }
    else {
      values.category_ids = [null];
    }

    if(values.countries!=null && values.countries.length>0) {
      values.country_codes = values.countries.map(item=>item.code)
      values.country_ids = values.countries.map(item=> item.id)
    }
    else {
      values.country_codes = [];
      values.country_ids = [];
    }

    if(values.brands!=null && values.brands.length>0) {
      values.brand_ids = values.brands.map(item=>item.id)
    }
    else {
      values.brand_ids = [null];
    }

    if(this.state.banks!=null && values.banks.length>0) {
      values.bank_ids = values.banks.map(item=>item.id)
    }
    else {
      values.bank_ids = [null];
    }

    if(this.state.selectedBrand!=null) {
      values.brand_id = values.brand.id;
    }
    else {
      values.brand_id = null;
    }

    if (values?.title?.de && !values?.title?.en) {
      values.title.en = values.title.de
    }

    if(this.state.create) {
      action = addCashback(values);
    }
    else {
      action = updateCashback(values);
    }
    store.dispatch(action).then((result) => {
      if(typeof(result)!='undefined') {
        this.setState({open: true});
        setTimeout(() => {
          // this.props.history.push('/')
          window.location.href = window.location.origin + '/cashbacks'
        }, 500)
      }
    }).catch((error) => {
      throw new SubmissionError({_error:  error });
    });
  }

  handleClose() {
    this.setState({open: false});
  }

  handleUnitChange(e) {
    this.setState({selectedUnit: e=='' ? null : e});
  }

  handleBrandChange(e) {
    this.setState({selectedBrand: e=='' ? null : e});
    if(typeof(e.id)=='undefined') {
      this.handleBrandSearch(e);
    }
  }

  handleOnlineStatusChange(onlineStatus) {
    console.log('---handleOnlineStatusChange')
    console.log(onlineStatus)
    this.setState({onlineStatus})
  }

  handleBrandSearch = debounce((searchTerms) => {
    this.brandSearch(searchTerms);
  }, 500);

  brandSearch(searchTerms) {
    console.log('handleBrandSearch');
    var params = {}
    var search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getBrands(params)).then((result) => {
      var brandSearchResult = result.payload.items.map(brand => ({ id: brand.id, name: brand.name }));
      this.setState({brandSearchResult: brandSearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleCountrySearch = debounce((searchTerms) => {
    this.countrySearch(searchTerms);
  }, 500);

  countrySearch(searchTerms) {
    console.log('handleCountrySearch');
    var params = {}
    var search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getCountries(params)).then((result) => {
      var countrySearchResult = result.payload.items.map(country => ({ code: country.code, name: country.name?.en || country.name }));
      this.setState({countrySearchResult: countrySearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleCategorySearch = debounce((searchTerms) => {
    this.categorySearch(searchTerms);
  }, 500);

  categorySearch(searchTerms) {
    console.log('handleCategorySearch');
    var params = {}
    var search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getCategories(params)).then((result) => {
      var categorySearchResult = result.payload.items.map(category => ({ id: category.id, name: category.name?.en || category.name }));
      this.setState({categorySearchResult: categorySearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleBankSearch = debounce((searchTerms) => {
    this.bankSearch(searchTerms);
  }, 500);

  bankSearch(searchTerms) {
    console.log('handleBankSearch');
    var params = {}
    var search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getBanks(params)).then((result) => {
      var bankSearchResult = result.payload.items.map(bank => ({ id: bank.id, name: bank.name }));
      this.setState({bankSearchResult: bankSearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleTargetedChange(e) {
    this.setState({targeted: e.target.checked});
  }

  handleOfflineChange(e) {
    this.setState({offline: e.target.checked});
  }

  render() {
    const isOnlineCheck = this.props?.isOnlineCheck
    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

    const { error, handleSubmit, pristine, reset, submitting, message, categories, countries, brands, banks, cashback } = this.props

    console.log('---render')

    if (
      (!this.state.descriptionEN && !this.state.descriptionDE) &&
      cashback?.description && this.state.cashbackIsLoaded && this.state.id
    ) {

      console.log(cashback,'cashback')
      this.setState({ descriptionEN: cashback?.description?.en })
      this.setState({ descriptionDE: cashback?.description?.de })
      this.setState({ termDE: cashback?.terms?.de })
      this.setState({ termEN: cashback?.terms?.en })
    }



    var targeted = false;
    if(this.state.targeted==null && typeof(cashback)=='undefined') {
      targeted = false;
    }
    else if(this.state.targeted==null && typeof(cashback)!='undefined') {
      targeted = cashback.targeted ? true : false;
    }
    else {
      targeted = this.state.targeted;
    }
    console.log('---targeted');
    console.log(targeted);


    var offline = false;
    if(this.state.offline==null && typeof(offline)=='undefined') {
      offline = false;
    }
    else if(this.state.offline==null && typeof(cashback)!='undefined') {
      offline = cashback.offline ? true : false;
    }
    else {
      offline = this.state.offline;
    }
    console.log('---offline');
    console.log(offline);

    var selectedBrand = '';
    var selectedUnit = '';
    if(typeof(cashback)!='undefined') {
      selectedBrand = cashback.brand.id;
      selectedUnit = cashback.unit;
    }
    if(this.state.selectedBrand==null) {
      selectedBrand = '';
    }
    else if(this.state.selectedBrand!='') {
      selectedBrand = this.state.selectedBrand;
    }
    if(this.state.selectedUnit==null) {
      selectedUnit = '';
    }
    else if(this.state.selectedUnit!='') {
      selectedUnit = this.state.selectedUnit;
    }

    var categoriesArr = categories.map(category => ({ id: category.id, name: category.name?.en || category.name }));
    if(this.state.categorySearchResult!=null) {
      categoriesArr = this.state.categorySearchResult;
    }
    if(typeof(cashback)!='undefined' && typeof(cashback.categories)!='undefined' && cashback.categories!=null) {
      var cashbackCategories =  cashback.categories;
      for(var i=0; i<cashbackCategories.length; i++) {
        var category = cashbackCategories[i];
        const found = categoriesArr.some(item => item.id === category.id);
        if(!found) {
          categoriesArr = categoriesArr.concat({id: category.id, name: category.name?.en || category.name});
        }
      }
    }
    categoriesArr = categoriesArr.concat({id: 0, name: '...'});

    var countriesArr = countries.map(country => ({ id: country.id, code: country.code, name: country.name?.en || country.name }));
    if(this.state.countrySearchResult!=null) {
      countriesArr = this.state.countrySearchResult;
    }
    if(typeof(cashback)!='undefined' && typeof(cashback.countries)!='undefined' && cashback.countries!=null) {
      var cashbackCountries =  cashback.countries;
      for(var i=0; i<cashbackCountries.length; i++) {
        var country = cashbackCountries[i];
        const found = countriesArr.some(item => item.id === country.id);
        if(!found) {
          countriesArr = countriesArr.concat({id: country.id, code: country.code, name: country.name?.en || country.name});
        }
      }
    }
    countriesArr = countriesArr.concat({code: '', name: '...'});

    var brandsArr = brands.map(brand => ({ id: brand.id, name: brand.name }));
    if(this.state.brandSearchResult!=null) {
      brandsArr = this.state.brandSearchResult;
    }
    if(typeof(cashback)!='undefined' && typeof(cashback.brands)!='undefined' && cashback.brands!=null) {
      var cashbackBrands =  cashback.brands;
      for(var i=0; i<cashbackBrands.length; i++) {
        var brand = cashbackBrands[i];
        const found = brandsArr.some(item => item.id === brand.id);
        if(!found) {
          brandsArr = brandsArr.concat({id: brand.id, name: brand.name});
        }
      }
    }
    brandsArr = brandsArr.concat({id: 0, name: '...'});

    var banksArr = banks.map(bank => ({ id: bank.id, name: bank.name }));
    if(this.state.bankSearchResult!=null) {
      banksArr = this.state.bankSearchResult;
    }
    if(typeof(cashback)!='undefined' && typeof(cashback.banks)!='undefined' && cashback.banks!=null) {
      var cashbackBanks =  cashback.banks;
      for(var i=0; i<cashbackBanks.length; i++) {
        var bank =  cashbackBanks[i];
        const found = banksArr.some(item => item.id === bank.id);
        if(!found) {
          banksArr =  banksArr.concat({id: bank.id, name: bank.name});
        }
      }
    }
    banksArr = banksArr.concat({id: 0, name: '...'});

    var brandsSingleSelectArr = brands.map(brand => ({ value: brand.id, text: brand.name }));

    let onlineStatus
    if (cashback != null && this.state.onlineStatus == null) {
      onlineStatus = cashback.online ? 'online' : 'offline'
    } else onlineStatus = this.state.onlineStatus

    console.log('onlineStatus')
    console.log(onlineStatus)

    const modules = {
      toolbar: [
        [{ 'header': [2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
      ],
    }



    return (
      <div className="animated fadeIn mt-5 form-sections">
        <h1 className="h3 text-uppercase">Cashback</h1>
        <form onSubmit={handleSubmit(this.submit)} ref={this.form}>
          <Row>
            <Col xs="12" lg="6">
                <Card>
                  <CardBody>
                    <Card>
                      <CardBody>
                      <CardTitle className="d-inline">Offer</CardTitle>
                        <Field name="targeted" component={renderCheckbox} color="primary" label="Targeted" onChange={(event)=>this.handleTargetedChange(event)} />
                        <Row>
                          <Col xs="12">
                            <Field
                              name="website"
                              type="text"
                              component={renderField}
                              label="Website"
                              className="form-control"
                              asterisk={isOnlineCheck ? '*' : ''}
                            />
                          </Col>
                        </Row>
                        {/*<Row>
                          <Col xs="12">
                            <div className="d-block d-sm-flex align-items-center">
                              <Label className="mr-3 pb-1">Valid</Label>
                              <Field name="online" component={renderCheckbox} color="primary" label="Online" />
                              <Field name="offline" component={renderCheckbox} color="primary" label="Offline"
                                     onChange={(event)=>this.handleOfflineChange(event)} />
                            </div>
                          </Col>
                        </Row>*/}


                        <div className="flex align-items-center">
                          <Label className="mr-3">Valid</Label>
                          <Field name="onlineStatus" className="flex flex-row align-items-center" component={renderRadioGroup}
                                 defaultValue={onlineStatus}
                                 onChange={this.handleOnlineStatusChange}>
                            <FormControlLabel value="offline" control={<Radio color="primary" />} label="Offline" />
                            <FormControlLabel value="online" control={<Radio color="primary" />} label="Online" />
                          </Field>
                        </div>


                        <Field
                          name="brand"
                          type="select"
                          component={renderCombobox}
                          label="Brand - type to search Brand e.g. Adidas"
                          data={brandsArr}
                          defaultValue={selectedBrand}
                          valueField="id"
                          textField="name"
                          filter={true}
                          className="p-0 pr-2"
                          onChange={(event) => this.handleBrandChange(event)}
                          disabled={[{id:0, name: '...'}]}
                          asterisk="*"
                        />

                        {this.state.cashbackIsLoaded &&
                          <Row className="mt-3 mb-3 translations">
                            <Col xs="12" className="pr-4 relative">
                              <Tabs tabs={[
                                {label: 'DE', items: [
                                    renderTitle('title.de', 'Title', '*'),
                                    <div className="mb-2 mr-2">
                                      <label>Description <span className="text-danger">*</span></label>
                                      <div>
                                        <textarea name="description.de"
                                                  value={this.state.descriptionDE}
                                                  placeholder="Description"
                                                  onChange={(e) => this.handleChangeDescriptionDE(e)}
                                                  className="form-control"/>
                                      </div>
                                    </div>,
                                    <div className="mb-2 mr-2">
                                      <label>Terms <span className="text-danger">*</span></label>
                                      <div>
                                        <textarea name="terms.de"
                                                  value={this.state.termDE}
                                                  placeholder="Terms"
                                                  onChange={(e) => this.handleChangeTermDE(e)}
                                                  className="form-control"/>
                                      </div>
                                    </div>,
                                    // renderTerms('terms.en', 'Terms', '*')
                                  ]},

                                {label: 'EN', items: [
                                    renderTitle('title.en', 'Title'),
                                    <div className="mb-2 mr-2"><label>Description <span className="text-danger">*</span></label>
                                      <div>
                                        <textarea name="description.en"
                                                  value={this.state.descriptionEN}
                                                  placeholder="Description"
                                                  onChange={(e) => this.handleChangeDescriptionEN(e)}
                                                  className="form-control"/>
                                      </div>
                                    </div>,
                                    <div className="mb-2 mr-2">
                                      <label>Terms <span className="text-danger">*</span></label>
                                      <div>
                                        <textarea name="terms.en"
                                                  value={this.state.termEN}
                                                  placeholder="Terms"
                                                  onChange={(e) => this.handleChangeTermEN(e)}
                                                  className="form-control"/>
                                      </div>
                                    </div>,
                                  ]},
                              ]} />

                              <a className={'btn btn-primary'} style={{
                                position: 'absolute',
                                top: 0,
                                right: '1.5rem',
                                color: 'white',
                                cursor: 'pointer'
                              }}
                                 onClick={this.handleTranslate}
                              >Translate</a>
                            </Col>
                          </Row>
                        }
                        <Row className="mb-2">
                          <Col xs="12" lg="12">
                            <Label>Logo</Label><br />
                            <Field component={UploadFile} name='logo' accept='.jpg, .png' className="mb-3" />
                            {typeof(cashback)!='undefined' && cashback.logo!=null && !this.state.create &&
                              <img src={cashback.logo} className="img-thumbnail brnd-logo d-block" />
                            }
                          </Col>
                          <Col xs="12" lg="12">
                            <br />
                            <Label>Logo 2x</Label><br />
                            <Field component={UploadFile} name='logo_2x' accept='.jpg, .png' className="mb-3" />
                             {typeof(cashback)!='undefined' && cashback.logo_2x!=null && !this.state.create &&
                              <img src={cashback.logo_2x} className="img-thumbnail brnd-logo d-block" />
                            }
                          </Col>
                        </Row>
                        <Label>Countries - <em>Leave the field empty if you want the offer to be valid for all Countries.</em></Label>
                        <Field
                            name="countries"
                            component={renderMultiselect}
                            data={countriesArr}
                            value={[]}
                            valueField="code"
                            textField="name"
                            filter={false}
                            onSearch={this.handleCountrySearch}
                            messages={messages}
                            disabled={[{code:'', name: '...'}]}
                        />
                        <Label className="mt-1">Type to search Country e.g. Germany</Label>

                        <Field name="featured" component={renderCheckbox} color="primary" label="Featured. Appears ot top of lists." />
                        <Row>
                          <Col xs="6">
                            <Field
                              name="start_date"
                              type="date"
                              component={renderDateField}
                              label="Start date"
                              className="form-control"
                            />
                          </Col>
                          <Col xs="6">
                            <Field
                              name="end_date"
                              type="date"
                              component={renderDateField}
                              label="End date"
                              className="form-control"
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col xs="12" lg="6">
                            <Field
                                name="min_spent"
                                component={renderNumberField}
                                label="Min. spent"
                                className="form-control"
                            />
                          </Col>
                          <Col xs="12" lg="6">
                            <Field
                                name="max_spent"
                                component={renderNumberField}
                                label="Max. spent"
                                className="form-control"
                            />
                          </Col>
                        </Row>
                        <br /><br />
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle className="d-inline">Cashback Fields</CardTitle>
                        {onlineStatus === 'online' &&
                          <Row>
                            <Col xs="12" lg="6">
                             <Field
                                name="identifier"
                                type="text"
                                component={renderField}
                                label="Identifier"
                                className="form-control"
                                asterisk="*"
                              />
                            </Col>
                          </Row>
                        }
                        {onlineStatus === 'online' &&
                          <Field
                            name="url"
                            type="text"
                            component={renderField}
                            label="URL"
                            className="form-control"
                            asterisk="*"
                          />
                        }
                        <Row>
                          <Col xs="6">
                            <Field
                              name="amount"
                              component={renderNumberField}
                              label="Amount in %"
                              className="form-control"
                              asterisk="*"
                              min="0" max="100" step="0.01"
                            />
                          </Col>
                          {/*<Col xs="6">*/}
                          {/*  <Field*/}
                          {/*    name="unit"*/}
                          {/*    type="select"*/}
                          {/*    component={ renderSelectField }*/}
                          {/*    options={[{value: '%', text: '%'}, {value: '€', text: '€'}]}*/}
                          {/*    defaultValue={selectedUnit}*/}
                          {/*    label="Unit"*/}
                          {/*    className="form-control"*/}
                          {/*    onChange={(event) => this.handleUnitChange(event.target.value)}*/}
                          {/*    asterisk="*"*/}
                          {/*  />*/}
                          {/*</Col>*/}

                        </Row>
                        <Field
                          name="commission_fee"
                          component={ renderNumberField }
                          label="Commission fee"
                          className="form-control"
                        />
                        {onlineStatus === 'online' &&
                          <div className="d-flex mb-4">
                            <Field
                              name="vat"
                              component={ renderNumberField }
                              label="VAT"
                              className="form-control"
                            />
                            <Field
                              name="tolerance"
                              component={ renderNumberField }
                              label="Tolerance"
                              className="form-control"
                            />
                          </div>
                        }
                        {onlineStatus === 'online' &&
                          <Shipping />
                        }
                      </CardBody>
                    </Card>
                  </CardBody>
                </Card>
            </Col>
            {targeted &&
            <Col xs="12" lg="6">
                <Card>
                  <CardBody>
                    <Card>
                      <CardBody>
                        <CardTitle>Targeting</CardTitle>
                        <div className="d-flex align-items-center">
                          <div><label className="mb-2 mr-3 MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">Gender</label></div>
                          <Field name="male" component={renderCheckbox} color="primary" label="Male" />
                          <Field name="female" component={renderCheckbox} color="primary" label="Female" />
                        </div>
                        <Row>
                          <Col xs="6">
                            <Field
                              name="start_age"
                              type="number"
                              component={renderField}
                              label="Start age"
                              className="form-control"
                            />
                          </Col>
                          <Col xs="6">
                            <Field
                              name="end_age"
                              type="number"
                              component={renderField}
                              label="End age"
                              className="form-control"
                            />
                          </Col>
                        </Row>
                        <br />
                        <Label>Banks - <em>Leave the field empty if you want the offer to be valid for all Banks.</em></Label>
                        <Field
                          name="banks"
                          component={renderMultiselect}
                          data={banksArr}
                          value={[]}
                          valueField="id"
                          textField="name"
                          filter={false}
                          onSearch={this.handleBankSearch}
                          messages={messages}
                          disabled={[{id:0, name: '...'}]}
                        />
                        <Label className="mt-1">Type to search Banks e.g. DKB</Label>
                        <br /><br />
                        <label>Categories - <em>Leave the field empty if you want the offer to be valid for all Categories.</em></label>
                        <Field
                          name="categories"
                          component={renderMultiselect}
                          data={categoriesArr}
                          value={[]}
                          valueField="id"
                          textField="name"
                          filter={false}
                          onSearch={this.handleCategorySearch}
                          messages={messages}
                          disabled={[{code:'', name: '...'}]}
                        />
                        <Label className="mt-1">Type to search Category e.g. Electronics</Label>
                        <br /><br />
                        <Label>Brands - <em>Leave the field empty if you want the offer to be valid for all Brands.</em></Label>
                        <Field
                          name="brands"
                          component={renderMultiselect}
                          data={brandsArr}
                          value={[]}
                          valueField="id"
                          textField="name"
                          filter={false}
                          onSearch={this.handleBrandSearch}
                          messages={messages}
                          disabled={[{id:0, name: '...'}]}
                        />
                        <Label className="mt-1">Type to search Brands e.g. Adidas</Label>
                        <br /><br />
                        <Row>
                          <Col xs="12" lg="6">
                            <Field
                              name="turnover_by_brand"
                              component={renderNumberField}
                              label="Min. turnover by brand in the last 6m"
                              className="form-control"
                            />
                          </Col>
                          <Col xs="12" lg="6">
                            <Field
                              name="frequency_by_brand"
                              component={renderNumberField}
                              label="Frequency by brand in the last 6m"
                              className="form-control"
                            />
                          </Col>
                        </Row>
                        <br/>
                        <Row>
                          <Col xs="12" lg="6">
                            <Field
                              name="turnover_by_category"
                              component={renderNumberField}
                              label="Min. turnover by category in the last 6m"
                              className="form-control"
                            />
                          </Col>
                          <Col xs="12" lg="6">
                            <Field
                              name="frequency_by_category"
                              component={renderNumberField}
                              label="Frequency by category in the last 6m"
                              className="form-control"
                            />
                          </Col>
                        </Row>
                        <br/>
                        {/*<Row>*/}
                        {/*  <Col xs="12" lg="6">*/}
                        {/*    <Field*/}
                        {/*      name="min_spent"*/}
                        {/*      component={renderNumberField}*/}
                        {/*      label="Min. spent"*/}
                        {/*      className="form-control"*/}
                        {/*    />*/}
                        {/*  </Col>*/}
                        {/*  <Col xs="12" lg="6">*/}
                        {/*    <Field*/}
                        {/*      name="max_spent"*/}
                        {/*      component={renderNumberField}*/}
                        {/*      label="Max. spent"*/}
                        {/*      className="form-control"*/}
                        {/*    />*/}
                        {/*  </Col>*/}
                        {/*</Row>*/}
                      </CardBody>
                    </Card>
                  </CardBody>
                </Card>
              </Col>
              }
              <Col xs="12">
                {!!error && <div className="form-error mt-3">{error && <strong>{error}</strong>}</div>}
                <div className={'text-white'}>
                  <Field name="active" component={renderCheckbox} label="Active" />
                  <button type="submit" className="btn btn-primary mb-5">Submit</button>
                </div>
              </Col>
          </Row>
        </form>
        <Snackbar open={this.state.open} autoHideDuration={2000} message="Saved" onClose={() => this.setState({open: false})}
          action={
            <React.Fragment>
              <a className="text-white cursor-pointer mr-2" aria-label="close" onClick={this.handleClose}><i className="cil-x"></i></a>
            </React.Fragment>
          }
        />
      </div>
    )
  }
}

CashbackForm.propTypes = {
  pristine: PropTypes.bool,
  message: PropTypes.string,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  cashback: PropTypes.object,
  brands: PropTypes.array,
  categories: PropTypes.array,
  countries: PropTypes.array,
  banks: PropTypes.array,
  session: PropTypes.any
};

function mapStateToProps(state) {
  console.log('STATE');
  console.log(state);
  if (
    !state?.form?.cashbackForm?.fieldsIsOverwritten &&
    ((state?.form?.cashbackForm?.values?.title_translations?.de && !state?.form?.cashbackForm?.values?.title?.de) ||
      (state?.form?.cashbackForm?.values?.title_translations?.en && !state?.form?.cashbackForm?.values?.title?.en))
  ) {
    state.form.cashbackForm.values.title = state.form.cashbackForm.values.title_translations
    state.form.cashbackForm.values.description = state.form.cashbackForm.values.description_translations
    state.form.cashbackForm.values.terms = state.form.cashbackForm.values.terms_translations

    state.form.cashbackForm.fieldsIsOverwritten = true
  }
    return {
      //formData: state.cashbacks,
      initialValues: typeof(state.cashbackForm)!=='undefined' ? state.cashbackForm.cashback : null,
      cashback: typeof(state.cashbackForm)!=='undefined' ? state.cashbackForm.cashback : null,
      categories: (typeof(state.categories)!=='undefined' && typeof(state.categories.result)!=='undefined') ? state.categories.result.items : [],
      countries: (typeof(state.countries)!=='undefined' && typeof(state.countries.result)!=='undefined') ? state.countries.result.items : [],
      brands: (typeof(state.brands)!=='undefined' && typeof(state.brands.result)!=='undefined') ? state.brands.result.items : [],
      banks: (typeof(state.banks)!=='undefined' && typeof(state.banks.result)!=='undefined') ? state.banks.result.items : [],
      session: state.session,
      isOnlineCheck: !!state?.form?.cashbackForm?.values?.online,
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({getCashback, addCashback, updateCashback, resetCashback,  getCategories, getCountries, getBrands, getBanks, checkAuth}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
   form: 'cashbackForm', // a unique identifier for this form,
   enableReinitialize: true,
   validate
})(withHistory(CashbackForm)))