import React, {useCallback, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import {createEditAdmin} from '../../actions/brandUpdateActions';
import Checkbox from '@material-ui/core/Checkbox';
import {resetUsersActions, usersActions} from '../../actions/usersActions';
import {useDispatch, useSelector} from 'react-redux';
import {usersSelector} from '../../selectors/users';
import Fade from '@material-ui/core/Fade';


function CreateBrand({ match, history }) {
    const [ changedBrand, setChangedBrand ] = useState({});
    const [loading, setLoading] = useState(false);

    console.log('11111');
    const handleInputChange = useCallback(({ target }) => {
        const { name, value, checked } = target;
        console.log(value , name);
        setChangedBrand(item => ({...item,
            [name]: name === 'active' ? checked : value,
        }))
    }, []);

    const submitForm = useCallback(async (e) => {
        e.preventDefault();
        setLoading(true);
        await createEditAdmin(changedBrand, 'create');

        setTimeout(() => setLoading(false), 500);
    }, [ changedBrand ]);

    return (
        <div>
            <Card>
                <CardBody>
                    <CardHeader>
                        <div className="d-flex align-items-center justify-content-between">
                            <div><i className="fa fa-align-justify"></i>Change Users Info</div>
                        </div>
                    </CardHeader>

                    <form onSubmit={submitForm}>
                        <CardBody>
                            <Row>
                                <Col xs={6}>
                                    <div className="mb-2 mr-2">
                                        <label>Email</label>
                                        <div>
                                            <input required onChange={handleInputChange} name="email" type="email" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="mb-2 mr-2">
                                        <label>Password</label>
                                        <div>
                                            <input required onChange={handleInputChange} name="password" type="password" className="form-control" />
                                        </div>
                                    </div>

                                    <div className="mb-2 mr-2">
                                        <label>Brand id</label>
                                        <div>
                                            <input required onChange={handleInputChange} name="brand_id" type="number" className="form-control" />
                                        </div>
                                    </div>


                                </Col>

                                <Col xs={6}>
                                    <div className="mb-2 mr-2">
                                        <label>Locale</label>
                                        <div>
                                            <select onChange={handleInputChange} className="form-control" name="" id="">
                                                <option value="en">En</option>
                                                <option value="de">De</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="mb-2 mr-2">
                                        <label>
                                            Active:
                                            <Checkbox
                                                onChange={handleInputChange}
                                                name={'active'}
                                                defaultChecked={false}
                                            />
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <button type="submit" className="btn btn-primary mt-3">Submit</button>
                                </Col>
                            </Row>
                        </CardBody>
                    </form>
                </CardBody>
            </Card>
            <Fade in={loading} mountOnEnter unmountOnExit>
                        <span style={{
                            position: 'fixed',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            marginLeft: 150,
                            color: 'black',
                            background: 'white',
                            zIndex: 100,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 40,
                            textAlign: 'center'
                        }}>Loading ...</span>
            </Fade>
        </div>
    );
}

export default CreateBrand;