import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Badge, Card, CardBody, CardHeader, Col, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';

import { Bar, Doughnut, Line, Pie, Polar, Radar } from 'react-chartjs-2';
import 'chartjs-plugin-labels';
//import 'chartjs-plugin-datalabels';

import {WidgetTwo, AnalyticFilterForm} from './Widget';

// Actions
import { checkAuth } from '../../actions/sessionActions';

import { UserContext } from '../../shared/context/user';
import { ThemeContext } from '../../shared/context/theme';

var colors = ["#0f358e","#1d3557","#457b9d","#a8dadc","#f1faee","#f91f43","#e76f51","#f4a261","#e9c46a","#f4e3b8", "#2A9D8F"];

const optionsDark = {
                      layout: {
                        padding: {
                          top: 10
                        }
                      },
                      legend: {
                        display: false,
                        labels: {
                          // This more specific font property overrides the global property
                          fontColor: '#F3A261'
                        }
                      },
                      scales: {
                        xAxes: [{
                          barPercentage: 0.4,
                          gridLines: {color: '#404147'},
                          ticks: {fontColor: '#939AA1'}
                        }],
                        yAxes: [{
                          barPercentage: 0.4,
                          gridLines: {color: '#404147'},
                          ticks: {fontColor: '#939AA1'}
                        }],
                      },
                      plugins: {
                        labels: {
                          render: 'value',
                          fontColor: '#447D9B',
                        }
                      }
                    }
const optionsLight =  {
                        layout: {
                          padding: {
                            top: 10
                          }
                        },
                        legend: {
                          display: false,
                          labels: {
                            // This more specific font property overrides the global property
                            fontColor: 'black'
                          }
                        },
                        scales: {
                          xAxes: [{
                            barPercentage: 0.4,
                            gridLines: {color: 'rgba(0,0,0,0.05)'},
                            ticks: {fontColor: 'black'}
                          }],
                          yAxes: [{
                            barPercentage: 0.4,
                            gridLines: {color: 'rgba(0,0,0,0.05)'},
                            ticks: {fontColor: 'black'}
                          }],
                        },
                        plugins: {
                          labels: {
                            render: 'value',
                            fontColor: '#F3A261',
                          }
                        }
                      }
const barData = {
        datasets: [{
            label: 'Chart',
            data: [10, 20, 30, 40, 15, 20, 30, 59, 11, 59, 5, 2],
            fontColor: 'red',
            //backgroundColor: 'rgba(0,0,0,0.5)',
            //backgroundColor: colors,
            lineTension: 0,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'darkorange',
            pointBackgroundColor: 'rgba(255,255,255,0.5)',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 10  
        }
        ],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
const lineData = {
        datasets: [{
            label: 'Chart',
            data: [10, 20, 30, 40, 15, 20, 30, 59, 11, 59, 5, 2],
            fontColor: 'red',
            //backgroundColor: 'rgba(0,0,0,0.5)',
            //backgroundColor: colors,
            borderColor: '#447D9B',
            borderWidth: 1,
            lineTension: 0,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#447D9B',
            pointBackgroundColor: 'rgba(255,255,255,1.5)',
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 10  
        }
        ],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }


class Metrics extends Component {
  
  constructor(props) {
    super(props);
    var options = optionsLight;
    if(localStorage.getItem('theme')=='dark') {
      options = optionsDark;
    }
    this.state = {
      theme: 'light',
      barOptions: options
    };
  }
  componentDidMount() {
    this.setState({theme: this.context.theme.theme});
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.theme != this.context.state.theme) {
      this.setState({theme: this.context.state.theme});
      if(this.context.state.theme=='dark') {
        this.setState({barOptions: this.state.barOptions = optionsDark});  
      }
      else {
        this.setState({barOptions: this.state.barOptions = optionsLight}); 
      }
    }  
  }
  
  render() {
    
    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

    return (
      <UserContext.Consumer>
      { user => (
      <div className="analytic mb-5">
        <div className="row">
          
          <div className="col-12"><AnalyticFilterForm /></div>

          <div className="col-12 col-sm-4">
            <WidgetTwo title="Conversion rate(CR)" value1={20} value2={10} prefix='%' /> 
          </div>
           <div className="col-12 col-sm-4">
            <WidgetTwo title="Cancellation transactions" value1={20} value2={30} prefix='%' /> 
          </div>
           <div className="col-12 col-sm-4">
            <WidgetTwo title="Cancellation payments" value1={120} value2={110} prefix='%' /> 
          </div>
           <div className="col-12 col-sm-4">
            <WidgetTwo title="Cashback per Customer" value1={201} value2={140} prefix='€ ' /> 
          </div>
           <div className="col-12 col-sm-4">
            <WidgetTwo title="Cashback Total per Customer" value1={1220} value2={1110} prefix='€ ' /> 
          </div>
           <div className="col-12 col-sm-4">
            <WidgetTwo title="Cancellation Payments" value1={20} value2={10} prefix='%' /> 
          </div>
           <div className="col-12 col-sm-4">
            <WidgetTwo title="Turnover per Customer" value1={20} value2={10} prefix='€ ' /> 
          </div>
           <div className="col-12 col-sm-4">
            <WidgetTwo title="Turnover per Transaction" value1={120} value2={210} prefix='€ ' /> 
          </div>
           <div className="col-12 col-sm-4">
            <WidgetTwo title="Cashback per Transaction" value1={220} value2={310} prefix='€ ' /> 
          </div>
           <div className="col-12 col-sm-4">
            <WidgetTwo title="Turnover all Customers in period" value1={20} value2={10} prefix='€ ' /> 
          </div>
           <div className="col-12 col-sm-4">
            <WidgetTwo title="Cashback all Customers in Period" value1={20} value2={10} prefix='€ ' /> 
          </div>
           <div className="col-12 col-sm-4">
            <WidgetTwo title="Customer Activity in Period" value1={20} value2={10} prefix='%' /> 
          </div>
        </div>
      </div>
      )}
      </UserContext.Consumer>
    );
  }
}

function mapStateToProps(state) {
    return {
      isMobile: state.device.isMobile,
      session: state.session      
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({checkAuth}, dispatch);
Metrics.contextType = ThemeContext;
export default connect(
        mapStateToProps,
        mapDispatchToProps
        )(Metrics);
