import * as types from '../actions/actionTypes';
import initialState from './initialState';
//import {browserHistory} from 'react-router';

import {setCookie, getCookie} from '../shared/utils/cookies';


export default function accountsReducer(state = initialState.session, action) {
  switch(action.type) {
    case types.GET_ACCOUNT_DETAILS_SUCCESS:
      console.log('ACTION');
      console.log(action.payload)
      return Object.assign({}, state, {success: true, user: action.payload});
      break;
    case types.GET_ACCOUNT_DETAILS_ERROR:
      return Object.assign({}, state, {error: action.payload});
      break;  

    default: 
      return state;
  }
}

