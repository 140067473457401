// Action Types
import {GET_REVIEW_SUCCESS, GET_REVIEWS_SUCCESS, ADD_REVIEW_SUCCESS, ADD_REVIEW_ERROR, UPDATE_REVIEW_ERROR} from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function reviewFormReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REVIEW_SUCCESS: {
      const {payload: review} = action;
      return getNewState(state, {review});
      break;
    }
    case ADD_REVIEW_SUCCESS: {
      const {payload: review} = action;
      return getNewState(state, {review});
      break;
    }
    case ADD_REVIEW_ERROR: {
      const {payload: review} = action;
      return getNewState(state, {review});
      break;
    }
    case UPDATE_REVIEW_ERROR: {
      const {payload: review} = action;
      return getNewState(state, {review});
      break;
    }
    default:
      return state;
  }
}
