// Dependencies
import axios from 'axios';
import { API_URL, X_API_KEY } from '../conf.js';
import { getCookie } from '../shared/utils/cookies';
import { axiosInterceptors } from '../shared/utils/axiosInterceptors';

// Action Types
import {
  GET_TRANSACTION_REQUEST,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_ERROR,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_ERROR,
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_ERROR,
  UPDATE_TRANSACTION_REQUEST,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_ERROR, DELETE_TRANSACTION_REQUEST, DELETE_TRANSACTION_SUCCESS, DELETE_TRANSACTION_ERROR
} from './actionTypes';

// Base Actions
import { request, received, error } from '../shared/redux/baseActions';

const getTransaction = (params) => dispatch => {
  dispatch(request(GET_TRANSACTION_REQUEST));
  var id = '';
  var bank_id = '';
  if (typeof (params) !== 'undefined') {
    id = params.id;
    bank_id = params.bank_id;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: API_URL + '/transactions/' + id + '/?bank_id=' + bank_id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_TRANSACTION_SUCCESS, response.data)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_TRANSACTION_ERROR));
    });
};

const getTransactions = (params) => dispatch => {
  dispatch(request(GET_TRANSACTIONS_REQUEST));
  var perPage = 10;
  var page = 1;
  console.log('GET_TRANSACTIONS');
  console.log(params);
  var url = API_URL + '/transactions?';
  var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  console.log(queryString);
  url += queryString;
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: url,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_TRANSACTIONS_SUCCESS, { items: response.data['items'], meta: response.data["meta"] })))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_TRANSACTIONS_ERROR));
    });
};

const addTransaction = (params) => dispatch => {
  dispatch(request(ADD_TRANSACTION_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('ADD_TRANSACTION');
  console.log(params);
  params.address_attributes = params.address
  params.contact_attributes = params.contact
  // Axios Data
  const axiosData = {
    method: 'POST',
    url: API_URL + '/transactions',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: params
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(ADD_TRANSACTION_SUCCESS, response)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(ADD_TRANSACTION_ERROR, err.response));
    });
};

const updateTransaction = (params, bank_id = '') => dispatch => {
  dispatch(request(UPDATE_TRANSACTION_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('UPDATE_TRANSACTION');
  console.log(params);
  params = { ...params, ...{ address_attributes: params.address } }
  params = { ...params, ...{ contact_attributes: params.contact } }

  const status = params.status === 'declined' ? 'decline' : params.status === 'confirmed' ? 'confirm' : ''
  const bank = bank_id ? '/?bank_id=' + bank_id : ''
  // Axios Data
  const axiosData = {
    method: 'PATCH',
    url: API_URL + '/transactions/' + params.id + '/' + status + bank,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: {id: params.id}
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(UPDATE_TRANSACTION_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(UPDATE_TRANSACTION_ERROR, err.response));
    });
};

const resetTransaction = () => dispatch => {
  dispatch(received(ADD_TRANSACTION_SUCCESS, []))
};

const deleteTransaction = (bank_id,id) => dispatch => {
  dispatch(request(DELETE_TRANSACTION_REQUEST));
  // Axios Data
  const axiosData = {
    method: 'DELETE',
    url: API_URL + '/transactions/' + id + `?bank_id=${bank_id}`,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
      .then(response => dispatch(received(DELETE_TRANSACTION_SUCCESS, response.data)))
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log('AXIOS ERROR:', err.response);
        dispatch(error(DELETE_TRANSACTION_ERROR, err.response));
      });
};

export { deleteTransaction, getTransaction, getTransactions, addTransaction, updateTransaction, resetTransaction };
