import axios from 'axios';
import { GET_ANALYTIC_CHART_DATA_SUCCESS, GET_ANALYTIC_CHART_DATA_ERROR, GET_ANALYTIC_CHART_DATA_REQUEST, GET_REDASH_JOB_REQUEST, GET_REDASH_JOB_SUCCESS, GET_REDASH_JOB_ERROR, GET_REDASH_QUERY_RESULTS_REQUEST, GET_REDASH_QUERY_RESULTS_SUCCESS, GET_REDASH_QUERY_RESULTS_ERROR } from './actionTypes';
import { API_URL, ANALYTIC_API_URL, X_API_KEY, X_API_VERSION } from '../conf.js';
import { getCookie, setCookie } from '../shared/utils/cookies';
import { createAccountToken } from '../shared/utils/account';
import { axiosInterceptors } from '../shared/utils/axiosInterceptors';

// Base Actions
import { request, received, error } from '../shared/redux/baseActions';

export function getAnalyticChartDataSuccess(payload) {
  console.log('PAYLOAD');
  console.log(payload);
  return { type: GET_ANALYTIC_CHART_DATA_SUCCESS, payload: payload }
}

export function getAnalyticChartDataError() {
  return { type: GET_ANALYTIC_CHART_DATA_ERROR }
}

const getAnalyticChartData = (params) => dispatch => {
  
  dispatch(request(GET_ANALYTIC_CHART_DATA_REQUEST));

  var queryId = params.queryId;
  delete params.queryId;
  // params.max_age = 0;
  // Axios Data
  const axiosData = {
    method: 'POST',
    url: ANALYTIC_API_URL + '/queries/'+queryId+'/results?api_key=HCmiVZgrRLELZEkVh8fsLyVL1s6Vsu9HbH6FPb22&max_age=0',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    //data: {"parameters": {"booked_at_start": "2020-06-01","booked_at_end":"2020-06-30", "bank_id":1 }}
    data: {parameters: params}
  };
  //const axiosInstance = axios.create();
  //axiosInterceptors(axiosInstance);
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => { 
      return dispatch(received(GET_ANALYTIC_CHART_DATA_SUCCESS, response.data))
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_ANALYTIC_CHART_DATA_ERROR));
    });
}

const getRedashJob = (id) => dispatch => {
  
  dispatch(request(GET_REDASH_JOB_REQUEST));

  // Axios Data
  const axiosData = {
    method: 'GET',
    url: ANALYTIC_API_URL + '/jobs/'+id+'?api_key=HCmiVZgrRLELZEkVh8fsLyVL1s6Vsu9HbH6FPb22&max_age=0',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  //const axiosInstance = axios.create();
  //axiosInterceptors(axiosInstance);
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => { 
      return dispatch(received(GET_REDASH_JOB_SUCCESS, response.data))
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_REDASH_JOB_ERROR));
    });
}


const getRedashQueryResults = (id) => dispatch => {
  
  dispatch(request(GET_REDASH_QUERY_RESULTS_REQUEST));

  // Axios Data
  const axiosData = {
    method: 'GET',
    url: ANALYTIC_API_URL + '/query_results/'+id+'?api_key=HCmiVZgrRLELZEkVh8fsLyVL1s6Vsu9HbH6FPb22&max_age=0'
  };
  //const axiosInstance = axios.create();
  //axiosInterceptors(axiosInstance);
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => { 
      return dispatch(received(GET_REDASH_QUERY_RESULTS_SUCCESS, response.data))
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_REDASH_QUERY_RESULTS_ERROR));
    });
}

export {getAnalyticChartData, getRedashJob, getRedashQueryResults}