// Dependencies
import axios from 'axios';
import { API_URL, X_API_KEY } from '../conf.js';
import { getCookie } from '../shared/utils/cookies';
import { axiosInterceptors } from '../shared/utils/axiosInterceptors';

// Action Types
import {
  ADD_PAYMENT_CREATE_REQUEST,
  ADD_PAYMENT_CREATE_SUCCESS,
  ADD_PAYMENT_CREATE_ERROR,
  GET_PAYMENT_REQUEST,
  GET_PAYMENT_SUCCESS,
  GET_PAYMENT_ERROR,
  GET_PAYMENTS_REQUEST,
  GET_PAYMENTS_SUCCESS,
  GET_PAYMENTS_ERROR,
  ADD_PAYMENT_REQUEST,
  ADD_PAYMENT_SUCCESS,
  ADD_PAYMENT_ERROR,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_ERROR
} from './actionTypes';

// Base Actions
import { request, received, error } from '../shared/redux/baseActions';

const getPayment = (params) => dispatch => {
  dispatch(request(GET_PAYMENT_REQUEST));
  var id = '';
  var bank_id = '';
  if (typeof (params) !== 'undefined') {
    id = params.id;
    bank_id = params.bank_id;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: API_URL + '/payments/' + id + '/?bank_id=' + bank_id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_PAYMENT_SUCCESS, response.data)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_PAYMENT_ERROR));
    });
};

const getPayments = (params) => dispatch => {
  dispatch(request(GET_PAYMENTS_REQUEST));
  var perPage = 10;
  var page = 1;
  console.log('GET_PAYMENTS');
  console.log(params);
  var url = API_URL + '/payments?';
  var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  console.log(queryString);
  url += queryString;
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: url,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_PAYMENTS_SUCCESS, { items: response.data['items'], meta: response.data["meta"] })))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_PAYMENTS_ERROR));
    });
};

const addPayment = (params) => dispatch => {
  dispatch(request(ADD_PAYMENT_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('ADD_PAYMENT');
  console.log(params);
  params.address_attributes = params.address
  params.contact_attributes = params.contact
  // Axios Data
  const axiosData = {
    method: 'POST',
    url: API_URL + '/payments',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: params
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(ADD_PAYMENT_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(ADD_PAYMENT_ERROR, err.response));
    });
};

const updatePayment = (params, bank_id) => dispatch => {

  const urlId = params.id;
  dispatch(request(UPDATE_PAYMENT_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('UPDATE_PAYMENT');
  console.log(params);
  params = { brand_id: params.brand_id, notes: params.notes, ...{ address_attributes: params.address } }
  params = { brand_id: params.brand_id,  notes: params.notes, ...{ contact_attributes: params.contact } }
  // Axios Data
  const axiosData = {
    method: 'PATCH',
    url: API_URL + '/payments/' + urlId + '/?bank_id=' + bank_id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: params
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(UPDATE_PAYMENT_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(UPDATE_PAYMENT_ERROR, err.response));
    });
};

const createPayouts = (params) => {
  // dispatch(request(ADD_PAYMENT_CREATE_REQUEST));
  // Axios Data
  console.log(params);
  const axiosData = {
    method: 'POST',
    url: API_URL + '/payouts',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: JSON.stringify(params)
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
      .then(response => (response))
      .catch(err => (err));
};

const getPayouts = (id) => {
  const axiosData = {
    method: 'GET',
    url: API_URL + `/payouts?bank_id=${id}`,
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
      .then(response => (response?.data))
      .catch(err => (err));
};

const getPayoutCurrent = (id = '', bank_id) => {
  const axiosData = {
    method: 'GET',
    url: API_URL + `/payouts/${id}?bank_id=${bank_id}`,
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
      .then(response => (response?.data))
      .catch(err => (err));
};

const getPayoutsCsv = (id, bank_id) => {
  const axiosData = {
    method: 'get',
    url: API_URL + `/payouts/${id}.csv?bank_id=${bank_id}`,
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
      .then(response => (response?.data))
      .catch(err => (err));
};

const getPayoutsXml = (id, bank_id) => {
  const axiosData = {
    method: 'get',
    url: API_URL + `/payouts/${id}.xml?bank_id=${bank_id}`,
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
      .then(response => (response?.data))
      .catch(err => (err));
};


const resetPayment = () => dispatch => {
  dispatch(received(ADD_PAYMENT_SUCCESS, []))
};

export { getPayoutCurrent, getPayoutsXml, getPayoutsCsv, createPayouts, getPayment, getPayments, addPayment, updatePayment, resetPayment,getPayouts };
