import React, {memo, useCallback, useEffect, useState} from 'react';
import {Card, CardBody} from 'reactstrap';

function Billing({ brand, newBrand = false, getDataChild, error = {} }) {
    const { billing } = brand || {};

    let {
        bic = '',
        city = '',
        country = '',
        iban = '',
        street = '',
        title = '',
        zip = ''
    } = billing || {}

    if(newBrand && Object.keys(brand).length > 0) {
        bic = brand['billing[bic]'] || ''
        city = brand['billing[city]'] || ''
        country = brand['billing[country]'] || ''
        iban = brand['billing[iban]'] || ''
        street = brand['billing[street]'] || ''
        title = brand['billing[title]'] || ''
        zip = brand['billing[zip]'] || ''
    }

    const [ list, setList ] = useState({
        bic,
        city,
        country,
        iban,
        street,
        title,
        zip
    });


    useEffect(() => {
        getDataChild(list);
    }, [ list ]);

    const handleChange = useCallback((e) => {
        e.persist()
        const { target } = e;
        const { name , value } = target;

        setList(item => ({...item, [name]: value }))
    }, []);

    return (
        <Card>
            <CardBody>
                <div className="card-title">Billing</div>
                <div className="mb-2 mr-2">
                    <label>Title </label>
                    <div>
                        <input onChange={handleChange} name="billing[title]" type="text" className="form-control" defaultValue={title}

                        />
                        { (Object.keys(error).length > 0 && error['billing.title']) &&
                            <span style={{
                                position: 'absolute',
                                marginTop: '-4px',
                                color: 'red'
                            }}> {  error['billing.title'][0] } </span>
                        }
                    </div>
                </div>
                <div className="mb-2 mr-2">
                    <label>Iban </label>
                    <div>
                        <input  onChange={handleChange} name="billing[iban]" type="text" className="form-control" defaultValue={iban}

                        />
                        {  (Object.keys(error).length > 0 && error['billing.iban']) &&
                            <span style={{
                                position: 'absolute',
                                marginTop: '-4px',
                                color: 'red'
                            }}> { error['billing.iban'][0]}
                            </span>
                        }
                    </div>
                </div>
                <div className="mb-2 mr-2"><label>Bic </label>
                    <div>
                        <input  onChange={handleChange} name="billing[bic]" type="text" className="form-control" defaultValue={bic}

                        />
                        {  (Object.keys(error).length > 0 && error['billing.bic']) &&
                            <span style={{
                                position: 'absolute',
                                marginTop: '-4px',
                                color: 'red'
                            }}> { error['billing.bic'][0]} </span>
                        }
                    </div>
                </div>
                <div className="mb-2 mr-2">
                    <label>Street </label>
                    <div>
                        <input  onChange={handleChange} name="billing[street]" type="text" className="form-control" defaultValue={street}

                        />
                        {  (Object.keys(error).length > 0 && error['billing.street']) &&
                            <span style={{
                                position: 'absolute',
                                marginTop: '-4px',
                                color: 'red'
                            }}> { error['billing.street'][0]} </span>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="mb-2 mr-2"><label>ZIP </label>
                            <div>
                                <input  onChange={handleChange} name="billing[zip]" type="text" className="form-control" defaultValue={zip}

                                />
                                {  (Object.keys(error).length > 0 && error['billing.zip']) &&
                                    <span style={{
                                        position: 'absolute',
                                        marginTop: '-4px',
                                        color: 'red'
                                    }}> { error['billing.zip'][0]} </span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="mb-2 mr-2">
                            <label>City </label>
                            <div>
                                <input onChange={handleChange} name="billing[city]" type="text" className="form-control" defaultValue={city}
                                />
                                {  Object.keys(error).length > 0 && error['billing.city'] &&
                                    <span style={{
                                        position: 'absolute',
                                        marginTop: '-4px',
                                        color: 'red'
                                    }}> { error['billing.city'][0]} </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-2 mr-2">
                    <label>Country </label>
                    <div>
                        <input onChange={handleChange} name="billing[country]" type="text" className="form-control" defaultValue={country}

                        />
                        {  (Object.keys(error).length > 0 && error['billing.country']) &&
                            <span style={{
                                position: 'absolute',
                                marginTop: '-4px',
                                color: 'red'
                            }}> { error['billing.country'][0]} </span>
                        }
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default memo(Billing);
