import React, {Component} from 'react';
import debounce from 'lodash/debounce';
import { PropTypes, array, bool } from 'prop-types';
import { Field, FormSection, reduxForm } from 'redux-form'
import Address from '../../shared/components/form-section/Address';
import Contact from '../../shared/components/form-section/Contact';
import Tabs from '../../shared/components/tabs/Tabs';

import { Form, Input, Button, Card, CardColumns, CardTitle, CardBody, CardHeader, Col, Row, Label } from 'reactstrap';
import Snackbar from '@material-ui/core/Snackbar';

import SimpleLineIcon from 'react-simple-line-icons';
import { renderField, renderTextareaField, renderSelectField, renderCheckbox, renderDateField, renderMultiselect } from '../../shared/components/form-field/ReduxFormFields';

import { reset } from 'redux-form';
import { SubmissionError } from 'redux-form'
import { stopSubmit } from 'redux-form'

import { getPayment, addPayment, updatePayment, resetPayment } from '../../actions/paymentActions';
import { getCategories } from '../../actions/categoryActions';
import { addBrand, getBrands, updateBrand } from '../../actions/brandActions';
import { checkAuth } from '../../actions/sessionActions';
import { validate } from './validate';

import './PaymentForm.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../shared/redux/configureStore';

import BrandCombobox from "../../shared/components/searchable-field/BrandComboboxSelect"

// Configuring Redux Store
const store = configureStore(window.initialState);

const messages = {
  emptyFilterList: 'No results. Try another query'
}

const renderDescription = (name, label) => {
  return (
      <Field
        name={name}
        type="text"
        component={renderTextareaField}
        label={label}
        className="form-control"
        asterisk="*"
        ref={name.replace('.', '_')}
      />
    )
}

const renderTitle = (name, label) => {
  return (
      <Field
        name={name}
        type="text"
        component={renderField}
        label={label}
        className="form-control"
        asterisk="*"
      />
  )
}

class PaymentForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      open: false,
      create: false,
      categories: null,
      brands: null,
      selectedBrand: '',
      categorySearchResult: null,
      googleResult: [],
      alias: null
    }
    this.submit = this.submit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCategorySearch = this.handleCategorySearch.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
    this.handleAddBrandClick = this.handleAddBrandClick.bind(this);
    this.handleFindOnGooglePlace = this.handleFindOnGooglePlace.bind(this);
    this.handleAliasChange = this.handleAliasChange.bind(this);
  }

  componentDidMount(props) {
    console.log('PaymentForm DidMount');
    console.log(props);
    const {getPayment, addPayment, updatePayment, getCategories, getBrands } = this.props;
    if(this.state.id) {
      getPayment({id: this.state.id, bank_id: new URLSearchParams(this.props.location.search).get("bank_id") || 1});
    }
    getCategories();
    getBrands({per_page: 100});

  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate')
    console.log(this.props);
    if(!this.props.match.params.id) {
      if(!this.state.create) {
        //this.props.dispatch(reset('paymentForm'));  // requires form name
        this.props.initialize();
        store.dispatch(resetPayment());
        this.setState({create: true, brands: null, alias: null});
        //this.setState({brands: null});
      }
    }
  }

  // Save Payment
  submit(values) {
    console.log('--submit')
    console.log(values)
    console.log(this.state)
    var action;

    if(this.state.brands!=null) {
      values.brand_ids = values.brands.map(item=>item.id)
    }
    else {
      values.brand_ids = [];
    }

    // values.brand_id = values.brand;
    values.brand_id = this.state.selectedBrand ? this.state.selectedBrand : values.brand

    var alias = values.description;
    if(alias!='') {
      var brandParams = {};
      brandParams.id = this.state.selectedBrand!='' ? this.state.selectedBrand : values?.brand?.id;

      if(!brandParams.id) return

      if (values?.initDescription !== values?.description) {
        brandParams['aliases[0]'] = {name: alias};
      }

      // brandParams['aliases[0]'] = {name: alias};
      console.log('---brandParams')
      console.log(brandParams)
      store.dispatch(updateBrand(brandParams)).then((result) => {
        if(typeof(result)!='undefined') {
          console.log(result);
           this.props.history.push({
            pathname: '/payments/payments',
            search: window.location.search
          });
        }
      }).catch((error) => {
        throw new SubmissionError({_error:  error });
      });
    }
    //console.log('---values');
    //console.log(values);
    //return;

    if(this.state.create) {
      action = addPayment(values);
       this.props.history.push({
      pathname: '/payments/payments',
      search: window.location.search
    });
    }
    else {
      action = updatePayment(values, this.props.match.params.bank_id);
       this.props.history.push({
      pathname: '/payments/payments',
      search: window.location.search
    });
    }
    store.dispatch(action).then((result) => {
      if(typeof(result)!='undefined') {
        this.setState({open: true});
         this.props.history.push({
      pathname: '/payments/payments',
      search: window.location.search
    });
      }
  	}).catch((error) => {
  		throw new SubmissionError({_error:  error });
  	});
  }

  handleClose() {
    this.setState({open: false});
  }

  handleBrandChange(e) {
    console.log('handleBrandChange')
    console.log(e)
    console.log(this.state)
    this.setState({selectedBrand: e=='' ? null : e});
  }

  handleCategorySearch = debounce((searchTerms) => {
    this.categorySearch(searchTerms);
  }, 500);

  categorySearch(searchTerms) {
    console.log('handleCategorySearch');
    var params = {}
    var search = {}
    search['search[name]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getCategories(params)).then((result) => {
      var categorySearchResult = result.payload.items.map(category => ({ id: category.id, name: category.name?.en || category.name }));
      this.setState({categorySearchResult: categorySearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleAliasChange(e) {
    this.setState({alias: e.target.value});
  }

  handleAddBrandClick() {
    console.log('---handleAddBrandClick');
    console.log(this.refs);

    var categories = this.refs.categories.value;
    var values = {};
    values.name = this.refs.name.value;
    //values.booking_name = this.refs.bookingName.value;
    if(typeof(this.refs.alias.value)!='undefined' && this.refs.alias.value!='') {
      values['aliases[0]'] = {name: this.refs.alias.value}
      //return;
    }
    var description = {};
    description.de = this.refs.descriptionDe.value;
    description.en = this.refs.descriptionEn.value;
    values.description = description;
    if(categories!=null && categories.length>0) {
      values.category_ids = categories.map(item=>item.id)
    }
    values.active = false;
    // Create Brand
    store.dispatch(addBrand(values)).then((result) => {
      if(typeof(result)!='undefined') {
        console.log('---result');
        console.log(result);
        var brand = result.payload;
        if(typeof(brand)!='undefined') {
          var brandId = brand.id;
          // Update Payment
          store.dispatch(updatePayment({id: this.state.id, brand_id: brandId}, this.props.match.params.bank_id)).then((result) => {
            if(typeof(result)!='undefined') {
              this.setState({open: true});
               this.props.history.push({
      pathname: '/payments/payments',
      search: window.location.search
    });
            }
          }).catch((error) => {
            throw new SubmissionError({_error:  error });
          });
        }
      }
    }).catch((error) => {
      throw new SubmissionError({_error:  error });
    });


  }

  handleFindOnGooglePlace() {
    console.log('---handleFindOnGooglePlace');
    var description = this.refs.description.value;
    console.log('description:');
    console.log(description);
    const request = {
      query: description,
      fields: ["name", "business_status", "formatted_address", "types"]
    };
    var service = new window.google.maps.places.PlacesService(document.createElement('div'));
    service.findPlaceFromQuery(request, (results, status) => {
      var html = '';
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        console.log(results);
        if(results.length>0) {
          for(var i=0; i<results.length; i++) {
            var result = results[i];
            html += 'Name: ' + result['name'] + '<br />';
          }
        }
        this.setState({googleResult: results});
      }
      else {
        this.setState({googleResult: [{status: window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS}] });
      }
    });

  }

  render() {

    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

    const { error, handleSubmit, pristine, reset, submitting, message, categories, brands, payment } = this.props

    console.log('---render PaymentForm')
    console.log(payment)

    var results = [];
    if(typeof(this.state.googleResult)=='undefined') {
      results = [];
    }
    else {
      results = this.state.googleResult;
    }
    // console.log('results');
    // console.log(results);

    let selectedBrand = '';
    if (payment?.brand) selectedBrand = payment.brand
    // if(typeof(payment)!='undefined' && payment.brand!=null) {
    //   selectedBrand = payment.brand;
    // }
    // if(this.state.selectedBrand==null) {
    //   selectedBrand = '';
    // }
    // else if(this.state.selectedBrand!='') {
    //   selectedBrand = this.state.selectedBrand;
    // }

    var categoriesArr = categories.map(category => ({ id: category.id, name: category.name?.en || category.name }));
    if(this.state.categorySearchResult!=null) {
      categoriesArr = this.state.categorySearchResult;
    }

    categoriesArr = categoriesArr.concat({id: 0, name: '...'});

    const brandsSingleSelectArr = brands
      .map(brand => ({ value: brand.id, text: brand.name }))
      .sort((a, b) => a.text.toLowerCase() < b.text.toLowerCase() ? - 1 : 1)

    var alias = '';
    if(typeof(payment)!='undefined') {
      alias = payment.description;
    }
    if(this.state.alias!=null) {
      alias = this.state.alias;
    }

    const paymentIsLoaded = this.state.id ?
      payment && brandsSingleSelectArr?.length && +this.state.id === +payment?.id :
      true

    return (
      <div className="animated fadeIn mt-5 form-sections">
        <Card>
          <CardHeader><i className="fa fa-align-justify"></i> Payment</CardHeader>
          <CardBody>
            <Row>
              <Col xs="12" lg="6">
                <form onSubmit={handleSubmit(this.submit)}>
                  <Card>
                    <CardBody>
                      <CardTitle><i className="fa fa-align-justify"></i> Assign brand</CardTitle>
                      <br />

                      <div className="mb-2">
                        <label>Brand <span className="text-danger">*</span></label>
                        {paymentIsLoaded &&
                        <BrandCombobox
                          onChange={this.handleBrandChange}
                          defaultValue={selectedBrand}
                        />
                        }
                      </div>

                      {/*<Field*/}
                      {/*  name="brand"*/}
                      {/*  type="select"*/}
                      {/*  component={renderSelectField}*/}
                      {/*  label="Brand"*/}
                      {/*  options={brandsSingleSelectArr}*/}
                      {/*  defaultValue={selectedBrand}*/}
                      {/*  className="form-control"*/}
                      {/*  onChange={(event) => this.handleBrandChange(event.target.value)}*/}
                      {/*  asterisk="*"*/}
                      {/*/>*/}

                      <Field
                        name="notes"
                        type="text"
                        component={renderTextareaField}
                        label="Notes"
                        className="form-control"
                      />
                      <Field
                        name="description"
                        type="text"
                        component={renderField}
                        label="Alias"
                        className="form-control"
                        onChange={this.handleAliasChange}
                      />
                      <Row>
                        <Col xs="12">
                          {!!error && <div className="form-error mt-3">{error && <strong>{error}</strong>}</div>}
                          <button type="submit" className="btn btn-primary mt-5">Submit</button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </form>
                <Card>
                    <CardBody>
                      <CardTitle><i className="fa fa-align-justify"></i> Find on Google places</CardTitle>
                      <br />
                      <Field
                        name="description"
                        type="text"
                        component={renderField}
                        label="Check for"
                        className="form-control"
                        ref="description"
                      />
                      <div>
                      {results.map((result) =>
                          <table>
                            {typeof(result.status)!='undefined' &&
                              <tr><td>Status:</td><td className="pl-3">{result.status}</td></tr>
                            }
                            {typeof(result.name)!='undefined' &&
                              <tr><td>Suggested brand:</td><td className="pl-3">{result.name}</td></tr>
                            }
                            {/*typeof(result.business_status)!='undefined' &&
                              <li>{result.business_status}</li>
                            */}
                            {typeof(result.formatted_address)!='undefined' &&
                              <tr><td>Address:</td><td className="pl-3">{result.formatted_address}</td></tr>
                            }
                            {typeof(result.types)!='undefined' &&
                              <tr>
                                <td>Categories:</td>
                                <td className="pl-3">
                                  {result.types.map((type, index)=>
                                    <span>{type}{index<result.types.length ? ', ' :''}</span>
                                  )}
                                </td>
                              </tr>
                            }
                          </table>
                      )}</div>
                      <button type="submit" className="btn btn-primary mt-5" onClick={this.handleFindOnGooglePlace}>Check</button>
                    </CardBody>
                  </Card>
              </Col>
              <Col xs="12" lg="6">
                <Card>
                  <CardBody>
                    <CardTitle>Add new brand</CardTitle>
                    <br />
                    <Label>If there is no brand in system for this payment, you can add a new brand here. This brand will be connected with the booking name in future</Label>
                    <Row>
                      <Col xs="12" lg="6">
                        <Field
                          name="name"
                          type="text"
                          component={renderField}
                          label="Name"
                          className="form-control"
                          asterisk="*"
                          ref="name"
                        />
                      </Col>
                      <Col xs="12" lg="6">
                      <label>Alias</label>
                      <input
                          name="alias"
                          type="text"
                          label="Alias"
                          className="form-control"
                          ref="alias"
                          value={alias}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3 mb-3 translations">
                      <Col xs="12" className="pr-4">
                        <Tabs tabs={[
                          {label: 'EN', items: [renderDescription('descriptionEn', 'Description')]},
                          {label: 'DE', items: [renderDescription('descriptionDe', 'Description')]}
                          ]} />
                      </Col>
                    </Row>
                    <br />
                    <Label>Assign categories</Label>
                    <Field
                      name="categories"
                      component={renderMultiselect}
                      data={categoriesArr}
                      value={[]}
                      valueField="id"
                      textField="name"
                      filter={false}
                      onSearch={this.handleCategorySearch}
                      messages={messages}
                      disabled={[{code:'', name: '...'}]}
                      ref="categories"
                    />
                    <Label className="mt-1">Type to search Category e.g. Electronics</Label>
                    <br />
                    <button type="button" className="btn btn-primary mt-5" onClick={this.handleAddBrandClick}>Add brand and assign</button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Snackbar open={this.state.open} autoHideDuration={2000} message="Saved" onClose={() => this.setState({open: false})}
          action={
            <React.Fragment>
              <a className="text-white cursor-pointer mr-2" aria-label="close" onClick={this.handleClose}><i className="cil-x"></i></a>
            </React.Fragment>
          }
        />
      </div>
    )
  }
}

PaymentForm.propTypes = {
  pristine: PropTypes.bool,
  message: PropTypes.string,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  payment: PropTypes.object,
  categories: PropTypes.array,
  brands: PropTypes.array,
  session: PropTypes.any
};

function mapStateToProps(state) {
  console.log('STATE');
  console.log(state);

  if (!state?.form?.paymentForm?.values?.initDescription && state?.form?.paymentForm?.values?.description) {
    state.form.paymentForm.values.initDescription = state.form.paymentForm.values.description
  }

  return {
    //formData: state.payments,
    initialValues: typeof(state.paymentForm)!=='undefined' ? state.paymentForm.payment : null,
    payment: typeof(state.paymentForm)!=='undefined' ? state.paymentForm.payment : null,
    categories: (typeof(state.categories)!=='undefined' && typeof(state.categories.result)!=='undefined') ? state.categories.result.items : [],
    brands: (typeof(state.brands)!=='undefined' && typeof(state.brands.result)!=='undefined') ? state.brands.result.items : [],
    session: state.session
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({getPayment, addPayment, updatePayment, resetPayment, getCategories, getBrands, checkAuth}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({
   form: 'paymentForm', // a unique identifier for this form,
   enableReinitialize: true,
   validate
})(PaymentForm))