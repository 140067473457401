import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { PropTypes, array, bool } from 'prop-types';
import { Form, Input, Button, Badge, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialCheckbox from '../../shared/components/material-checkbox/MaterialCheckbox';
import DataTableProgressComponent from '../../shared/components/widget/DataTableProgressComponent';
import DataTableNoDataComponent from '../../shared/components/widget/DataTableNoDataComponent';
import SimpleLineIcon from 'react-simple-line-icons';
import DropdownList from 'react-widgets/lib/DropdownList';
import 'react-widgets/dist/css/react-widgets.css';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import './Modal.css';
import './Countries.css';
import CountryShow from './CountryShow';

import memoize from 'memoize-one';
import DataTable, { createTheme } from 'react-data-table-component';

import { checkAuth } from '../../actions/sessionActions';
import { getCountry, getCountries, deleteCountry } from '../../actions/countryActions';

import { SubmissionError } from 'redux-form'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Redux Store
import configureStore from '../../shared/redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

const columns = memoize((handleEditClick, handleShowClick, handleDeleteClick) => [
  {
    name: 'Code',
    selector: 'code',
    sortable: true,
    grow: 0
  },
  {
    name: 'Name',
    selector: 'name.en',
    sortable: true,
    grow: 2
  },
  {
    name: 'Status',
    selector: 'active',
    sortable: false,
    cell: row => <span>{row.active==true ? 'Active' : 'Inactive'}</span>,
    center: true,
    grow: 2
  },
  {
    name: 'Actions',
    sortable: false,
    left: true,
    cell: (row) => <div>
                     <button className="btn btn-sm btn-light mr-2" onClick={handleEditClick} id={row.id} data-code={row.code}><i className="icon-pencil" onClick={handleEditClick} id={row.id} data-code={row.code}></i></button>
                     <button className="btn btn-sm btn-light mr-2" onClick={handleDeleteClick} id={row.id} data-code={row.code}><i className="icon-trash" id={row.id} data-code={row.code}></i></button>
                     <button className="btn btn-sm btn-light mr-2" onClick={handleShowClick} id={row.id} data-code={row.code}><i className="icon-chart" onClick={handleShowClick} id={row.id} data-code={row.code}></i></button>
                   </div>,
    ignoreRowClick: true,
    grow: 1
  }
]);


const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

class Countries extends Component {

  state = {
    data: [],
    loading: false,
    totalRows: 0,
    perPage: 10,
    sort: false,
    search: null,
    modalOpen: false,
    country: null,
    modalTitle: null,
    error: null
  };
  
  constructor(props) {
    super(props);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleShowClick = this.handleShowClick.bind(this);
    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
  }
  
  componentDidMount() {
    this.setState({ loading: true });
    console.log('componentDidMount');
    const { perPage } = this.state;
    console.log(perPage);
    var params = {}
    params.per_page = perPage;
    store.dispatch(getCountries(params)).then((result) => {
      this.setState({
        data: result.payload.items,
        totalRows: result.payload.items.length,
        loading: false,
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
       this.setState({
          error: error,
          modalTitle: 'Error!',
          modalOpen: true
        });
    }); 
    /* JWT is stored in memory */
    /*
    const {session} = this.props
    if(typeof(session.jwt)=='undefined') {
      sessionStorage.removeItem('jwt');
      this.setState({session: null});
      this.props.history.push({pathname: '/login'});
      window.location.href="/login";
    }
    */
  }

  handleQueryChange(event) {
    console.log('handleQueryChange');
    console.log(event.target.value)
    var search = {}
    search['search[name]'] = encodeURIComponent(event.target.value);
    this.setState({search: search});
  }
  
  handleFindCountries = async (e) => {
    e.preventDefault();
    console.log('handleFindCountries');
    console.log(this.state.search);
    if(this.state.search && this.state.search.toString().length>1) {
      this.setState({ loading: true });
      var params = {}
      params.per_page = this.state.perPage;
      if(this.state.sort) {
        Object.assign(params, this.state.sort)
      }
      if(this.state.search) {
        Object.assign(params, this.state.search)
      }
      store.dispatch(getCountries(params)).then((result) => {
        this.setState({
          loading: false,
          data: result.payload.items,
          totalRows: result.payload.items.length
      });
      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
         this.setState({
          error: error,
          modalOpen: true
        });
      }); 
    }
  }

  handlePageChange = async page => {
    console.log('handlePageChange');
    this.setState({ loading: true });
    const { perPage } = this.state;
    var params = {}
    params.per_page = perPage;
    params.page = page;
    if(this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getCountries(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
       this.setState({
          error: error,
          modalOpen: true
        });
    }); 

  }

  handlePerRowsChange = async (perPage, page) => {
    console.log('handlePerRowsChange');
    this.setState({ loading: true });
    var params = {}
    params.per_page = perPage;
    params.page = page;
    if(this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getCountries(params)).then((result) => {
      console.log('getCountries(params)');
      console.log(result.payload);
      this.setState({
        loading: false,
        data: result.payload.items,
        perPage: perPage,
    });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
       this.setState({
          error: error,
          modalOpen: true
        });
    }); 
  }

  handleSort = async (column, sortDirection) => {
    console.log('handleSort');
    this.setState({ loading: true });
    var params = {}
    params.per_page = this.state.perPage;
    var sort = {}
    sort['order['+column.selector+']'] = sortDirection;
    Object.assign(params, sort)
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getCountries(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        sort: sort
    });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
       this.setState({
          error: error,
          modalOpen: true
        });
    });
  };
  
  handleEditClick(event) {
    console.log('handleEditClick');
    this.props.history.push({pathname: '/countries/edit/'+event.target.dataset.code});
  }

  handleShowClick(event) {
    console.log('handleShowClick');
     store.dispatch(getCountry(event.target.dataset.code)).then((result) => {
      if(typeof(result)!='undefined') {
        
        console.log(result);
        var country = result.payload;
        this.setState({
          country: country,
          modalTitle: country.name?.en || country.name
        })
        this.setState({modalOpen: true});
      }
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
       this.setState({
          error: error,
          modalOpen: true
        });
    });
  }

  handleDeleteClick(event) {
    console.log('handleDeleteClick');
    if(window.confirm('Are you sure you want to delete this country?')) {
      store.dispatch(deleteCountry(event.target.dataset.code)).then((result) => {
        if(typeof(result)!='undefined') {
          var brand = result.payload;
          window.location.reload();
        }
      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
        this.setState({
          error: error,
          modalOpen: true
        });
      }); 
    }
  }

  onSearchInputChange = (evt,field) => {
    const search = Object.assign({}, this.state.search);
    if(typeof(field)!='undefined') {
      if(field=='active') {
        if(evt=='Active') {
          var evt = {
            target: {name: 'active', value: '', checked: true}
          }
        }
        if(evt=='Inactive') {
          var evt = {
            target: {name: 'active', value: '', checked: false}
          }
        }
        if(evt=='Status') {
          var evt = {
            target: {name: 'active', value: '', checked: null}
          }
        }
      }
    }
    if(evt.target.value!='') {
      search['search['+evt.target.name+']'] = encodeURIComponent(evt.target.value);
    }
    else if(evt.target.name=='active' && evt.target.checked!=null) {
      search['search['+evt.target.name+']'] = evt.target.checked;
    }
     else if(evt.target.name=='active' && evt.target.checked==null) {
      delete search['search['+evt.target.name+']'];
    }
    else {
      delete search['search['+evt.target.name+']'];
    }
    this.setState({search});
  }

  handleModalOpen() {
    this.setState({modalOpen: true});
  }

  handleModalClose() {
    this.setState({modalOpen: false})
  }

  render() {
    
    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

    const { loading, data, totalRows } = this.state;

    const handleChange = (state) => {
      // You can use setState or dispatch with something like Redux so we can use the retrieved data
      console.log('Selected Rows: ', state.selectedRows);
    };
      
    return (
      <div className="animated fadeIn mt-5">
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          id="modal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalOpen}>
            <div className="material-modal-content">
              <h4 id="transition-modal-title" className="mb-4">{this.state.modalTitle} <i className="cil-x cursor-pointer float-right" onClick={this.handleModalClose}></i></h4>
              <div id="transition-modal-description">
               {this.state.error!=null
                  ? <div>{this.state.error.toString()}</div>
                  : <CountryShow country={this.state.country} />
                }  
              </div>
            </div>
          </Fade>
        </Modal>
        <Row>
          <Col xs="12" lg="12">
            <div className="d-flex mb-4 justify-content-end">
              <Link to="/countries/new">
              <Button color="secondary" className="bg-white text-dark" outline>
                <i className="cil-plus text-dark"></i>
              </Button>
            </Link>
            </div>  
          </Col>
          <Col xs="12" lg="12">
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center justify-content-between">
                  <div><i className="fa fa-align-justify"></i> Countries list</div>
                  <div>
                    <Form className="form-inline float-right" onSubmit={this.handleFindCountries}>
                      <Input type="search" name="code" className="mr-2" placeholder="Code" onChange={this.onSearchInputChange} />
                      <Input type="search" name="name" className="mr-2" placeholder="Name" onChange={this.onSearchInputChange} />
                      <DropdownList
                        name="active"
                        onChange={(e)=>this.onSearchInputChange(e, 'active')}
                        data={['Status', 'Active', 'Inactive']}
                        placeholder="Status"
                        className="status-dropdown"
                      />
                      <a onClick={(e)=>this.handleFindCountries(e)} className="search-btn ml-1 cursor-pointer btn btn-outline-secondary"><i className="icon-magnifier"></i></a>  
                    </Form>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                  <DataTable
                    noHeader={false}
                    columns={columns(this.handleEditClick, this.handleShowClick, this.handleDeleteClick)}
                    onSelectedRowsChange={this.updateState}
                    data={data}
                    theme="solarized"
                    progressPending={loading}
                    /*pagination
                    paginationServer
                    paginationTotalRows={totalRows}*/
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    onSort={this.handleSort}
                    sortServer
                    selectableRows
                    selectableRowsComponent={MaterialCheckbox}
                    selectableRowsComponentProps={selectProps}
                    onSelectedRowsChange={handleChange}
                    progressComponent={<DataTableProgressComponent />}
                    noDataComponent={<DataTableNoDataComponent />}
                  />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

Countries.propTypes = {
  countries: PropTypes.array,
  data: PropTypes.array,
  session: PropTypes.any
}

function mapStateToProps(state) {
  console.log('STATE')
  console.log(state);
    return {
      countries: state.countries,
      data: state.countries.countries.items,
      session: state.session
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({getCountry, getCountries, checkAuth}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Countries)