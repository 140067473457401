import React, {useCallback, useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import {createEditAdmin} from '../../actions/brandUpdateActions';
import Checkbox from '@material-ui/core/Checkbox';
import {resetUsersActions, usersActions} from '../../actions/usersActions';
import {useDispatch, useSelector} from 'react-redux';
import {usersSelector} from '../../selectors/users';
import Fade from '@material-ui/core/Fade';


function EditBrand({ match }) {
    const dispatch = useDispatch();
    const [ changedBrand, setChangedBrand ] = useState({});
    const [loading, setLoading] = useState(true);

    const [brand , setBrand ] = useState({});

    const { params : { id }} = match;
    const { items = [] } = useSelector(usersSelector);

    const fetchUsersList = useCallback(async () => {
        await dispatch(usersActions());
    }, []);


    const submitForm = useCallback(async () => {
        console.log(changedBrand);
        setLoading(true);
        await createEditAdmin(changedBrand);
        await fetchUsersList();
        setTimeout(() => setLoading(false), 500);
    }, [ changedBrand ]);

    const handleInputChange = useCallback(({ target }) => {
        const { name, value, checked } = target;
        console.log(value , name, checked);
        setChangedBrand(item => ({...item,
            [name]: name === 'active' ? checked : value,
        }))
    }, [ changedBrand ]);

    useEffect(() => {
        if(id) {
            fetchUsersList();
        }
    }, []);

    useEffect(() => {
        if(items.length > 0 && id) {
            const brandItem = items.filter(item => +item['id'] === +id);

            setBrand(brandItem[0] || {});
            setChangedBrand(brandItem[0] || {});
        }

        setTimeout(() => setLoading(false), 500);
    }, [ items ]);

    return (
        <div>
            <Card>
                <CardBody>
                    <CardHeader>
                        <div className="d-flex align-items-center justify-content-between">
                            <div><i className="fa fa-align-justify"></i>Change Users Info</div>
                        </div>
                    </CardHeader>
                    {!loading && Object.keys(brand).length > 0 &&
                        <CardBody className={'relative'}>
                            <Row>
                                <Col xs={6}>
                                    <div className="mb-2 mr-2">
                                        <label>Email</label>
                                        <div>
                                            <input required onChange={handleInputChange} name="email" type="email" defaultValue={brand?.email} className="form-control" />
                                        </div>
                                    </div>

                                    <div className="mb-2 mr-2">
                                        <label>Password</label>
                                        <div>
                                            <input required onChange={handleInputChange} name="password" type="password" className="form-control" />
                                        </div>
                                    </div>

                                    {/*<div className="mb-2 mr-2">*/}
                                    {/*    <label>Brand id</label>*/}
                                    {/*    <div>*/}
                                    {/*        <input required onChange={handleInputChange} name="brand_id" type="number" defaultValue={brand['brand_id']} className="form-control" />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}


                                </Col>

                                <Col xs={6}>
                                    <div className="mb-2 mr-2">
                                        <label>Locale</label>
                                        <div>
                                            <select onChange={handleInputChange} className="form-control" name="locale" id="">
                                                <option value="en">En</option>
                                                <option value="de">De</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="mb-2 mr-2">
                                        <label>
                                            Active:
                                            <Checkbox
                                                onChange={handleInputChange}
                                                name={'active'}
                                                defaultChecked={brand?.active || false}
                                            />
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <button onClick={submitForm} type="submit" className="btn btn-primary mt-3">Submit</button>
                                </Col>
                            </Row>
                        </CardBody>
                    }

                    <Fade in={loading} mountOnEnter unmountOnExit>
                        <span style={{
                                    position: 'fixed',
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                    marginLeft: 150,
                                    color: 'black',
                                    background: 'white',
                                    zIndex: 100,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: 40,
                                    textAlign: 'center'
                                }}>Loading ...</span>
                    </Fade>
                </CardBody>
            </Card>
        </div>
    );
}

export default EditBrand;