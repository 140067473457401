import React, { Component } from 'react';

import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Line, Doughnut, Bar, Chart } from 'react-chartjs-2';
import 'chartjs-plugin-labels';
//import 'chartjs-plugin-datalabels';
import ZoomedLine from '../../shared/components/chart/ZoomedLine';
import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import { Widget } from './Widget';
import FilterForm from './FilterForm';
import { optionsLight, optionsDark, lineDatasetProps, doughnutOptions, barOptionsLight, barOptionsDark } from '../../conf.js';
// Actions
import { checkAuth } from '../../actions/sessionActions';
import { getAnalyticChartData, getRedashJob, getRedashQueryResults } from '../../actions/analyticActions';

import { arrayComparer } from '../../shared/utils/helpers.js';

import { UserContext } from '../../shared/context/user';
import { ThemeContext } from '../../shared/context/theme';

// Redux Store
import configureStore from '../../shared/redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

var colors = ["#457b9d","#a8dadc","#f1faee","#f91f43","#e76f51","#f4a261","#e9c46a","#f4e3b8", "#2A9D8F","#0f358e","#1d3557"];

var fillScatteredTimeScaleDataPlugin = {
  beforeUpdate: function(c) {
    var timeAxis = c.options.scales.xAxes[0].time;
    if (!timeAxis || !timeAxis.fillGapsWithZero) return;
    for (var i=0;i<c.data.datasets.length;i++){
      var set = c.data.datasets[i];
      if (!set.data.length) continue;
      var min, max, hash = {};
      for (var j=0;j<set.data.length;j++){
        var val = moment(set.data[j].x, timeAxis.parser);
        if (!min || min.diff(val)>0)
          min = val;
        if (!max || max.diff(val)<0)
          max = val;
        hash[set.data[j].x] = 1;
      }
      for (var val = min; max.diff(val)>0; val.add(1, timeAxis.minUnit)){
        var d = val.format(timeAxis.parser);
        if (!hash[d])
          set.data.push({x:d, y:0});
      }
      set.data.sort(function(a,b){
        return a.x < b.x?-1:1;
      });
    }
  }
}

const items = [

  {
    identifier: 'noOfRegUsers',
    label: 'No of Registered Users',
    type: 'line',
    section: 'customer',
    col: 'col-12 col-sm-6',
    category: 'chart',
    height: 35
  }, 
  {
    identifier: 'noOfActiveUsers',
    label: 'Active Users',
    type: 'line',
    section: 'customer',
    col: 'col-12 col-sm-6',
    category: 'chart',
    height: 35
  },
  {
    identifier: 'noPerActivityGroup',
    label: 'User Activity Groups',
    type: 'line',
    section: 'customer',
    col: 'col-12 col-sm-12',
    category: 'chart',
    height: 20
  },
  {
    identifier: 'percOfAlltimeAct',
    label: 'All Time Activity',
    type: 'donut',
    section: 'customer',
    col: 'col-12 col-sm-4',
    category: 'chart',
    height: 55
  },
    {
    identifier: 'percPerActiveUsersByGender',
    label: 'Active Users By Gender',
    type: 'donut',
    section: 'customer',
    col: 'col-12 col-sm-4',
    category: 'chart',
    height: 55
  },
  {
    identifier: 'percPerActiveUsersByAge',
    label: 'Active Users By Age',
    type: 'donut',
    section: 'customer',
    col: 'col-12 col-sm-4',
    category: 'chart',
    height: 55
  },
  {
    identifier: 'top100CustomersByPaymentTurnover',
    label: 'Top 100 Customers By Payment Turnover',
    type: 'table',
    section: 'customer',
    col: 'col-12 col-sm-6',
    category: 'chart',
    height: 35
  },
  {
    identifier: 'percCancelRatios',
    label: 'Cancellation Ratios',
    type: 'line',
    section: 'customer',
    col: 'col-12 col-sm-6',
    category: 'chart',
    height: 35
  },  
  {
    identifier: 'top10CategoryByPaymentTurnover',
    label: 'Top 10 Category By Payment Turnover',
    type: 'table',
    section: 'customer',
    col: 'col-12 col-sm-6',
    category: 'chart',
    height: 35
  },
  {
    identifier: 'top10BrandsByPaymentTurnover',
    label: 'Top 10 Brands By Payment Turnover',
    type: 'table',
    section: 'customer',
    col: 'col-12 col-sm-6',
    category: 'chart',
    height: 35
  },
  {
    identifier: 'paymentMediumPopByTurnover',
    label: 'Payment Medium Pop By Turnover',
    type: 'donut',
    section: 'customer',
    col: 'col-12 col-sm-6',
    category: 'chart',
    height: 35
  },
  {
    identifier: 'noOfTransactions',
    label: 'No of Transactions',
    type: 'line',
    section: 'performance',
    col: 'col-12 col-sm-6',
    category: 'chart',
    height: 35
  },
  {
    identifier: 'turnoverOfTransactions',
    label: 'Turnover Of Transactions',
    type: 'line',
    section: 'performance',
    col: 'col-12 col-sm-6',
    category: 'chart',
    height: 35
  },
  {
    identifier: 'cashbackTransactionsByStatus',
    label: 'Cashback Transactions By Status',
    type: 'line',
    section: 'performance',
    col: 'col-12 col-sm-6',
    category: 'chart',
    height: 35
  },
  {
    identifier: 'cashbackTurnoverByStatus',
    label: 'Cashback Turnover By Status',
    type: 'line',
    section: 'performance',
    col: 'col-12 col-sm-6',
    category: 'chart',
    height: 35
  },
  {
    identifier: 'campaignsInfo',
    label: 'Campaigns Info',
    type: 'bar',
    section: 'performance',
    col: 'col-12 col-sm-6',
    category: 'chart',
    height: 35
  },
  {
    identifier: 'clicksInfo',
    label: 'Clicks Info',
    type: 'line',
    section: 'performance',
    col: 'col-12 col-sm-6',
    category: 'chart',
    height: 35
  },
  {
    identifier: 'customerCashbackByStatus',
    label: 'Customer Cashback By Status',
    type: 'line',
    section: 'performance',
    col: 'col-12 col-sm-6',
    category: 'chart',
    height: 35
  },
  {
    identifier: 'avgTransactionsPerCust',
    label: 'Average Transactions Per Customer',
    type: 'widget',
    section: 'performance',
    col: 'col-12 col-sm-4',
    category: 'widget',
    height: 35
  },
  {
    identifier: 'avgCashbackPerCust',
    label: 'Average Cashback Per Customer',
    type: 'widget',
    section: 'performance',
    col: 'col-12 col-sm-4',
    category: 'widget',
    height: 35
  },
  {
    identifier: 'avgTurnoverPerCust',
    label: 'Average Turnover Per Customer',
    type: 'widget',
    section: 'performance',
    col: 'col-12 col-sm-4',
    category: 'widget',
    height: 35
  }
];

class Analytic extends Component {
  
  constructor(props) {
    super(props);
    var options = optionsLight;
    var barOptions = barOptionsLight;
    if(localStorage.getItem('theme')=='dark') {
      options = optionsDark;
      barOptions = barOptionsDark;
    }
    this.state = {
      theme: props.theme,
      lineOptions: options,
      barOptions: barOptions,
      lineData: [],
      loadedItems: [],
      section: 'customer'
    };
    this.setFilterParams = this.setFilterParams.bind(this);
    this.drawChart = this.drawItems.bind(this);
    this.sectionCharts = this.sectionCharts.bind(this);
    this.sectionWidgets = this.sectionWidgets.bind(this);
    items.filter((item)=>typeof(item.section)!='undefined').map((item)=>{
      this.state[item.identifier+"Data"] = [];
      this.state[item.identifier+"Redraw"] = false;
      if(item.type!='line') {
        this[item.identifier+"Reference"] = React.createRef();
      } 
    });
  }

  componentDidMount() {
    //this.setState({theme: this.context.theme.theme});
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('---componentDidUpdate');
    var section = this.props.location.pathname.split('/').pop();
    const {startDate, endDate, bankId} = this.props;
    if(prevState.section!=section) {
      if(typeof(bankId)!='undefined' && typeof(startDate)!='undefined' && typeof(endDate)!='undefined') {
        this.props.history.push({
          pathname: '/analytic/'+section,
          search: "?" + new URLSearchParams({booked_at_start: startDate, booked_at_end: endDate, bank_id: bankId}).toString()
        });
      }
      //this.setFilterParams(Object.fromEntries(new URLSearchParams(window.location.search)));
      this.setState({section: section});
      if(startDate!=null && endDate!=null && bankId!=null) {
        //alert('setFilterParams');
        this.setFilterParams({booked_at_start: startDate, booked_at_end: endDate, bank_id: bankId});
      }
    }

    if(this.state.theme != this.context.state.theme) {
      let theme = this.context.state.theme;
      let lineOptions;
      let barOptions;
      if(this.context.state.theme=='dark') {
        lineOptions = optionsDark;
        barOptions = barOptionsDark;  
      }
      else {
        lineOptions = optionsLight; 
        barOptions = barOptionsLight;
      }
      let stateUpdates = {theme: this.context.state.theme};
      stateUpdates = Object.assign({lineOptions: lineOptions, barOptions: barOptions}, stateUpdates);
      this.setState(stateUpdates);
    }  

    items.filter((chart)=>['donut', 'bar', 'line_'].indexOf(chart.type)!=-1 && chart.section == this.state.section).map((chart) => {
      
      try {

        if(typeof(this[chart.identifier+"Reference"])!='undefined') {
          let chartInstance = this[chart.identifier+"Reference"].current.chartInstance;
          if(typeof(chartInstance.chart.config.data.datasets[0])!='undefined') {
            chartInstance.chart.config.data.datasets[0].borderWidth = chart.type=='donut' ? 4 : 0;
            chartInstance.chart.config.data.datasets[0].borderColor = this.context.state.theme=='dark' ? '#3B3D47' : '#ffffff';
            if(typeof(chartInstance.chart.config.options.scales.xAxes[0].time)!='undefined') {
              //chartInstance.chart.config.options.scales.xAxes[0].time.unit = 'month';
              //chartInstance.chart.config.options.scales.xAxes[0].time.minUnit = 'month';
            }
            // fillGapsWithZero
            if(chart.type=='line') {
              var c = chartInstance.chart;
              var timeAxis = c.options.scales.xAxes[0].time;
              if(typeof(timeAxis)!='undefined') {
                if (!timeAxis || !timeAxis.fillGapsWithZero) return;
                for (var i=0;i<c.data.datasets.length;i++){
                  var set = c.data.datasets[i];
                  if (!set.data.length) continue;
                  var min, max, hash = {};
                  for (var j=0;j<set.data.length;j++){
                    var val = moment(set.data[j].t, timeAxis.parser);
                    if (!min || min.diff(val)>0)
                      min = val;
                    if (!max || max.diff(val)<0)
                      max = val;
                    hash[set.data[j].t] = 1;
                  }
                  for (var val = min; max.diff(val)>0; val.add(1, timeAxis.minUnit)){
                    var d = val.format(timeAxis.parser);
                    if (!hash[d])
                      set.data.push({t:d, y:0});
                  }
                  set.data.sort(function(a,b){
                    return a.t < b.t?-1:1;
                  });
                }
              }
            }
            chartInstance.chart.canvas.parentNode.style.height = '228px';
            chartInstance.update();
            //window[redrawChart] = true;
          }
        }
      } catch(e) {
        console.log(e);
      }


    });
  
  }

  setFilterParams(params) {
    this.getAnalyticData(params);    
  }

  getAnalyticData(params) {
    console.log('getAnalyticData');
    params.queryId = 6;
    params.time_dimension = 'day';
    store.dispatch(getAnalyticChartData(params)).then((result)=>{
      if(typeof(result.payload.query_result)!='undefined') {
        this.drawItems(result.payload.query_result);
      }
      else {
        if(typeof(result.payload.job)!='undefined') {
          this.getRedashJob(result.payload.job.id);      
        }
      }
    }).catch((error)=>{
      alert(error);
    });
  }

  getRedashJob(id) {
    console.log('getRedashJob');
    store.dispatch(getRedashJob(id)).then((result)=>{
      //console.log(result);
      if(result.payload.job.query_result_id!=null) {
        this.getRedashQueryRes(result.payload.job.query_result_id);
      }
      else {
        this.getRedashJob(id);
      }
    }).catch((error)=>{
      alert(error);
    });
  }

  getRedashQueryRes(id) {
     console.log('getRedashQueryRes')
    store.dispatch(getRedashQueryResults(id)).then((result)=>{
      //console.log(result);
      if(typeof(result.payload.query_result)!='undefined') {
        this.drawItems(result.payload.query_result);
      }
    }).catch((error)=>{
     
      alert(error);
    });
  }

  drawItems(queryResult) {

    var data = [];
    if(typeof(queryResult.data.rows)!='undefined') {
      data = queryResult.data.rows;
    } 
    var availableItems = [];
    if(typeof(data[0].json_text)!='undefined' && data[0].json_text!=null) {
      availableItems = Object.keys(data[0].json_text);
    }
    items.filter((item)=>item.section == this.state.section && availableItems.indexOf(item.identifier)!=-1).map((item)=>{
      var curData = {};
      curData.datasets = [];
      var redrawChart = item.identifier+"Redraw";
      window[redrawChart] = false;
      if(data[0].json_text!=null) {
        var rows = [];
        if(typeof(data[0].json_text[item.identifier])!='undefined') {
          rows = data[0].json_text[item.identifier].rows;
        }
        if(typeof(this.state[item.identifier+"Data"].datasets)!='undefined') {
          window[redrawChart] = this.state[item.identifier+"Data"].datasets.length<rows.length;
        }
        for(var i=0; i<rows.length; i++) {
          var dataset = {}
          dataset.label = rows[i].tag;
          dataset.borderColor = colors[i];
          dataset.pointBorderColor = colors[i];
          if(item.type=='donut') {
            curData.labels = typeof(curData.labels)=='undefined' ? [rows[i].tag] : curData.labels.concat(rows[i].tag);
            curData.datasets = curData.datasets.length==0 ? [{backgroundColor: [colors[i]], borderColor: [this.context.state.theme=='dark' ? '#3B3D47' : '#ffffff'], borderWidth: 2, data: [rows[i].data[0].y]}] : [{backgroundColor: curData.datasets[0].backgroundColor.concat(colors[i]), borderColor: curData.datasets[0].borderColor.concat(this.context.state.theme=='dark' ? '#3B3D47' : '#ffffff'), borderWidth: 2, data: curData.datasets[0].data.concat(rows[i].data[0].y)}];
          }
          else if(item.type=='bar') {
            curData.type = 'bar';
            if(item.identifier=='campaignsInfo') {
              curData.labels = typeof(curData.labels)=='undefined' ? rows[i].data.map((el)=>el.t) : rows[i].data.map((el)=>el.t);
              curData.datasets = curData.datasets.length==0 ? [{label: rows[i].tag, barPercentage: 0.5, borderColor: [this.context.state.theme=='dark' ? '#3B3D47' : '#ffffff'], borderWidth: 0, backgroundColor: colors[i], data: rows[i].data.map((el)=>el.y) }] : curData.datasets.concat({label: rows[i].tag, barPercentage: 0.5, borderColor: curData.datasets[0].borderColor.concat(this.context.state.theme=='dark' ? '#3B3D47' : '#ffffff'), borderWidth: 0, backgroundColor: colors[i], data: rows[i].data.map((el)=>el.y)});
            }
            else {
              curData.labels = typeof(curData.labels)=='undefined' ? rows[i].data.map((el)=>el.t) : curData.labels.concat(rows[i].data.map((el)=>el.t));
              curData.datasets = curData.datasets.length==0 ? [{label: rows[i].tag, barPercentage: 0.5, backgroundColor_: [colors[i]], borderColor: [this.context.state.theme=='dark' ? '#3B3D47' : '#ffffff'], borderWidth: 2, data: rows[i].data.map((el)=>el.y) }] : [{label: rows[i].tag, barPercentage: 0.5, backgroundColor_: curData.datasets[0].backgroundColor_.concat(colors[i]), borderColor: curData.datasets[0].borderColor.concat(this.context.state.theme=='dark' ? '#3B3D47' : '#ffffff'), borderWidth: 2, data: curData.datasets[0].data.concat(rows[i].data.map((el)=>el.y))}];
            }
          }
          else if(item.type=='table') {
            curData.type = 'table';   
            var columns = {};       
            switch(item.identifier) {
              case 'top100CustomersByPaymentTurnover':
              {
                columns = [{
                  dataField: 'rank',
                  text: 'Rank',
                  sort: true,
                  headerAlign: 'center',
                  align: 'center',
                  headerStyle: () => {
                    return { width: "15%" };
                  }
                }, {
                  dataField: 'id',
                  text: 'Customer ID',
                  sort: true
                }, {
                  dataField: 'turnover',
                  text: 'Payment Turnover',
                  sort: true,
                  headerAlign: 'right',
                  align: 'right',
                  headerStyle: () => {
                    return { width: "30%" };
                  }
                }];
                curData.datasets = curData.datasets.length==0 ? [{key:'id', columns: columns, data: rows[i].data.map((el)=>{return {id: el.y, rank:el.t, turnover: el.x}})}] : [{ data: curData.datasets[0].data.concat(rows[i].data.map((el)=>{return {id: el.y, rank: el.y, turnover: el.x}}))}];
              }
              break;
              case 'top10CategoryByPaymentTurnover':
              {
                 columns = [{
                  dataField: 'rank',
                  text: 'Rank',
                  sort: true,
                  headerAlign: 'center',
                  align: 'center',
                  headerStyle: () => {
                    return { width: "15%" };
                  }
                }, {
                  dataField: 'category',
                  text: 'Category',
                  sort: true
                }, {
                  dataField: 'turnover',
                  text: 'Turnover',
                  sort: true,
                  headerAlign: 'right',
                  align: 'right',
                  headerStyle: () => {
                    return { width: "30%" };
                  }
                }];
                curData.datasets = curData.datasets.length==0 ? [{key: 'category', columns: columns, data: rows[i].data.map((el)=>{return {rank: el.y, category:el.t, turnover:el.x}})}] : [{ data: curData.datasets[0].data.concat(rows[i].data.map((el)=>{return {rank: el.y, category: el.t, turnover: el.x}}))}];
              }
              break
              case 'top10BrandsByPaymentTurnover':
              {
                 columns = [{
                  dataField: 'rank',
                  text: 'Rank',
                  sort: true, 
                  headerAlign: 'center',
                  align: 'center',
                  headerStyle: () => {
                    return { width: "15%" };
                  }
                }, {
                  dataField: 'brand',
                  text: 'Brand',
                  sort: true
                }, {
                  dataField: 'turnover',
                  text: 'Turnover',
                  sort: true,
                  headerAlign: 'right',
                  align: 'right',
                  headerStyle: () => {
                    return { width: "30%" };
                  }
                }];
                curData.datasets = curData.datasets.length==0 ? [{key: 'brand', columns: columns, data: rows[i].data.map((el)=>{return {rank:el.y, brand: el.t, turnover: el.x}})}] : [{ data: curData.datasets[0].data.concat(rows[i].data.map((el)=>{return {brand: el.t, rank: el.y, turnover: el.x}}))}];
              }
              break
            }
          }
          else if(item.type=='widget') {
            curData.datasets = curData.datasets.length==0 ? [{data: rows[i].data.map((el)=>{return {value:el.y}})}] : [{ data: curData.datasets[0].data.concat(rows[i].data.map((el)=>{return {value: el.y}}))}];
          }
          else {
            dataset.data = rows[i].data;
            curData.datasets.push({...lineDatasetProps, ...dataset});
          }
          
        }
      }
      var loadedItems = this.state.loadedItems;
      var onlyInA = loadedItems.filter(arrayComparer(availableItems));
      for(var i=0; i<onlyInA.length; i++) {
        if(items.filter((ch)=>ch.identifier==onlyInA[i]&&ch.section==this.state.section && ch.category=='chart').length>0) { // Charts
          try {
            if(typeof(this[onlyInA[i]+"Reference"].current.itemInstance)!='undefined') {
              this[onlyInA[i]+"Reference"].current.itemInstance.reset();
            }
          }
          catch(e) {
            console.log(e);
          }
        }
      }
      var onlyInB = availableItems.filter(arrayComparer(loadedItems));
      var itemsDiff = onlyInA.concat(onlyInB);
      console.log('---curData');
      console.log(curData);

      this.setState({[item.identifier+"Data"]: curData, noOfTransactionsRedraw: window[redrawChart], loadedItems: availableItems}, ()=>{});
  
    });

  }

  sectionCharts(charts, section) { 
    return (
    <div className="row">
    {charts.filter((chart)=>chart.section==section).map((chart)=>(
        <div className={chart.col}>
          <div className="panel-filled" id={chart.identifier}>           
            <h5 className="mb-3">{chart.label}</h5>
            {chart.type == 'donut' &&
              <Doughnut
                data={this.state[chart.identifier+"Data"]}
                width={100}
                height={chart.height}
                options={doughnutOptions}
                ref={this[chart.identifier+"Reference"]}
                redraw={this.state[chart.identifier+"Redraw"]}
              />
            }
            {chart.type == 'line' &&
              <ZoomedLine
                data={this.state[chart.identifier+"Data"]}
                width={100}
                height={chart.height}
                options={this.state.lineOptions}
                redraw={this.state[chart.identifier+"Redraw"]}
                id={chart.identifier}
              />
            }
            {chart.type == 'bar' &&
              <Bar
                data={this.state[chart.identifier+"Data"]}
                width={100}
                height={chart.height}
                options={this.state.barOptions}
                ref={this[chart.identifier+"Reference"]}
                redraw={this.state[chart.identifier+"Redraw"]}
              />
            }
            {(chart.type == 'table') && (typeof(this.state[chart.identifier+"Data"].datasets)!='undefined' && typeof(this.state[chart.identifier+"Data"].datasets[0])!='undefined') &&
             <BootstrapTable 
                data={this.state[chart.identifier+"Data"].datasets[0].data}
                bootstrap4
                keyField={this.state[chart.identifier+"Data"].datasets[0].key}
                columns={this.state[chart.identifier+"Data"].datasets[0].columns}
                ref={this[chart.identifier+"Reference"]}
                redraw={this.state[chart.identifier+"Redraw"]} 
                condensed
                />
            }
          </div>
        </div>
    ))}
    </div>
    )
  }


  sectionWidgets(widgets, section) {

    return (
      <div className="row">
      {widgets.filter((widget)=>widget.section==section).map((widget)=>(
        <div className={'text-center ' + widget.col}><Widget title={widget.label} content={typeof(this.state[widget.identifier+"Data"].datasets)!='undefined' ? JSON.stringify(this.state[widget.identifier+"Data"].datasets[0].data[0].value) : ''} /></div>
      ))}
      </div>
    )

  }

  render() {
    
    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

    return (
      <UserContext.Consumer>
      { user => (
        <div className="analytic mb-5" key="analytic-container">
          <div className="row">
            <div className="col-12"><FilterForm {...this.props} setFilterParams={this.setFilterParams} /></div>
          </div>
          {this.state.section==null && 
            <div>
              Analytic Home
            </div>
          }
          {this.sectionCharts(items.filter((item)=>item.category=='chart'), this.state.section)}
          {this.sectionWidgets(items.filter((item)=>item.category=='widget'), this.state.section)}        
        </div>
      )}
      </UserContext.Consumer>
    );
  }
}

function mapStateToProps(state) {
    return {
      isMobile: state.device.isMobile,
      session: state.session,
      startDate: state.globalParams.startDate,
      endDate: state.globalParams.endDate, 
      bankId: state.globalParams.bankId       
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({checkAuth}, dispatch);
Analytic.contextType = ThemeContext;
export default connect(
        mapStateToProps,
        mapDispatchToProps
        )(Analytic);
