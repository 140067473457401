import React, { Component } from 'react';
import PropTypes from 'prop-types';
import logo from '../../images/blogo.png';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as sessionActions from '../../../actions/sessionActions';
import { createBrowserHistory } from "history";
const history = createBrowserHistory()

class Header extends Component {

  constructor(props) {
    super();
    this.logOut = this.logOut.bind(this);
  }

  logOut(event) {
    event.preventDefault();
    this.props.actions.logOutUser();
    window.location.replace("/");
  }

  // Here you can define your PropTypes.
  static propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string
  };
  render() {
    const {
      title = 'benfido CLO',
      url = '/'
    } = this.props;
    const isLoggedIn = this.props.logged_in;
    console.log(isLoggedIn);
    let button;
    if (isLoggedIn === true || isLoggedIn.success) {
      button = <Link to="logout" className="nav-link login-btn" onClick={this.logOut}>Log out</Link>;
    } else {
      button = <Link to="login" className="nav-link logout-btn">Login</Link>;
    }
    if (isLoggedIn.error && !isLoggedIn.success) {
      alert(isLoggedIn.error);
    }
    if (isLoggedIn.success) {
      console.log(history);
      //window.location.replace("/");
    }

    return (
            <header className="app-header navbar">
              <button className="c-header-toggler c-class-toggler d-lg-none mfe-auto" type="button" data-target="#sidebar" data-class="c-sidebar-show">Toggler</button>
              <ul className="c-header-nav d-md-down-none">
                <li className="c-header-nav-item px-3"><a className="c-header-nav-link" href="#">Dashboard</a></li>
                <li className="c-header-nav-item px-3"><a className="c-header-nav-link" href="#">Users</a></li>
                <li className="c-header-nav-item px-3"><a className="c-header-nav-link" href="#">Settings</a></li>
              </ul> 
                
                
             <div className="c-subheader justify-content-between px-3">
                <ol className="breadcrumb border-0 m-0 px-0 px-md-3">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item"><a href="#">Admin</a></li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
                <div className="c-subheader-nav d-md-down-none mfe-2"><a className="c-subheader-nav-link" href="#">&nbsp;Settings</a></div>
              </div>
            </header>
            );
  }
}


Header.defaultProps = {
  title: 'Benfido'
}

Header.propTypes = {
  actions: PropTypes.object.isRequired
}

function mapStateToProps(state, ownProps) {
  return {logged_in: state.session};
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(sessionActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);