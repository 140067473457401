import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import { useHistory } from 'react-router-dom';
import {Card, CardBody, CardColumns, CardHeader, Col, Row} from 'reactstrap';
import MapSection from '../../shared/components/form-section/MapSection'
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import BrandInfo from './BrandInfo';
import { addBrand } from '../../actions/brandUpdateActions';
import SelectBrand from './SelectBrand';
import ImageGroup from './ImageGroup';
import AddBlockList from '../AddBlockList';
import Settings from './Settings';
import Address from './Address';
import Billing from './Billing';
import Contact from './Contact';
import Other from './Other';

function BrandFormNew() {
    const [ loading, setLoading ] = useState(false);
    const [ snackbarOpen, setSnackbarOpen ] = useState(false);
    const [ brand, setBrand ] = useState({})
    const [ errorMessage, setErrorMessage ] = useState({});
    const [ updateBrand, setUpdateBrand ] = useState({});
    const formRef = useRef(null); // Add a ref to the form element
    const history = useHistory();
    const getDataChild = useCallback((data) => {
        setUpdateBrand(item => ({ ...item, ...data }));
    }, [ updateBrand ]);

    const handleUpdateBrand = useCallback(async (e) =>  {
        e.preventDefault();
        console.log(e);
        await setLoading(true);
        setBrand(updateBrand)
        const { error = {} } = await addBrand({...updateBrand})
        if(Object.keys(error).length > 0 ) {
            setErrorMessage(error);
            await setLoading(false);
            return
        }

        setErrorMessage({})

        setLoading(false);

        setSnackbarOpen(true)
        setTimeout(() => history.push('/brands/brands/'), 2000)
    }, [ updateBrand ])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"})

        return () => setSnackbarOpen(false);
    }, [ ])

    const useLocationParams = coordinates => {
        setUpdateBrand(item => ({ ...item, ...{
            longitude: coordinates[0],
            latitude: coordinates[1]
        }}));
    }

  return (
      <Col className="animated mt-5">
        <form ref={formRef} onSubmit={handleUpdateBrand}>
            <Fade direction="up" in={!loading} mountOnEnter unmountOnExit>
              <Card>
                <CardHeader><i className="fa fa-align-justify"></i> Brand details</CardHeader>
                <CardBody>
                  <CardColumns className="form-sections">
                      {
                          <>
                              <BrandInfo
                                  brand={brand}
                                  newBrand
                                  getDataChild={getDataChild}
                                  error={errorMessage}
                              />

                              <SelectBrand
                                  title={'Parent Brand'}
                                  brand={brand}
                                  newBrand
                                  getDataChild={getDataChild}
                                  error={errorMessage}
                              />

                              <ImageGroup
                                  brand={brand}
                                  newBrand
                                  getDataChild={getDataChild}
                                  error={errorMessage}
                              />

                              <Card>
                                  <CardBody>
                                      <div className="d-inline card-title">Tags</div>
                                      <AddBlockList
                                          getData={getDataChild}
                                          newBrand
                                          data={[]}
                                          title={'Tags'}
                                      />
                                  </CardBody>
                              </Card>


                              <SelectBrand
                                  title={'Contract Types'}
                                  newBrand
                                  brand={brand}
                                  getDataChild={getDataChild}
                              />

                              <SelectBrand
                                  title={'Categories'}
                                  brand={brand}
                                  newBrand
                                  getDataChild={getDataChild}
                              />

                              <Settings
                                  brand={brand}
                                  newBrand
                                  getDataChild={getDataChild}
                                  error={errorMessage}
                              />
                          </>
                      }
                    </CardColumns>


                    <MapSection
                        brand={brand}
                        newBrand
                        useLocationParams={useLocationParams}
                    />

                    <CardColumns className="form-sections">
                        {
                            <>
                                <Address
                                    newBrand
                                    brand={brand}
                                    getDataChild={getDataChild}
                                    error={errorMessage}
                                />

                                <Billing
                                    brand={brand}
                                    newBrand
                                    getDataChild={getDataChild}
                                    error={errorMessage}
                                />

                                <Contact
                                    brand={brand}
                                    newBrand
                                    getDataChild={getDataChild}
                                    error={errorMessage}
                                />

                                <Other
                                    brand={brand}
                                    newBrand
                                    getDataChild={getDataChild}
                                    error={errorMessage}
                                />
                            </>
                        }
                    </CardColumns>

                    <Row>
                        <Col lg={12}>
                            <button type="submit" className="btn btn-primary mt-3">Submit</button>
                        </Col>
                    </Row>
                </CardBody>
              </Card>
            </Fade>

            <Fade in={loading} mountOnEnter unmountOnExit>
                <span style={{
                    position: 'fixed',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    marginLeft: 150,
                    color: 'black',
                    background: 'white',
                    zIndex: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 40,
                    textAlign: 'center'
                }}>Loading ...</span>
            </Fade>

            <Snackbar open={snackbarOpen} autoHideDuration={1000} message="Saved"
                      action={
                          <React.Fragment>
                              <a className="text-white cursor-pointer mr-2" aria-label="close">
                                  <i className="cil-x"/>
                              </a>
                          </React.Fragment>
                      }
            />
        </form>
      </Col>
  );
}

export default memo(BrandFormNew);