// Dependencies
import axios from 'axios';
import { API_URL, X_API_KEY, X_API_VERSION } from '../conf.js';
import { getCookie, setCookie } from '../shared/utils/cookies';
import { setStorageItem, getStorageItem } from '../shared/utils/localStorage';
import { axiosInterceptors } from '../shared/utils/axiosInterceptors';
import { objectToFormData } from 'object-to-formdata';

// Action Types
import {
  GET_CONTRACT_TYPE_REQUEST,
  GET_CONTRACT_TYPE_SUCCESS,
  GET_CONTRACT_TYPE_ERROR,
  GET_CONTRACT_TYPES_REQUEST,
  GET_CONTRACT_TYPES_SUCCESS,
  GET_CONTRACT_TYPES_ERROR,
  ADD_CONTRACT_TYPE_REQUEST,
  ADD_CONTRACT_TYPE_SUCCESS,
  ADD_CONTRACT_TYPE_ERROR,
  DELETE_CONTRACT_TYPE_REQUEST,
  DELETE_CONTRACT_TYPE_SUCCESS,
  DELETE_CONTRACT_TYPE_ERROR,
  UPDATE_CONTRACT_TYPE_REQUEST,
  UPDATE_CONTRACT_TYPE_SUCCESS,
  UPDATE_CONTRACT_TYPE_ERROR, GET_COUNTRIES_SUCCESS
} from './actionTypes';

// Base Actions
import { request, received, error } from '../shared/redux/baseActions';


const getContractType = (params) => dispatch => {
  dispatch(request(GET_CONTRACT_TYPE_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: API_URL + '/contract_types/' + id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_CONTRACT_TYPE_SUCCESS, response.data)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_CONTRACT_TYPE_ERROR));
    });
};

const getContractTypes = (params) => async dispatch => {
  dispatch(request(GET_CONTRACT_TYPES_REQUEST));
  var perPage = 50;
  var page = 1;
  console.log('GET_CONTRACT_TYPES');
  console.log(params);

  if (getStorageItem('_contract_types')) {
    const contractTypes = JSON.parse(getStorageItem('_contract_types'))
    console.log('contractTypes')
    console.log(contractTypes)
    return await dispatch(received(GET_CONTRACT_TYPES_SUCCESS, {items: contractTypes}))
  }


  var url = API_URL + '/contract_types?';
  if (typeof(params) == 'undefined') {
    params = {};
    params['order[name]']='ASC';
  }
  var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    console.log(queryString);
    url += queryString;
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: url,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  // If everything is correct we dispatch our received action   
  // otherwise our error action.
  return axiosInstance(axiosData)
    .then(response => {
      setStorageItem('_contract_types', JSON.stringify(response.data['items']))
      return dispatch(received(GET_CONTRACT_TYPES_SUCCESS, {items: response.data['items'], meta: response.data["meta"]}))
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response || err);
      dispatch(error(GET_CONTRACT_TYPES_ERROR));
    });
};

const addContractType = (params) => dispatch => {
  localStorage.removeItem('_contract_types')
  dispatch(request(ADD_CONTRACT_TYPE_REQUEST))
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('ADD_CONTRACT_TYPE');
  console.log(params);
  /*
  if(typeof(params.keywords)!='undefined') {
    params.keywords = params.keywords.join(',');
  }
  else {
    params.keywords = '';
  }
  */

  // Axios Data
  const axiosData = {
    method: 'POST',
    url: API_URL + '/contract_types',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: params
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(ADD_CONTRACT_TYPE_SUCCESS, typeof(response.data['form'])!='undefined' ? response.data['form'] : response.data )))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(ADD_CONTRACT_TYPE_ERROR, err.response));
    });
};

const updateContractType = (params) => dispatch => {
  localStorage.removeItem('_contract_types')
  dispatch(request(UPDATE_CONTRACT_TYPE_REQUEST))
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('UPDATE_CONTRACT_TYPE');
  console.log(params);
  /*
  if(typeof(params.keywords)!='undefined') {
    params.keywords = params.keywords.join(',');
  }
  else {
    params.keywords = '';
  }
  */
  // Axios Data
  const axiosData = {
    method: 'PATCH',
    url: API_URL + '/contract_types/' + params.id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: params
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(UPDATE_CONTRACT_TYPE_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(UPDATE_CONTRACT_TYPE_ERROR, err.response));
    });
};

const deleteContractType = (id) => dispatch => {
  localStorage.removeItem('_contract_types')
  dispatch(request(DELETE_CONTRACT_TYPE_REQUEST));
  // Axios Data
  const axiosData = {
    method: 'DELETE',
    url: API_URL + '/contract_types/' + id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'X-Api-Version' : X_API_VERSION,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(DELETE_CONTRACT_TYPE_SUCCESS, response.data)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(DELETE_CONTRACT_TYPE_ERROR, err.response));
    });
}

const resetContractType = () => dispatch => {
  localStorage.removeItem('_contract_types')
  dispatch(received(ADD_CONTRACT_TYPE_SUCCESS, []))
};

export { getContractType, getContractTypes, addContractType, updateContractType, resetContractType, deleteContractType };
