import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import * as router from 'react-router-dom';
import { Container } from 'reactstrap';
import { element } from 'prop-types';

// import './App.css';
// Layout Components
import Sidebar from '../shared/components/layout/Sidebar';
import Header from '../shared/components/layout/Header';
import Content from '../shared/components/layout/Content';
import Footer from '../shared/components/layout/Footer';

import '../shared/App.scss';
import {
AppAside,
        AppFooter,
        AppHeader,
        AppSidebar,
        AppSidebarFooter,
        AppSidebarForm,
        AppSidebarHeader,
        AppSidebarMinimizer,
        AppBreadcrumb2 as AppBreadcrumb,
        AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from './_nav';
import routes from '../routes';
import DefaultAside from '../shared/components/layout/DefaultAside';
import DefaultHeader from '../shared/components/layout/DefaultHeader';
import DefaultFooter from '../shared/components/layout/DefaultFooter';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;


class AppLogin extends Component {
  render() {
    return (
            <div className="app">  
              <div className="app-body">
                <main className="main">
                  <Container fluid>
                    <Content>{this.props.children}</Content>
                  </Container>
                </main>
              </div>
            </div>
            )
  }
}

AppLogin.propsTypes = {
  children: element
}

export default AppLogin;