import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';

function ImageGroup({ brand, getDataChild }) {
    const { logo, image1, image2, image3  } = brand;

    const [ imagesList, setImagesList ] = useState({
        logo: '',
        image1: '',
        image2: '',
        image3: '',
    });

    useEffect(() => {
        setImagesList({
            logo,
            image1,
            image2,
            image3
        })
    }, [logo, image1, image2, image3]);

    const handleEvent = useCallback(e => {
        e.persist()
        const { target } = e
        const { name } = target;

        setImagesList(item => ({...item, [name]: target.files[0] }));
    }, [ imagesList ]);

    useEffect(() => getDataChild(imagesList), [imagesList]);


    return (
        <Card>
            <CardBody>
                <div className="d-inline card-title">Logo</div>
                <Row>
                    <Col lg={6} xs={12}>
                        <label className="">Logo</label>
                        <br/>
                        <input type="file"
                               onChange={handleEvent}
                               name="logo"
                               className="mb-3"/>
                        {
                            (imagesList.logo &&  typeof imagesList.logo !== 'object') &&
                            <div className="flex justify-center">
                                <img src={imagesList.logo}
                                     className="img-thumbnail brnd-logo"
                                     alt={'logo'}
                                />
                            </div>
                        }
                    </Col>

                    <Col lg={6} xs={12}>
                        <label className="">Image 1</label>
                        <br/>
                        <input type="file"
                               onChange={handleEvent}
                               name="image1"
                               accept=".jpg, .png"
                               className="mb-3"/>

                            {
                                (imagesList.image1 && typeof imagesList.image1 !== 'object') &&
                                <div className="flex justify-center">
                                    <img src={imagesList.image1}
                                         className="img-thumbnail brnd-logo" />
                                </div>
                            }
                    </Col>

                    <Col lg={6} xs={12}>
                        <label className="">Image 2</label>
                        <br/>
                        <input type="file"
                               onChange={handleEvent}
                               name="image2"
                               accept=".jpg, .png"
                               className="mb-3"/>
                        {
                            (imagesList.image2 && typeof imagesList.image2 !== 'object') &&

                            <div className="flex justify-center">
                                <img src={imagesList.image2}
                                     className="img-thumbnail brnd-logo" />
                            </div>
                        }
                    </Col>

                    <Col lg={6} xs={12}>
                        <label className="">Image 3</label>
                        <br/>
                        <input type="file"
                               onChange={handleEvent}
                               name="image3"
                               accept=".jpg, .png"
                               className="mb-3"/>
                        {
                            (imagesList.image3 &&  typeof imagesList.image3 !== 'object') &&
                            <div className="flex justify-center">
                                <img src={imagesList.image3}
                                     className="img-thumbnail brnd-logo" />
                            </div>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default memo(ImageGroup);
