import React, {Component} from 'react';


export default function TransactionShow(props) {

    let {transaction} = props;
    return (
      <div className="container transactions-modal">
        <div className="row">
          <div className="col-12 col-sm-12">
            <h1>Transaction details</h1>
          </div>
        </div>
        <div className="row card">
          <div className="col-12 col-sm-12">
            <table className="table mt-4">
              <tbody>
                <tr>
                  <th>ID:</th>
                  <td>{transaction.id}</td>
                </tr>
                <tr>
                  <th>Reference:</th><td>{transaction.reference}</td>
                </tr>
                <tr>
                  <th className="w-50">Brand:</th><td>{transaction.brand!=null ? transaction.brand.name : '---'}</td>
                </tr>
                <tr>
                  <th className="w-50">Customer:</th><td>{transaction.customer_id}</td>
                </tr>
                <tr>
                  <th className="w-50">Type:</th><td>{transaction.type}</td>
                </tr>
                <tr>
                  <th className="w-50">Status:</th><td>{transaction.status}</td>
                </tr>
                <tr>
                  <th>Amount:</th><td>{transaction.amount}</td>
                </tr>
                <tr>
                  <th>Commission:</th><td>{transaction.commission}</td>
                </tr>
                <tr>
                  <th>Net Amount:</th><td>{transaction.net_amount}</td>
                </tr>
                <tr>
                  <th>Paid Amount:</th><td>{transaction.paid_amount}</td>
                </tr>
                <tr>
                  <th>Gross Amount:</th><td>{transaction.gross_amount}</td>
                </tr>
                <tr>
                  <th>Payment ID:</th><td>{transaction.payment_id}</td>
                </tr>
                <tr>
                  <th>Booked at:</th><td>{transaction.booked_at ? transaction.booked_at.substring(0,10) : '---'}</td>
                </tr>
                <tr>
                  <th className="w-50">Notes:</th><td>{transaction.notes}</td>
                </tr>
              </tbody>
            </table>
            {/*
            Customer
            <table className="table mt-2">
              {transaction.customer!=null &&
                <tbody>
                  <tr>
                    <th className="w-50">ID:</th><td>{transaction.customer.id}</td>
                  </tr>
                  <tr>
                    <th className="w-50">Role:</th><td>{transaction.customer.role}</td>
                  </tr>
                  <tr>
                    <th className="w-50">Locale:</th><td>{transaction.customer.locale}</td>
                  </tr>
                  <tr>
                    <th className="w-50">Active:</th><td>{transaction.customer.active ? 'Yes':'No'}</td>
                  </tr>
                  <tr>
                    <th>Created at:</th><td>{transaction.customer.created_at ? transaction.customer.created_at.substring(0,10) : '---'}</td>
                  </tr>
                  <tr>
                    <th>Updated at:</th><td>{transaction.customer.updated_at ? transaction.customer.updated_at.substring(0,10) : '---'}</td>
                  </tr>
               </tbody>
              }
            </table>
            */}
          </div>
        </div>
      </div>
    )
}