import React, { Component } from 'react';
import SimpleLineIcon from 'react-simple-line-icons';
import { Link } from 'react-router-dom';
import * as router from 'react-router-dom';
import {
  AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../../components/_nav';
// import CustomIcon2 from './path/to/custom/icon2.svg';

// console.log(navigation.items)
// navigation.items[1].icon = <img src={CustomIcon2} alt="Custom Icon 2" />;

class Sidebar extends Component {

  constructor(props) {
    super(props)
  }

  render() {

    const sliCSS = {display: 'inline-block', paddingRight: '10px'}
    return (
      <div>
        <AppSidebarNav navConfig={navigation} {...this.props.props} router={router}/>
      </div>
    );
 }
}

export default Sidebar;