 export const validate = values => {
  
  const errors = {}
  // if (!values.country_code) {
  //   errors.country_code = 'Required';
  // }
  if (values.password && values.password.length < 8) {
    errors.password = 'Must be at least 8 characters long'
  }

  return errors
}