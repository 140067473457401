import { GET_USERS_SUCCESS, GET_USERS_ERROR, GET_USERS_REQUEST} from './actionTypes'
import {getUsersList} from './brandUpdateActions';

export function usersActions(params) {
    return async (dispatch) => {
        try {
            dispatch({
                type : GET_USERS_REQUEST
            });

            // await api.cart.list(session);
            const list = await getUsersList(params);

            dispatch({
                type :  GET_USERS_SUCCESS,
                data : list
            });
        } catch (err) {
            console.log('[ERROR] GET_USERS_ERROR', err);
            dispatch({
                type : GET_USERS_ERROR,
                data : err
            });
            throw (err);
        }
    };
}

export function resetUsersActions() {
    return async (dispatch) => {
        try {
            dispatch({
                type : GET_USERS_REQUEST
            });

            dispatch({
                type :  GET_USERS_SUCCESS,
                data : []
            });
        } catch (err) {
            console.log('[ERROR] GET_USERS_ERROR', err);
            dispatch({
                type : GET_USERS_ERROR,
                data : err
            });
            throw (err);
        }
    };
}
