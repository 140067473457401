import React, {Component} from 'react';


export default function CashbackShow(props) {

    let {cashback} = props;
    return (
      <div className="container cashbacks-modal">
        <div className="row">
          <div className="col-12 col-sm-6">
            <table className="table mt-4">
              <tbody>
                <tr>
                  <th className="bg-light w-50">Brand:</th><td>{cashback.brand.name}</td>
                </tr>
                <tr>
                  <th className="bg-light w-50">Title:</th><td>{cashback.title?.en || cashback.title}</td>
                </tr>
                <tr>
                  <th className="bg-light">Identifier:</th><td>{cashback.identifier}</td>
                </tr>
                <tr>
                  <th className="bg-light">Amount:</th><td>{cashback.amount}</td>
                </tr>
                <tr>
                  <th className="bg-light">Unit:</th><td>{cashback.unit}</td>
                </tr>
                <tr>
                  <th className="bg-light">Description:</th><td>{cashback.description?.en ? cashback.description.en : '---' }</td>
                </tr>
                <tr>
                  <th className="bg-light">Active:</th><td>{cashback.active ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <th className="bg-light">Start date:</th><td>{cashback.start_date ? cashback.start_date.substring(0,10) : '---'}</td>
                </tr>
                <tr>
                  <th className="bg-light">End date:</th><td>{cashback.end_date ? cashback.end_date.substring(0,10) : '---'}</td>
                </tr>
                <tr>
                  <th className="bg-light">Online:</th><td>{cashback.online ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <th className="bg-light">Offline:</th><td>{cashback.offline ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <th className="bg-light">Male:</th><td>{cashback.male ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <th className="bg-light">Female:</th><td>{cashback.female ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                  <th className="bg-light">Payments count:</th><td>{cashback.payments_count}</td>
                </tr>
                <tr>
                  <th className="bg-light">Turnover:</th><td>{cashback.turnover}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-12 col-sm-6">
            <table className="table mt-4">
              <tbody>  
                <tr>
                  <th className="bg-light">Categories:</th>
                  <td>
                    {
                      cashback.categories.map((category)=>(
                        <div>- {category.name}</div>
                      ))
                    }
                  </td>
                </tr>
                <tr>
                  <th className="bg-light">Countries:</th>
                  <td>
                    {
                      cashback.countries.map((country)=>(
                        <div>- {country.name}</div>
                      ))
                    }
                  </td>
                </tr>
                <tr>
                  <th className="bg-light">Brands:</th>
                  <td>
                    {
                      cashback.brands.map((brand)=>(
                        <div>- {brand.name}</div>
                      ))
                    }
                  </td>
                </tr>
                <tr>
                  <th className="bg-light">Banks:</th>
                  <td>
                    {
                      cashback.banks.map((bank)=>(
                        <div>- {bank.name}</div>
                      ))
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
}