import React, {useCallback, useEffect, useState} from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';

function Other({ brand, newBrand = false, getDataChild }) {

    const { website, twitter, facebook, snapchat, instagram } = brand;

    const [ list, setList ] = useState({
        website,
        twitter,
        facebook,
        snapchat,
        instagram
    });

    const handleChange = useCallback((e) => {
        e.persist()
        const { target } = e;
        const { name , value, checked } = target;

        setList(item => ({...item, [name]: name === 'meinplus' ? checked : value }))
    }, []);

    useEffect(() => {
        getDataChild(list);
    }, [ list ]);

    return (
        <Card>
            <CardBody>
                <div className="card-title">Others</div>

                <Row>
                    <Col xs={12} lg={6}>
                        <div className="mb-2 mr-2">
                            <label>Website </label>
                            <div>
                                <input name="website" type="text" className="form-control"
                                        onChange={handleChange} defaultValue={website} />
                            </div>
                        </div>
                        <div className="mb-2 mr-2">
                            <label>Twitter </label>
                            <div>
                                <input name="twitter" type="text" className="form-control" onChange={handleChange} defaultValue={twitter} />
                            </div>
                        </div>
                        <div className="mb-2 mr-2">
                            <label>Facebook </label>
                            <div>
                                <input name="facebook" type="text" className="form-control"
                                        onChange={handleChange} defaultValue={facebook} />
                            </div>
                        </div>
                        <div className="mb-2 mr-2">
                            <label>Snapchat </label>
                            <div>
                                <input name="snapchat" type="text" className="form-control" onChange={handleChange} defaultValue={snapchat} />
                            </div>
                        </div>
                        <div className="mb-2 mr-2">
                            <label>Instagram </label>
                            <div>
                                <input name="instagram" type="text" className="form-control"
                                onChange={handleChange} defaultValue={instagram} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default React.memo(Other);