import React, {memo, useCallback, useEffect, useState} from 'react';
import { Card, CardBody } from 'reactstrap';

function Address({ brand, newBrand = false, getDataChild, error = {} }) {
    const [ addressList, setAddressList ] = useState({
        title: '',
        street: '',
        city: '',
        country: '',
        zip: ''
    });

    const { address , longitude, latitude } = brand || {};

    let {
        title = '',
        street = '',
        city = '',
        country = '',
        zip = ''
    } = address || {};

    if(newBrand && Object.keys(brand).length > 0) {
        title = brand['address[title]'] || ''
        street = brand['address[street]'] || ''
        city = brand['address[city]'] || ''
        country = brand['address[country]'] || ''
        zip = brand['address[zip]'] || ''
    }

    const handleChange = useCallback((e) => {
        e.persist()
        const { target } = e;
        const { name , value } = target;

        setAddressList(item => ({...item, [name]: value }))
    }, [ addressList ]);

    useEffect(() => getDataChild(addressList), [ addressList ])

    return (
        <Card>
            <CardBody>
                <div className="d-inline card-title">Address</div>

                    <div className="mb-2 mr-2">
                        <label>Title </label>
                        <div>
                            <input onChange={handleChange} name="address[title]" type="text" defaultValue={title} className="form-control" />
                            {  (Object.keys(error).length > 0 && error['address.title']) &&
                                <span style={{
                                    position: 'absolute',
                                    marginTop: '-4px',
                                    color: 'red'
                                }}> { error['address.title'][0] } </span>
                            }
                        </div>
                    </div>
                    <div className="mb-2 mr-2">
                        <label>Street </label>
                        <div>
                            <input onChange={handleChange} name="address[street]" type="text" defaultValue={street} className="form-control" />
                            {  (Object.keys(error).length > 0 && error['address.street']) &&
                                <span style={{
                                    position: 'absolute',
                                    marginTop: '-4px',
                                    color: 'red'
                                }}> { error['address.street'][0] } </span>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="mb-2 mr-2">
                                <label>ZIP </label>
                                <div>
                                    <input onChange={handleChange} name="address[zip]" type="text" defaultValue={zip} className="form-control" />
                                    {  (Object.keys(error).length > 0 && error['address.zip']) &&
                                        <span style={{
                                            position: 'absolute',
                                            marginTop: '-4px',
                                            color: 'red'
                                        }}> { error['address.zip'][0] } </span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="mb-2 mr-2">
                                <label>City </label>
                                <div>
                                    <input onChange={handleChange} name="address[city]" type="text" defaultValue={city} className="form-control" />
                                    {  (Object.keys(error).length > 0 && error['address.city']) &&
                                        <span style={{
                                            position: 'absolute',
                                            marginTop: '-4px',
                                            color: 'red'
                                        }}> { error['address.city'][0] } </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 mr-2">
                        <label>Country </label>
                        <div>
                            <input onChange={handleChange} name="address[country]" type="text" defaultValue={country} className="form-control" />
                            {  (Object.keys(error).length > 0 && error['address.country']) &&
                                <span style={{
                                    position: 'absolute',
                                    marginTop: '-4px',
                                    color: 'red'
                                }}> { error['address.country'][0] } </span>
                            }
                        </div>
                    </div>

                <div className="row">
                    <div className="col-6">
                        <div className="mb-2 mr-2">
                            <label>Longitude </label>
                            <div>
                                <input onChange={handleChange} name="longitude" type="text" defaultValue={longitude} className="form-control" />
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="mb-2 mr-2">
                            <label>Latitude</label>
                            <div>
                                <input onChange={handleChange} name="latitude" type="text" defaultValue={latitude} className="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default memo(Address);