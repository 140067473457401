// Action Types
import {
        GET_CONTRACT_TYPE_SUCCESS, GET_CONTRACT_TYPES_SUCCESS, ADD_CONTRACT_TYPE_SUCCESS
        } from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {
  contractTypes: []
};

export default function contractTypesReducer(state = initialState, action) {
  switch (action.type) {
    
    /*
    case GET_CONTRACT_TYPE_SUCCESS:
    {
      const {payload: contractType} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        contractType
      });
    }
    break;
    */
    
    case GET_CONTRACT_TYPES_SUCCESS:
    {
      const {payload: result} = action;
      console.log('ACTION');
      console.log(action);
      return getNewState(state, {
        result
      });
    }
    break;
    
    /*
    case ADD_CONTRACT_TYPE_SUCCESS:
    {
      const {payload: contractTypes} = action;
      return getNewState(state, {
        contractTypes
      });
    }
    break;
    */
 
  default:
      return state;
}
};