import React, { useState, useEffect, memo } from 'react';

function AddBlockList({ getData, data = [], title = '' }) {
    const [inputs, setInputs] = useState([]);
    const [,setValuesArray] = useState([]);
    //

    useEffect(() => {
        const values = inputs?.map((input) => input.name);
        setValuesArray(values);
        getData(title === 'Tags' ? { tags : inputs } : inputs);
    }, [ inputs ]);
    //
    useEffect(() => {
        if(data.length > 0 && title !== 'Aliases') {
            data = data?.map((item,key) => (
                {id: key + 1,  name: item }
            ));

            setInputs(data);
            setValuesArray(data);

            return
        }


        if(data.length > 0 && title === 'Aliases') {
            setInputs(data);
        }

    }, [ data ]);
    //
    const handleAddInput = () => {
        const newInput = { name: '', id: `new-${inputs.length + 1}`, status: 'new' };
        setInputs([...inputs, newInput]);
    };

    const handleRemoveInput = (idToRemove) => {
        const updatedInputs = inputs.map(input => {
            if(input.id === idToRemove) {
                return {...input , delete: true }
            } else return input
        })

        setInputs(updatedInputs);
    };

    const handleInputChange = (id, value) => {
        const updatedInputs = inputs.map((input) =>
            input.id === id ? { ...input, name: value } : input
        );
        setInputs(updatedInputs);
    };

    return (
        <div className={'mt-3 mb-3'}>
            {
                title !== 'Terminal ids' && <h5>{ title }</h5>
            }
            {inputs?.map((input) => ( !input.delete &&
                <React.Fragment key={input.id}>
                   <label style={{
                       display: 'block'
                   }}> { title } Name </label>
                    <div key={input?.id} className="d-flex align-items-end">
                        <div className="mb-2 mr-2">
                            <div>
                                <input
                                    type="text"
                                    defaultValue={input.name}
                                    onChange={(e) => handleInputChange(input.id, e.target.value)}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="mb-2">
                            <button
                                type="button"
                                className="btn btn-light btn-sm mb-1"
                                onClick={() =>  handleRemoveInput(input.id)}
                            >
                                <i className="cil-x" />
                            </button>
                        </div>
                    </div>
                </React.Fragment>
            ))}

            <a type="button" onClick={handleAddInput} className="btn-link d-inline-block mb-3">
                <i className="cil-plus" /> Add { title }
            </a>
        </div>
    );
}

export default memo(AddBlockList);
