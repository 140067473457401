import React, {memo, useCallback, useEffect, useState} from 'react';
import {getPayouts, getPayoutsCsv, getPayoutsXml} from '../../actions/paymentActions';
import {Card, CardBody, CardHeader, Col, Fade} from 'reactstrap';
import DataTable from 'react-data-table-component';
import { withRouter } from 'react-router-dom';
import DataTableProgressComponent from '../../shared/components/widget/DataTableProgressComponent';
import DataTableNoDataComponent from '../../shared/components/widget/DataTableNoDataComponent';
import memoize from 'memoize-one';
import {getBanks} from '../../actions/bankActions';
import {connect} from 'react-redux';
import DropdownList from 'react-widgets/lib/DropdownList';
import {Link} from 'react-router-dom';
import fs from 'fs';

const Sepa = () => {
    return (
    <svg width="25" style={{ margin: '0 5px' }} viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg">
    <title/>

    <g id="xxx-word">

        <path className="cls-1" d="M325,105H250a5,5,0,0,1-5-5V25a5,5,0,1,1,10,0V95h70a5,5,0,0,1,0,10Z"/>

        <path className="cls-1" d="M325,154.83a5,5,0,0,1-5-5V102.07L247.93,30H100A20,20,0,0,0,80,50v98.17a5,5,0,0,1-10,0V50a30,30,0,0,1,30-30H250a5,5,0,0,1,3.54,1.46l75,75A5,5,0,0,1,330,100v49.83A5,5,0,0,1,325,154.83Z"/>

        <path className="cls-1" d="M300,380H100a30,30,0,0,1-30-30V275a5,5,0,0,1,10,0v75a20,20,0,0,0,20,20H300a20,20,0,0,0,20-20V275a5,5,0,0,1,10,0v75A30,30,0,0,1,300,380Z"/>

        <path className="cls-1" d="M275,280H125a5,5,0,1,1,0-10H275a5,5,0,0,1,0,10Z"/>

        <path className="cls-1" d="M200,330H125a5,5,0,1,1,0-10h75a5,5,0,0,1,0,10Z"/>

        <path className="cls-1" d="M325,280H75a30,30,0,0,1-30-30V173.17a30,30,0,0,1,30-30h.2l250,1.66a30.09,30.09,0,0,1,29.81,30V250A30,30,0,0,1,325,280ZM75,153.17a20,20,0,0,0-20,20V250a20,20,0,0,0,20,20H325a20,20,0,0,0,20-20V174.83a20.06,20.06,0,0,0-19.88-20l-250-1.66Z"/>

        <path className="cls-1" d="M168.48,217.48l8.91,1a20.84,20.84,0,0,1-6.19,13.18q-5.33,5.18-14,5.18-7.31,0-11.86-3.67a23.43,23.43,0,0,1-7-10,37.74,37.74,0,0,1-2.46-13.87q0-12.19,5.78-19.82t15.9-7.64a18.69,18.69,0,0,1,13.2,4.88q5.27,4.88,6.64,14l-8.91.94q-2.46-12.07-10.86-12.07-5.39,0-8.38,5t-3,14.55q0,9.69,3.2,14.63t8.48,4.94a9.3,9.3,0,0,0,7.19-3.32A13.25,13.25,0,0,0,168.48,217.48Z"/>

        <path className="cls-1" d="M179.41,223.15l9.34-2q1.68,7.93,12.89,7.93,5.12,0,7.87-2a6.07,6.07,0,0,0,2.75-5,7.09,7.09,0,0,0-1.25-4q-1.25-1.85-5.35-2.91l-10.2-2.66a25.1,25.1,0,0,1-7.73-3.11,12.15,12.15,0,0,1-4-4.9,15.54,15.54,0,0,1-1.5-6.76,14,14,0,0,1,5.31-11.46q5.31-4.32,13.59-4.32a24.86,24.86,0,0,1,12.29,3,13.56,13.56,0,0,1,6.89,8.52l-9.14,2.27q-2.11-6.05-9.84-6.05-4.49,0-6.86,1.88a5.83,5.83,0,0,0-2.36,4.77q0,4.57,7.42,6.41l9.06,2.27q8.24,2.07,11.05,6.11a15.29,15.29,0,0,1,2.81,8.93,14.7,14.7,0,0,1-5.92,12.36q-5.92,4.51-15.33,4.51a28,28,0,0,1-13.89-3.32A16.29,16.29,0,0,1,179.41,223.15Z"/>

        <path className="cls-1" d="M250.31,236h-9.77L224.1,182.68h10.16l12.23,40.86L259,182.68h8Z"/>

    </g>

</svg>
    )
}

const XmlFile = () => {
    return (
    <svg width="25" style={{ margin: '0 5px' }} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 56 56" xmlSpace="preserve" fill="#000000" stroke="#000000">

        <g id="SVGRepo_bgCarrier" strokeWidth="0"/>

        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.784"/>

        <g id="SVGRepo_iconCarrier">
            <g>
            <path style={{fill: '#ffffff'}} d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074 c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"/>
                <polygon style={{fill: '#ffffff'}} points="37.5,0.151 37.5,12 49.349,12 "/>
                <path style={{fill: '#000000'}} d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"/> <g>
                <path style={{fill: '#ffffff'}} d="M19.379,48.105L21.936,53h-1.9l-1.6-3.801h-0.137L16.576,53h-1.9l2.557-4.895l-2.721-5.182h1.873 l1.777,4.102h0.137l1.928-4.102H22.1L19.379,48.105z"/>
                <path style={{fill: '#ffffff'}} d="M31.998,42.924h1.668V53h-1.668v-6.932l-2.256,5.605h-1.449l-2.27-5.605V53h-1.668V42.924h1.668 l2.994,6.891L31.998,42.924z"/> <path style={{fill: '#ffffff'}} d="M37.863,42.924v8.832h4.635V53h-6.303V42.924H37.863z"/> </g>
                <path  style={{fill: '#000000'}} d="M15.5,24c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l6-6 c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-6,6C16.012,23.902,15.756,24,15.5,24z"/>
                <path style={{fill: '#000000'}}d="M21.5,30c-0.256,0-0.512-0.098-0.707-0.293l-6-6c-0.391-0.391-0.391-1.023,0-1.414 s1.023-0.391,1.414,0l6,6c0.391,0.391,0.391,1.023,0,1.414C22.012,29.902,21.756,30,21.5,30z"/>
                <path style={{fill: '#000000'}}d="M33.5,30c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l6-6 c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-6,6C34.012,29.902,33.756,30,33.5,30z"/>
                <path style={{fill: '#000000'}} d="M39.5,24c-0.256,0-0.512-0.098-0.707-0.293l-6-6c-0.391-0.391-0.391-1.023,0-1.414 s1.023-0.391,1.414,0l6,6c0.391,0.391,0.391,1.023,0,1.414C40.012,23.902,39.756,24,39.5,24z"/>
                <path style={{fill: '#000000'}} d="M24.5,32c-0.11,0-0.223-0.019-0.333-0.058c-0.521-0.184-0.794-0.755-0.61-1.276l6-17 c0.185-0.521,0.753-0.795,1.276-0.61c0.521,0.184,0.794,0.755,0.61,1.276l-6,17C25.298,31.744,24.912,32,24.5,32z"/>
            </g>
        </g>

        </svg>
    )
}

function PayoutsList( { history , getBanks,  bankList = [] }) {
    const [payouts, setPayouts] = useState([]);

    const [ loading, setLoading ] = useState(false);

    const [currentBank , setCurrentBank ] = useState(null);

    const [ sepa, setSepa ] = useState({
        csv:'',
        xml: ''
    })


    useEffect(() => {
        if(bankList.length > 0) return

        const fetchData = async () => {
           await getBanks();
        };

        fetchData();
    }, [ ]);

     const downloadCSV = useCallback((data, filename) => {
        const csvContent = `data:text/csv;charset=utf-8,${data}`;
        const encodedUri = encodeURI(csvContent);
        // const link = document.createElement("a");
        // link.setAttribute("href", encodedUri);
        // link.setAttribute("download", filename);

         return {
             href: encodedUri,
             download: filename
         }

    }, []);

     const downloadXML = useCallback((xmlData, filename) => {
         const blob = new Blob([xmlData], { type: 'application/xml' });

         const url = URL.createObjectURL(blob);

         return {
             href: url,
             download: filename
         }

     },[])

    const handleCsv = useCallback(async (row) => {
        const { id, created_at } = row
        const currentUrl = new URL(window.location.href);

        const bank = currentUrl.searchParams.get('bank_id');

        const csv = await getPayoutsCsv(id, currentBank || bank);
        if(csv) {
            const bankName = bankList.filter(item => +item.id === (+currentBank || +bank)).map(elem => elem.name)[0]

            const name = `${bankName + '_' + id + '_' + created_at}`

            const linkCsv = downloadCSV(csv, `${name}.csv`);
            // Trigger download programmatically
            const downloadLink = document.createElement('a');
            downloadLink.href = linkCsv.href;
            downloadLink.download = linkCsv.download;
            downloadLink.click();
        }
    }, [currentBank, downloadCSV, bankList ]);

    const handleXml = useCallback(async (row) => {
        const { id, created_at } = row
        const currentUrl = new URL(window.location.href);

        const bank = currentUrl.searchParams.get('bank_id');

        const xml = await getPayoutsXml(id, currentBank || bank);
        if(xml) {
            const bankName = bankList.filter(item => +item.id === (+currentBank || +bank)).map(elem => elem.name)[0]

            const name = `${bankName + '_' + id + '_' + created_at}`
            const linkXml = downloadXML(xml, `${name}.xml`);
            // Trigger download programmatically
            const downloadLink = document.createElement('a');
            downloadLink.href = linkXml.href;
            downloadLink.download = linkXml.download;
            downloadLink.click();
        }
    }, [currentBank, downloadXML , bankList ]);

    useEffect(() => {
        const currentUrl = new URL(window.location.href);

        const bank = currentUrl.searchParams.get('bank_id');

        if(currentBank || bank) {
            const fetchData = async () => {
                await setLoading(true)
                const data = await getPayouts(currentBank || bank);

                setSepa(item => {
                    return {
                        ...item
                    }
                })
                await setPayouts(data);
                await setLoading(false);
            };

            fetchData();
        }
    }, [ currentBank ]);

    const handleChange = useCallback(({ id }) => {
        setCurrentBank(id);
        const currentUrl = new URL(window.location.href);

        currentUrl.searchParams.set('bank_id', id);

        window.history.replaceState({}, '', currentUrl.toString());
    }, [ currentBank ]);

    const columns = memoize(() => [
        {
            name: 'ID',
            selector: 'id',
            sortable: false,
            center: true,
            grow: 0
        },
        {
            name: 'Created at',
            selector: 'created_at',
            sortable: false,
            center: false,
            grow: 1
        },
        {
            name: 'Updated at',
            selector: 'updated_at',
            sortable: false,
            right: false,
            grow: 1
        },
        {
            name: 'Download',
            selector: 'download',
            sortable: false,
            right: false,
            grow: 1,
            cell: row => (
                <div>
                    <a
                       // href={sepa?.csv?.href} download={sepa?.csv?.download} title={sepa?.csv?.download ? '1.csv' : 'no file'}
                    onClick={() => handleCsv(row)}
                    style={{
                        cursor: 'pointer'
                    }}>
                        <Sepa />
                    </a>

                    <a style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => handleXml(row)}>
                        <XmlFile />
                    </a>
                </div>
            )
        },
        {
            name: 'View',
            selector: row => {
                const currentUrl = new URL(window.location.href);

                const bank = currentUrl.searchParams.get('bank_id');

               return <Link to={`/payouts/${row.id}?bank_id=${bank}`}>Details</Link>
            },
            sortable: false,
            right: false,
            grow: 1
        },
    ]);

    let { items = [], meta = [] } = payouts;

    items = items.map(item => {
        return {
            ...item,
            created_at: item?.created_at.split('T')[0],
            updated_at: item?.updated_at.split('T')[0]
        }
    })

    return (
        <div className={'card'}>
            <Col xs="12" lg="12">
                <>
                    <CardHeader>
                        <div className="d-flex align-items-center">
                            <div className={'mr-auto'}>
                                <i className="fa fa-align-justify"/>
                                Sepa Credit list
                            </div>


                            {
                                bankList?.length > 0 &&
                                <Col xs="4" lg="4">
                                    <DropdownList filter
                                                  placeholder="Choose Bank"
                                                  data={bankList}
                                                  defaultValue={+new URL(window.location.href).searchParams.get('bank_id') || null}
                                                  valueField="id"
                                                  onChange={handleChange}
                                                  textField="name"
                                    />
                                </Col>
                            }
                        </div>
                    </CardHeader>
                </>
            </Col>
            <div style={{
                position: 'relative'
            }}>
                <Fade in={items?.length > 0 && !loading}>
                    { <div id="payments-table" style={{
                        position: 'relative',
                        zIndex: 10
                    }}>
                        <CardBody>
                            <DataTable
                                columns={columns(() => console.log('1'), () => console.log('1'))}
                                data={items}
                                theme="solarized"
                                pagination
                                paginationTotalRows={meta.total_items}
                                paginationServer
                                sortServer
                                progressComponent={<DataTableProgressComponent />}
                                noDataComponent={<DataTableNoDataComponent />}
                            />
                        </CardBody>
                    </div> }
                </Fade>

                {
                    loading &&  <span className={'span-payouts'}>
                        Loading ...
                    </span>
                }

                <Fade in={currentBank && !items?.length > 0 && !loading}>
                    <span className={'span-payouts'}>
                         Payouts not yet
                    </span>
                </Fade>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    bankList: state?.banks?.result?.items
});

const mapDispatchToProps = {
    getBanks
};

const ConnectedPaymentsList = connect(
    mapStateToProps,
    mapDispatchToProps
)(PayoutsList);

export default memo(ConnectedPaymentsList);