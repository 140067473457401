import React, {Component} from 'react';


export default function BankShow(props) {

    let {bank} = props;
    return (
      <div>
        <table className="table mt-4">
          <tbody>
            <tr>
              <th className="bg-light w-50">Name:</th><td>{bank.name}</td>
            </tr>
            <tr>
              <th className="bg-light">Identifier:</th><td>{bank.identifier}</td>
            </tr>
            <tr>
              <th className="bg-light">URL:</th><td>{bank.url ? bank.url : '---' }</td>
            </tr>
            <tr>
              <th className="bg-light">Active:</th><td>{bank.active ? 'Active' : 'Inactive'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
}