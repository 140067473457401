import React, {memo, useCallback, useEffect, useState} from 'react';
import {Card, CardBody} from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';
import DropdownList from 'react-widgets/lib/DropdownList';
import {getBanks} from '../../actions/brandUpdateActions';
import AddBlockList from '../AddBlockList';

function Settings({ brand, newBrand = false, getDataChild }) {
    let { google_shop_name = '', cancellation = '', partner_bank, tax_number = '', sales_tax_number = '', meinplus = false } = brand

    const [ bank, setBank ] = useState([]);

    const [ list, setList ] = useState({
        google_shop_name,
        cancellation,
        tax_number,
        sales_tax_number,
        meinplus
    });

    const handleChange = useCallback((e) => {
        e.persist()
        const { target } = e;
        const { name , value, checked } = target;

        setList(item => ({...item, [name]: name === 'meinplus' ? checked : value }))
    }, []);

    const handleChangeBanks = useCallback((e) => {
        setList(item => ({...item, partner_bank: e.name }))
    }, []);

    const getBankList = useCallback(async () => {
        const bank = await getBanks()

        setBank(bank);
    },[])

    useEffect(() => {
        getBankList();
    }, []);

    useEffect(() => {
        getDataChild(list);
    }, [ list ]);

    return (
        <Card>
            <CardBody>
                <div className="d-inline card-title">Setting</div>
                <div className="mb-2 mr-2">
                    <label>Google shop name</label>
                    <div>
                        <input onChange={handleChange} defaultValue={google_shop_name} name="google_shop_name" type="text" className="form-control" />
                    </div>
                </div>

                <div className="mb-2 mr-2">
                    <label>Cancellation</label>
                    <div>
                        <input onChange={handleChange} defaultValue={cancellation?.split('T')[0]} name="cancellation" type="date" className="form-control" />
                    </div>
                </div>

                <div className="mb-2 mr-2">
                    <label>Tax number</label>
                    <div>
                        <input onChange={handleChange} defaultValue={tax_number} name="tax_number" type="text" className="form-control" />
                    </div>
                </div>

                <div className="mb-2 mr-2">
                    <label>Partner Bank</label>

                    <DropdownList
                        filter
                        placeholder="Choose Bank"
                        data={bank || []}
                        valueField="id"
                        name={'partner_bank'}
                        defaultValue={partner_bank}
                        textField="name"
                        onChange={handleChangeBanks}
                        className="mb-2"
                    />
                </div>

                <div className="mb-2 mr-2">
                    <label>Sales tax number</label>
                    <div>
                        <input onChange={handleChange} name="sales_tax_number" defaultValue={sales_tax_number} type="text" className="form-control" />
                    </div>
                </div>

                <div className="mb-2 mr-2">
                    <label>
                        Meinplus:
                        <Checkbox
                            onChange={handleChange}
                            // onChange={handleInputChange}
                            name={'meinplus'}
                            defaultChecked={meinplus}
                        />
                    </label>

                    <AddBlockList
                        getData={(e) => setList(item => ({...item,
                            'terminal_ids': e.filter(items => !items.delete).map(child => child.name)
                        }))}
                        data={brand?.terminal_ids}
                        title={'Terminal ids'}
                    />
                </div>
            </CardBody>
        </Card>
    );
}

export default memo(Settings);
