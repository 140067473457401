import React from 'react';
import {Card, CardBody} from 'reactstrap';
import {Link} from 'react-router-dom';

function SubBrand({ brand }) {
    const { sub_brands} = brand
    return (
        <Card>
            <CardBody>
                <div className="card-title">Sub Brand</div>
                <ul>
                    { sub_brands.map(item => (
                        <li>
                            <a  href={window.location.origin + `/brands/edit/${item.id}`}>{item.name}</a>
                        </li>
                    ))}
                </ul>
            </CardBody>
        </Card>
    );
}

export default React.memo(SubBrand);
