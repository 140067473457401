// Action Types
import {GET_CONTRACT_TYPE_SUCCESS, GET_CONTRACT_TYPES_SUCCESS, ADD_CONTRACT_TYPE_SUCCESS, ADD_CONTRACT_TYPE_ERROR, UPDATE_CONTRACT_TYPE_ERROR} from '../actions/actionTypes';

// Utils
import { getNewState } from '../shared/utils/frontend';

// Initial State
const initialState = {};

export default function contractTypeFormReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONTRACT_TYPE_SUCCESS: {
      console.log('ACTION');
      console.log(action);
      const {payload: contractType} = action;
      return getNewState(state, {contractType});
      break;
    }
    case ADD_CONTRACT_TYPE_SUCCESS: {
      const {payload: contractType} = action;
      return getNewState(state, {contractType});
      break;
    }
    case ADD_CONTRACT_TYPE_ERROR: {
      const {payload: contractType} = action;
      return getNewState(state, {contractType});
      break;  
    }    
    case UPDATE_CONTRACT_TYPE_ERROR: {
      const {payload: contractType} = action;
      return getNewState(state, {contractType});
      break;
    }
    default:
      return state;
  }
}