export const GET_BANK_REQUEST = 'GET_BANK_REQUEST';
export const GET_BANK_SUCCESS = 'GET_BANK_SUCCESS';
export const GET_BANK_ERROR = 'GET_BANK_ERROR';

export const GET_BANKS_REQUEST = 'GET_BANKS_REQUEST';
export const GET_BANKS_SUCCESS = 'GET_BANKS_SUCCESS';
export const GET_BANKS_ERROR = 'GET_BANKS_ERROR';

export const ADD_BANK_REQUEST = 'ADD_BANK_REQUEST';
export const ADD_BANK_SUCCESS = 'ADD_BANK_SUCCESS';
export const ADD_BANK_ERROR = 'ADD_BANK_ERROR';

export const UPDATE_BANK_REQUEST = 'UPDATE_BANK_REQUEST';
export const UPDATE_BANK_SUCCESS = 'UPDATE_BANK_SUCCESS';
export const UPDATE_BANK_ERROR = 'UPDATE_BANK_ERROR';

export const GET_BRAND_REQUEST = 'GET_BRAND_REQUEST';
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRAND_ERROR = 'GET_BRAND_ERROR';

export const GET_BRANDS_REQUEST = 'GET_BRANDS_REQUEST';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRANDS_ERROR = 'GET_BRANDS_ERROR';

export const ADD_BRAND_REQUEST = 'ADD_BRAND_REQUEST';
export const ADD_BRAND_SUCCESS = 'ADD_BRAND_SUCCESS';
export const ADD_BRAND_ERROR = 'ADD_BRAND_ERROR';

export const UPDATE_BRAND_REQUEST = 'UPDATE_BRAND_REQUEST';
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS';
export const UPDATE_BRAND_ERROR = 'UPDATE_BRAND_ERROR';

export const DELETE_BRAND_REQUEST = 'DELETE_BRAND_REQUEST';
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS';
export const DELETE_BRAND_ERROR = 'DELETE_BRAND_ERROR';

export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR';

export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';

export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';

export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_ERROR = 'GET_COUNTRY_ERROR';

export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_ERROR = 'GET_COUNTRIES_ERROR';

export const ADD_COUNTRY_REQUEST = 'ADD_COUNTRY_REQUEST';
export const ADD_COUNTRY_SUCCESS = 'ADD_COUNTRY_SUCCESS';
export const ADD_COUNTRY_ERROR = 'ADD_COUNTRY_ERROR';

export const UPDATE_COUNTRY_REQUEST = 'UPDATE_COUNTRY_REQUEST';
export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS';
export const UPDATE_COUNTRY_ERROR = 'UPDATE_COUNTRY_ERROR';

export const DELETE_COUNTRY_REQUEST = 'DELETE_COUNTRY_REQUEST';
export const DELETE_COUNTRY_SUCCESS = 'DELETE_COUNTRY_SUCCESS';
export const DELETE_COUNTRY_ERROR = 'DELETE_COUNTRY_ERROR';

export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_ERROR = 'LOG_IN_ERROR';
export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_ERROR = 'LOG_OUT_ERROR';
export const CHECK_AUTH = 'CHECK_AUTH';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_ERROR = 'REFRESH_ERROR';

export const GET_ACCOUNT_DETAILS_REQUEST = 'GET_ACCOUNT_DETAILS_REQUEST';
export const GET_ACCOUNT_DETAILS_SUCCESS = 'GET_ACCOUNT_DETAILS_SUCCESS';
export const GET_ACCOUNT_DETAILS_ERROR = 'GET_ACCOUNT_DETAILS_ERROR';

export const GET_COUPON_REQUEST = 'GET_COUPON_REQUEST';
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_ERROR = 'GET_COUPON_ERROR';

export const GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_ERROR = 'GET_COUPONS_ERROR';

export const ADD_COUPON_REQUEST = 'ADD_COUPON_REQUEST';
export const ADD_COUPON_SUCCESS = 'ADD_COUPON_SUCCESS';
export const ADD_COUPON_ERROR = 'ADD_COUPON_ERROR';

export const UPDATE_COUPON_REQUEST = 'UPDATE_COUPON_REQUEST';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_ERROR = 'UPDATE_COUPON_ERROR';

export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_ERROR = 'DELETE_COUPON_ERROR';

export const GET_CASHBACK_REQUEST = 'GET_CASHBACK_REQUEST';
export const GET_CASHBACK_SUCCESS = 'GET_CASHBACK_SUCCESS';
export const GET_CASHBACK_ERROR = 'GET_CASHBACK_ERROR';

export const GET_CASHBACKS_REQUEST = 'GET_CASHBACKS_REQUEST';
export const GET_CASHBACKS_SUCCESS = 'GET_CASHBACKS_SUCCESS';
export const GET_CASHBACKS_ERROR = 'GET_CASHBACKS_ERROR';

export const ADD_CASHBACK_REQUEST = 'ADD_CASHBACK_REQUEST';
export const ADD_CASHBACK_SUCCESS = 'ADD_CASHBACK_SUCCESS';
export const ADD_CASHBACK_ERROR = 'ADD_CASHBACK_ERROR';

export const UPDATE_CASHBACK_REQUEST = 'UPDATE_CASHBACK_REQUEST';
export const UPDATE_CASHBACK_SUCCESS = 'UPDATE_CASHBACK_SUCCESS';
export const UPDATE_CASHBACK_ERROR = 'UPDATE_CASHBACK_ERROR';

export const DELETE_CASHBACK_REQUEST = 'DELETE_CASHBACK_REQUEST';
export const DELETE_CASHBACK_SUCCESS = 'DELETE_CASHBACK_SUCCESS';
export const DELETE_CASHBACK_ERROR = 'DELETE_CASHBACK_ERROR';

export const GET_PAYMENT_REQUEST = 'GET_PAYMENT_REQUEST';
export const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_ERROR = 'GET_PAYMENT_ERROR';

export const GET_PAYMENTS_REQUEST = 'GET_PAYMENTS_REQUEST';
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_ERROR = 'GET_PAYMENTS_ERROR';

export const ADD_PAYMENT_REQUEST = 'ADD_PAYMENT_REQUEST';
export const ADD_PAYMENT_SUCCESS = 'ADD_PAYMENT_SUCCESS';
export const ADD_PAYMENT_ERROR = 'ADD_PAYMENT_ERROR';

export const UPDATE_PAYMENT_REQUEST = 'UPDATE_PAYMENT_REQUEST';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_ERROR = 'UPDATE_PAYMENT_ERROR';

export const ADD_PAYMENT_CREATE_REQUEST = 'ADD_PAYMENT_CREATE_REQUEST'
export const ADD_PAYMENT_CREATE_SUCCESS = 'ADD_PAYMENT_CREATE_SUCCESS';
export const ADD_PAYMENT_CREATE_ERROR = 'ADD_PAYMENT_CREATE_ERROR';

export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR';

export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR';

export const ADD_CUSTOMER_REQUEST = 'ADD_CUSTOMER_REQUEST';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_ERROR = 'ADD_CUSTOMER_ERROR';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_ERROR = 'UPDATE_CUSTOMER_ERROR';

export const GET_CUSTOMER_TRANSACTIONS_REQUEST = 'GET_CUSTOMER_TRANSACTIONS_REQUEST';
export const GET_CUSTOMER_TRANSACTIONS_SUCCESS = 'GET_CUSTOMER_TRANSACTIONS_SUCCESS';
export const GET_CUSTOMER_TRANSACTIONS_ERROR = 'GET_CUSTOMER_TRANSACTIONS_ERROR';

export const GET_CUSTOMER_PAYMENTS_REQUEST = 'GET_CUSTOMER_PAYMENTS_REQUEST';
export const GET_CUSTOMER_PAYMENTS_SUCCESS = 'GET_CUSTOMER_PAYMENTS_SUCCESS';
export const GET_CUSTOMER_PAYMENTS_ERROR = 'GET_CUSTOMER_PAYMENTS_ERROR';

export const GET_TRANSACTION_REQUEST = 'GET_TRANSACTION_REQUEST';
export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTION_ERROR = 'GET_TRANSACTION_ERROR';

export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_ERROR = 'GET_TRANSACTIONS_ERROR';

export const ADD_TRANSACTION_REQUEST = 'ADD_TRANSACTION_REQUEST';
export const ADD_TRANSACTION_SUCCESS = 'ADD_TRANSACTION_SUCCESS';
export const ADD_TRANSACTION_ERROR = 'ADD_TRANSACTION_ERROR';

export const DELETE_TRANSACTION_REQUEST = 'ADD_TRANSACTION_REQUEST';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_ERROR = 'DELETE_TRANSACTION_ERROR';

export const UPDATE_TRANSACTION_REQUEST = 'UPDATE_TRANSACTION_REQUEST';
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_ERROR = 'UPDATE_TRANSACTION_ERROR';

export const GET_REVIEW_REQUEST = 'GET_REVIEW_REQUEST';
export const GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS';
export const GET_REVIEW_ERROR = 'GET_REVIEW_ERROR';

export const GET_REVIEWS_REQUEST = 'GET_REVIEWS_REQUEST';
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_ERROR = 'GET_REVIEWS_ERROR';

export const ADD_REVIEW_REQUEST = 'ADD_REVIEW_REQUEST';
export const ADD_REVIEW_SUCCESS = 'ADD_REVIEW_SUCCESS';
export const ADD_REVIEW_ERROR = 'ADD_REVIEW_ERROR';

export const UPDATE_REVIEW_REQUEST = 'UPDATE_REVIEW_REQUEST';
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS';
export const UPDATE_REVIEW_ERROR = 'UPDATE_REVIEW_ERROR';

export const DELETE_REVIEW_REQUEST = 'DELETE_REVIEW_REQUEST';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_ERROR = 'DELETE_REVIEW_ERROR';

export const GET_ANALYTIC_CHART_DATA_REQUEST = 'GET_ANALYTIC_CHART_DATA_REQUEST';
export const GET_ANALYTIC_CHART_DATA_SUCCESS = 'GET_ANALYTIC_CHART_DATA_SUCCESS';
export const GET_ANALYTIC_CHART_DATA_ERROR = 'GET_ANALYTIC_CHART_DATA_ERROR';



export const GET_REDASH_JOB_REQUEST = 'GET_REDASH_JOB_REQUEST';
export const GET_REDASH_JOB_SUCCESS = 'GET_REDASH_JOB_SUCCESS';
export const GET_REDASH_JOB_ERROR = 'GET_REDASH_JOB_ERROR';


export const GET_REDASH_QUERY_RESULTS_REQUEST = 'GET_REDASH_QUERY_RESULTS_REQUEST';
export const GET_REDASH_QUERY_RESULTS_SUCCESS = 'GET_REDASH_QUERY_RESULTS_SUCCESS';
export const GET_REDASH_QUERY_RESULTS_ERROR = 'GET_REDASH_QUERY_RESULTS_ERROR';


export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_BANK_ID = 'SET_BANK_ID';
export const GET_START_DATE = 'GET_START_DATE';
export const GET_END_DATE = 'GET_END_DATE';

export const GET_CONTRACT_TYPE_REQUEST = 'GET_CONTRACT_TYPE_REQUEST';
export const GET_CONTRACT_TYPE_SUCCESS = 'GET_CONTRACT_TYPE_SUCCESS';
export const GET_CONTRACT_TYPE_ERROR = 'GET_CONTRACT_TYPE_ERROR';

export const GET_CONTRACT_TYPES_REQUEST = 'GET_CONTRACT_TYPES_REQUEST';
export const GET_CONTRACT_TYPES_SUCCESS = 'GET_CONTRACT_TYPES_SUCCESS';
export const GET_CONTRACT_TYPES_ERROR = 'GET_CONTRACT_TYPES_ERROR';

export const ADD_CONTRACT_TYPE_REQUEST = 'ADD_CONTRACT_TYPE_REQUEST';
export const ADD_CONTRACT_TYPE_SUCCESS = 'ADD_CONTRACT_TYPE_SUCCESS';
export const ADD_CONTRACT_TYPE_ERROR = 'ADD_CONTRACT_TYPE_ERROR';

export const UPDATE_CONTRACT_TYPE_REQUEST = 'UPDATE_CONTRACT_TYPE_REQUEST';
export const UPDATE_CONTRACT_TYPE_SUCCESS = 'UPDATE_CONTRACT_TYPE_SUCCESS';
export const UPDATE_CONTRACT_TYPE_ERROR = 'UPDATE_CONTRACT_TYPE_ERROR';

export const DELETE_CONTRACT_TYPE_REQUEST = 'DELETE_CONTRACT_TYPE_REQUEST';
export const DELETE_CONTRACT_TYPE_SUCCESS = 'DELETE_CONTRACT_TYPE_SUCCESS';
export const DELETE_CONTRACT_TYPE_ERROR = 'DELETE_CONTRACT_TYPE_ERROR';


export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST'
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS'
export const DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR'

export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_ERROR = 'GET_USERS_SUCCESS'
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'


export const GET_CONTRACT_REQUEST =  'GET_CONTRACT_REQUEST'
export const GET_CONTRACT_SUCCESS = 'GET_CONTRACT_SUCCESS'
export const GET_CONTRACT_ERROR = 'GET_CONTRACT_ERROR'

export const GET_CONTRACTS_REQUEST = 'GET_CONTRACTS_REQUEST'
export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS'
export const GET_CONTRACTS_ERROR = 'GET_CONTRACTS_ERROR'
export const ADD_CONTRACT_REQUEST = 'ADD_CONTRACT_REQUEST'
export const ADD_CONTRACT_SUCCESS = 'ADD_CONTRACT_SUCCESS'
export const ADD_CONTRACT_ERROR = 'ADD_CONTRACT_ERROR'

export const DELETE_CONTRACT_REQUEST = 'DELETE_CONTRACT_REQUEST'
export const DELETE_CONTRACT_SUCCESS = 'DELETE_CONTRACT_SUCCESS'
export const DELETE_CONTRACT_ERROR = 'DELETE_CONTRACT_ERROR'

export const UPDATE_CONTRACT_REQUEST = 'UPDATE_CONTRACT_REQUEST'
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS'
export const UPDATE_CONTRACT_ERROR = 'UPDATE_CONTRACT_ERROR'

