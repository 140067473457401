import React , { useCallback, useEffect, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import {CardBody} from 'reactstrap';


export default function BrandLogPopup({ data }) {
    const [open, setOpen] = useState(false);

    const handleClose = useCallback(() =>  {
        setOpen(false);
    }, []);

    useEffect(() => {
        setOpen(true);
    },[ data ]);

    return (
        <Modal
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            id="modal"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500}}
        >
            <Fade in={open}>
                <div className="material-modal-content">
                    <h4 id="transition-modal-title" className="mb-4">Log Details
                        <i className="cil-x cursor-pointer float-right" onClick={handleClose} />
                    </h4>
                    <div id="transition-modal-description">
                        {
                            <CardBody className={'log-wrapper card-body'}>
                                <div>
                                    <h4>
                                        Title: <span>{data?.title}</span>
                                    </h4>

                                    <h4>
                                        UserID: <span>{data?.user_id}</span>
                                    </h4>

                                    <h4>
                                        Time: <span>{data?.created_at.split('T')[0]}</span>
                                    </h4>
                                </div>

                                <div className={'details'}>
                                    <h4>
                                        Details:
                                    </h4>
                                    <span>{data?.details ? JSON.stringify(data?.details) : null}</span>
                                </div>
                            </CardBody>
                        }
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}