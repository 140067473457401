import React, {Component} from 'react';
import debounce from 'lodash/debounce';
import { PropTypes, array, bool } from 'prop-types';
import Combobox from 'react-widgets/lib/Combobox';
import 'react-widgets/dist/css/react-widgets.css';
import { renderCombobox } from '../form-field/ReduxFormFields';
import { getCountry, getCountries } from '../../../actions/countryActions';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Redux Store
import configureStore from '../../redux/configureStore';
// Configuring Redux Store
const store = configureStore(window.initialState);

class CountryCombobox extends Component { 
	
	constructor(props) {
	  super(props);
	  this.state = {
      open: false,
      create: false,
      search: null,
      countries: null,
      selectedCountry: '',
      countrySearchResult: null
    }
	  this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleCountrySearch = this.handleCountrySearch.bind(this);
	}

	componentDidMount(props) {
    console.log('componentDidMount');
    console.log(props);
    const { getCountries } = this.props;
    getCountries();
  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate')
    console.log(this.props);
  }

  handleCountryChange(e) {
  	console.log('--searchable-country-change');
  	console.log(e);
    this.setState({selectedCountry: e=='' ? null : e});
    if(typeof(e.code)=='undefined') {
      this.handleCountrySearch(e);
    }
    else {
    	const search = Object.assign({}, this.state.search);
	    search['search[country]'] = encodeURIComponent(e.code);
	    this.setState({search});
	    this.props.onChange('country', e.code);
    }
  }

  handleCountrySearch = debounce((searchTerms) => {
    this.countrySearch(searchTerms);
  }, 500);

  countrySearch(searchTerms) {
    console.log('handleCountrySearch -- searchable');
    this.props.onChange('country', '');
    var params = {}
    var search = {}
    search['search[country]'] = encodeURIComponent(searchTerms.trim());
    Object.assign(params, search);
    store.dispatch(getCountries(params)).then((result) => {
      var countrySearchResult = result.payload.items.map(country => ({ code: country.code, name: country.name?.en || country.name }));
      this.setState({countrySearchResult: countrySearchResult});
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    }); 
  }

	render() {

		const { countries } = this.props;

		console.log('---render');

    var selectedCountry = '';
    if(this.state.selectedCountry==null) {
      selectedCountry = '';
    }
    else if(this.state.selectedCountry!='') {  
      selectedCountry = this.state.selectedCountry;
    }
		var countriesArr = countries.map(country => ({ code: country.code, name: country.name?.en || country.name }));
    if(this.state.countrySearchResult!=null) {
      countriesArr = this.state.countrySearchResult;
    }
    // countriesArr = countriesArr.concat({code: 0, name: '...'});
    countriesArr = [{code: 0, name: 'All Countries'}, ...countriesArr]


    return (
			<Combobox
			  name="country"
			  type="select"
			  label="Country - type to search Country e.g. Germany"
			  data={countriesArr}
			  defaultValue={selectedCountry}
			  valueField="id"
			  textField="name"
			  filter={true}
			  className={`p-0 pr-2 ${typeof(this.props.className)!='undefined' ? this.props.className : ''}`}
			  onChange={(event) => this.handleCountryChange(event)}
			  // disabled={[{code:0, name: '...'}]}
			  placeholder="All Countries"
			  asterisk="*"
			/>
		)
	}

}

function mapStateToProps(state) {
	console.log('STATE')
	console.log(state);
  return {
    countries: (typeof(state.countries)!=='undefined' && typeof(state.countries.result)!=='undefined') ? state.countries.result.items : [],
    session: state.session
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({getCountries}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountryCombobox)
