import React, {Component} from 'react';
import { Field, FieldArray } from 'redux-form'
import { Form, Input, Button, Card, CardTitle, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { renderField, renderNumberField, renderCheckbox  } from '../form-field/ReduxFormFields';

const renderSubFields = (member, index, fields) => (
  <div key={index} className="d-flex align-items-end">
    <Field
      name={`${member}.fee`}
      component={renderNumberField}
      label="Fee"
      className="form-control"
    />
    <Field
      name={`${member}.from`}
      component={renderNumberField}
      label="From"
      className="form-control"
    />
    <Field
      name={`${member}.to`}
      component={renderNumberField}
      label="To"
      className="form-control"
    />
    <div className="mb-2">
      <button
        type="button"
        title="Remove Shipping"
        onClick={() => { if(window.confirm('Are you sure you want to remove?')) { fields.remove(index)}}}
        className="btn btn-light btn-sm mb-1"
      >
        <i className="cil-x"></i>
      </button>
    </div>
  </div>
)
const renderMembers = ({ fields }) => (
  <div className="d-flex flex-column">
    {fields.map(renderSubFields)}
    <div>
      <a type="button" className="btn-link d-inline-block mb-3" onClick={() => fields.push({})}>
        <i className="cil-plus"></i> Add Shipping
      </a>
    </div>
  </div>
)  
class Shipping extends Component {

  render() {

    return (
      <FieldArray name="shipping" component={renderMembers} />
    )
  }
}

export default Shipping;