import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { PropTypes, array, bool } from 'prop-types';
import { Form, Input, Button, Badge, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialCheckbox from '../../shared/components/material-checkbox/MaterialCheckbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SimpleLineIcon from 'react-simple-line-icons';
import BrandCombobox from '../../shared/components/searchable-field/BrandCombobox';
import DataTableProgressComponent from '../../shared/components/widget/DataTableProgressComponent';
import DataTableNoDataComponent from '../../shared/components/widget/DataTableNoDataComponent';
import DropdownList from 'react-widgets/lib/DropdownList';
import 'react-widgets/dist/css/react-widgets.css';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import './Modal.css';
import './Payments.css';
import PaymentShow from './PaymentShow';

import memoize from 'memoize-one';
import DataTable, { createTheme } from 'react-data-table-component';

import { checkAuth } from '../../actions/sessionActions';
import { getPayment, getPayments } from '../../actions/paymentActions';
import { getBanks } from '../../actions/bankActions';

import { SubmissionError } from 'redux-form'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Redux Store
import configureStore from '../../shared/redux/configureStore';
import {API_URL, X_API_KEY, X_API_VERSION} from '../../conf';
import {getCookie} from '../../shared/utils/cookies';
import axios from 'axios';
import {axiosInterceptors} from '../../shared/utils/axiosInterceptors';
import {Box, Typography} from '@material-ui/core';
// Configuring Redux Store
const store = configureStore(window.initialState);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const columns = memoize((handleEditClick, handleShowClick, handleCashback) => [
  {
    name: 'ID',
    selector: 'id',
    sortable: false,
    center: true,
    grow: 0
  },
  {
    name: 'Brand',
    cell: row => <div>{row.brand!=null ? row.brand.name : '---'}</div>,
    sortable: false,
    center: false,
    grow: 1
  },
  {
    name: 'Customer',
    cell: row => <div>{row.customer_id}</div>,
    sortable: false,
    center: false,
    grow: 1
  },
  {
    name: 'Description',
    selector: 'description',
    sortable: false,
    right: false,
    grow: 3
  },
  {
    name: 'Reference',
    selector: 'reference',
    sortable: false,
    grow: 0
  },
  {
    name: 'Booked at',
    selector: "booked_at",
    sortable: true,
    cell: row => <div>{row.booked_at!=null ? row.booked_at.substring(0, 10) : ''}</div>,
    grow: 1
  },
  {
    name: 'Amount',
    selector: "amount",
    sortable: false,
    center: true,
    cell: row => <div>{row.amount}</div>,
    grow: 0
  },
  {
    name: 'Actions',
    sortable: false,
    left: true,
    cell: (row) => <div>
                     <button className="btn btn-sm btn-light mr-2" onClick={handleEditClick} id={row.id}><i className="icon-pencil" onClick={handleEditClick} id={row.id}></i></button>
                     <button className="btn btn-sm btn-light mr-2" onClick={handleShowClick} id={row.id}><i className="icon-book-open" onClick={handleShowClick} id={row.id}></i></button>
                   </div>,
    ignoreRowClick: true,
    grow: 1
  },
  {
    name: 'Refresh',
    selector: "icon",
    sortable: false,
    center: true,
    cell: row => <button style={{ border : 0}} className={'icon-refresh'} onClick={() => handleCashback(row.id)} />,
    grow: 0
  },
]);


const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

class Payments extends Component {
  constructor(props) {
    super(props);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleShowClick = this.handleShowClick.bind(this);
    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.historyStore = this.historyStore.bind(this)
    this.handleBankChange = this.handleBankChange.bind(this);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.handleCashback = this.handleCashback.bind(this)
    this.onSearchableInputChange = this.onSearchableInputChange.bind(this);
    var bankId = ((new URLSearchParams(window.location.search)).get("bank_id"));
    this.state = {
      data: [],
      loading: false,
      totalRows: 0,
      perPage: new URLSearchParams(window.location.search).get("per_page") || 10,
      page:  new URLSearchParams(window.location.search).get("page") || 1,
      sort: false,
      search: null,
      modalOpen: false,
      payment: null,
      modalTitle: null,
      error: null,
      refresh: false,
      banks: [],
      selectedBank: bankId==null ? null : parseInt(bankId),
      showSearch: false
    };

  }

  componentDidMount(props) {
    this.setState({ loading: true });
    console.log('componentDidMount');
    console.log('---accounts')
    console.log(this.props.accounts);
    this.setState({loading: false, data: []});
    const {getBanks} = this.props;
    getBanks();
    if(this.state.selectedBank!=null) {
      this.setState({showSearch: true}, function() {
        this.fetchPayments();
      });
    }
    /*
    const { perPage } = this.state;
    console.log(perPage);
    var params = {}
    params.per_page = perPage;
    store.dispatch(getPayments(params)).then((result) => {
      this.setState({
        data: result.payload.items,
        totalRows: result.payload.meta.total_items,
        loading: false,
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalTitle: 'Error!',
        modalOpen: true
      });
    });
    */


    /* JWT is stored in memory */
    /*
    const {session} = this.props
    if(typeof(session.jwt)=='undefined') {
      sessionStorage.removeItem('jwt');
      this.setState({session: null});
      this.props.history.push({pathname: '/login'});
      window.location.href="/login";
    }
    */
  }


  handleCashback(id) {
    console.log(id);

    const formData = new FormData();


    const bank_id = new URLSearchParams(window.location.search).get("bank_id")

    formData.append('bank_id', bank_id);

      const axiosData = {
        method: 'POST',
        url: API_URL + '/payments/' + id + '/grant_cashback/',
        headers: {
          //Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'X-Api-Key': X_API_KEY,
          'X-Api-Version' : X_API_VERSION,
          'Authorization': 'Bearer ' + getCookie("_str")
        },

        data: formData
      };
      const axiosInstance = axios.create();
      axiosInterceptors(axiosInstance);
      return axiosInstance(axiosData)
          .then(response => {
            const { data: { id: refreshId = '' } } = response;
            if(refreshId) {
              this.setState((item) => ({...item, refresh: true, refreshId }))
          }})
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log('AXIOS ERROR:', err.response);
          });
    }

  handleQueryChange(event) {
    console.log('handleQueryChange');
    console.log(event.target.value)
    var search = {}
    search['search[brand]'] = encodeURIComponent(event.target.value);
    this.setState({search: search});
  }

  handleFindPayments = async (e) => {
    e.preventDefault();
    console.log('handleFindPayments');
    console.log(this.state.search);
    if(this.state.search && this.state.search.toString().length>1) {
      this.setState({ loading: true });
      var params = {}
      params.per_page = this.state.perPage;
      params.bank_id = this.state.selectedBank;
      if(this.state.sort) {
        Object.assign(params, this.state.sort)
      }
      if(this.state.search) {
        Object.assign(params, this.state.search)
      }
      store.dispatch(getPayments(params)).then((result) => {
        this.setState({
          loading: false,
          data: result.payload.items,
          totalRows: result.payload.meta.total_items
      });
      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
        this.setState({
          error: error,
          modalOpen: true
        });
      });
    }
  }

  historyStore = (page, perPage) => {
    const currentUrl = this.props.history.location.pathname;

    const params = {}
    params.bank_id = this.state.selectedBank;
    params.page = page;
    params.per_page = perPage;

    const query = new URLSearchParams(params).toString();

    this.props.history.push(currentUrl + '?' + query);
  }

  handlePageChange = async page => {
    this.setState({ loading: true });
    const { perPage } = this.state;
    var params = {}

    console.log(page,'page')
    params.per_page = perPage;
    params.page = page;
    params.bank_id = this.state.selectedBank;

    this.historyStore(page,perPage)

    if(this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getPayments(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        totalRows: result.payload.meta.total_items,
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });

  }

  handlePerRowsChange = async (perPage, page) => {
    console.log('handlePerRowsChange');
    this.setState({ loading: true });
    var params = {}
    params.per_page = perPage;
    params.page = page;
    params.bank_id = this.state.selectedBank;

    this.historyStore(page,perPage)

    if(this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getPayments(params)).then((result) => {
      console.log('getPayments(params)');
      console.log(result.payload);
      this.setState({
        loading: false,
        data: result.payload.items,
        totalRows: result.payload.meta.total_items,
        perPage: perPage,
    });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleSort = async (column, sortDirection) => {
    console.log('handleSort');
    this.setState({ loading: true });
    var params = {}
    params.per_page = this.state.perPage;
    params.bank_id = this.state.selectedBank;
    var sort = {}
    sort['order['+column.selector+']'] = sortDirection;
    Object.assign(params, sort);
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getPayments(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        totalRows: result.payload.meta.total_items,
        sort: sort
    });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  };

  handleEditClick(event) {
    console.log('handleEditClick', this.state);
    this.props.history.push({
      pathname: '/payments/edit/'+event.target.id+'/'+this.state.selectedBank,
      search: window.location.search
    });
  }

  handleShowClick(event) {
    console.log('handleShowClick');
     store.dispatch(getPayment({id: event.target.id, bank_id: this.state.selectedBank})).then((result) => {
      if(typeof(result)!='undefined') {
        console.log(result);
        var payment = result.payload;
        this.setState({
          payment: payment,
          modalTitle: payment.title
        });
        this.setState({modalOpen: true});
      }
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  onSearchInputChange = (evt,field) => {
    console.log('---evt')
    console.log(evt);
    const search = Object.assign({}, this.state.search);
    if(typeof(field)!='undefined') {
      if(field=='active') {
        if(evt=='Active') {
          var evt = {
            target: {name: 'active', value: '', checked: true}
          }
        }
        if(evt=='Inactive') {
          var evt = {
            target: {name: 'active', value: '', checked: false}
          }
        }
        if(evt=='Status') {
          var evt = {
            target: {name: 'active', value: '', checked: null}
          }
        }
      }
      if(field=='matched') {
        if(evt=='No') {
          var evt = {
            target: {name: 'matched', value: '', checked: false}
          }
        }
        if(evt=='Yes') {
          var evt = {
            target: {name: 'matched', value: '', checked: true}
          }
        }
        if(evt=='Any' || evt=='Matched') {
          var evt = {
            target: {name: 'matched', value: '', checked: null}
          }
        }
      }
    }
    if(evt.target.value!='') {
      search['search['+evt.target.name+']'] = encodeURIComponent(evt.target.value);
    }
    else if(evt.target.name=='active' && evt.target.checked!=null) {
      search['search['+evt.target.name+']'] = evt.target.checked;
    }
     else if(evt.target.name=='active' && evt.target.checked==null) {
      delete search['search['+evt.target.name+']'];
    }
    else if(evt.target.name=='matched' && evt.target.checked!=null) {
      search['search['+evt.target.name+']'] = evt.target.checked;
    }
     else if(evt.target.name=='matched' && evt.target.checked==null) {
      delete search['search['+evt.target.name+']'];
    }
    else {
      delete search['search['+evt.target.name+']'];
    }
    this.setState({search});
  }

  onSearchableInputChange(name, value) {
    console.log('onSearchableInputChange');
    const search = Object.assign({}, this.state.search);
    if(value!='') {
      search['search['+name+']'] = encodeURIComponent(value);
    }
    else if(name=='active') {
      search['search['+name+']'] = value; // true/false
    }
    else {
      delete search['search['+name+']'];
    }
    this.setState({search});
  };

  handleModalOpen() {
    this.setState({modalOpen: true});
  }

  handleModalClose() {
    this.setState({modalOpen: false})
  }

  handleBankChange(e) {
    console.log('---handleBankChange');
    if(e.id!='' && e.id>0) {
      this.setState({selectedBank: e.id, showSearch: true}, function() {
        this.fetchPayments();
      });
      this.props.history.push({
        pathname: '/payments/payments/'+e.id,
        search: "?" + new URLSearchParams({bank_id: e.id}).toString()
      });
    }
    else {
      this.setState( {
        data: [],
        loading: false,
        totalRows: 0,
        perPage: 10,
        sort: false,
        search: null,
        showSearch: false,
        selectedBank: null
      });
    }
  }

  fetchPayments() {
    var params = {}
    params.per_page = this.state.perPage;
    params.page = this.state.page
    params.bank_id = this.state.selectedBank;
    var sort = {}
    if(this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if(this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getPayments(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        totalRows: result.payload.meta.total_items,
        sort: sort
    });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  render() {
    console.log('---render');

    const session = this.props.session
    if(typeof(session.forceLogout)!="undefined" && session.forceLogout==1) {
      this.setState({session: null});
      window.location.href="/login";
    }

    const { loading, data, totalRows } = this.state
    const {banks} = this.props;

    const handleChange = (state) => {
      // You can use setState or dispatch with something like Redux so we can use the retrieved data
      console.log('Selected Rows: ', state.selectedRows);
    };

    var banksArr = banks.map(bank => ({ id: bank.id, name: bank.name }));

    return (
      <div className="animated fadeIn mt-5">
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          id="modal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalOpen}>
            <div className="material-modal-content">
              <h4 id="transition-modal-title">{this.state.modalTitle} <i className="cil-x cursor-pointer float-right" onClick={this.handleModalClose}></i></h4>
              <div id="transition-modal-description">
                {this.state.error!=null
                  ? <div>{this.state.error.toString()}</div>
                  : <PaymentShow payment={this.state.payment} />
                }
              </div>
            </div>
          </Fade>
        </Modal>
        <Row>
          <Col xs="12" lg="2">
            <DropdownList filter
              placeholder="Choose Bank"
              data={[{id:'0', name: 'Choose Bank'}].concat(banksArr)}
              valueField="id"
              textField="name"
              defaultValue={{id: this.state.selectedBank!=null ? this.state.selectedBank: 0, name: (this.state.selectedBank!=null && banksArr.length>0) ? banksArr.find(b => b.id == this.state.selectedBank).name : 'Choose Bank'}}
              onChange={this.handleBankChange}
              className="mb-4"
            />
          </Col>
          <Col xs="12" lg="10">
            {/*<div className="d-flex mb-4 justify-content-end">
              <Link to="/payments/new">
                <Button color="secondary" className="bg-white text-dark" outline>
                  <i className="cil-plus text-dark"></i>
                </Button>
              </Link>
            </div>
            */}
          </Col>
          <Col xs="12" lg="12">
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center justify-content-between">
                  <div><i className="fa fa-align-justify"></i> Payments list</div>
                  {this.state.showSearch &&
                    <div>
                      <Form className="form-inline float-right text-right items-end" onSubmit={this.handleFindPayments}>
                        <div className="mr-2">
                          <label htmlFor="start_date">
                            Time Period From
                          </label>
                          <Input type="date" name="start_date"
                                 id={'start_date'}
                                 className="text-left"
                                 placeholder="Time Period From"
                                 onChange={(e)=>this.onSearchInputChange(e, 'start_date')}
                          />
                        </div>
                        <div className="mr-2">
                          <label htmlFor="start_date">
                            Time Period To
                          </label>
                          <Input type="date" name="end_date"
                                 className="text-left"
                                 onChange={(e)=>this.onSearchInputChange(e, 'end_date')}
                          />
                        </div>

                        <BrandCombobox onChange={this.onSearchableInputChange} className="w-200" />
                        <Input style={{width: 140}} type="search" name="customer" className="mr-2 text-left" placeholder="Customer" onChange={this.onSearchInputChange} />
                        <Input type="search" name="reference" className="mr-2 text-left" placeholder="Reference" onChange={this.onSearchInputChange} />
                        <DropdownList
                          name="active"
                          onChange={(e)=>this.onSearchInputChange(e, 'matched')}
                          data={['Matched', 'Any', 'Yes', 'No']}
                          placeholder="Matched"
                          className="status-dropdown text-left"
                        />
                        <a onClick={(e)=>this.handleFindPayments(e)} className="search-btn ml-1 cursor-pointer btn btn-outline-secondary"><i className="icon-magnifier"></i></a>
                      </Form>
                    </div>
                  }
                </div>
              </CardHeader>

             <div id="payments-table">
               <CardBody>
                 {
                   // (data?.length > 0) &&
                     <DataTable
                         noHeader={false}
                         columns={columns(this.handleEditClick, this.handleShowClick, this.handleCashback)}
                         // onSelectedRowsChange={this.updateState}
                         data={data}
                         theme="solarized"
                         progressPending={loading}
                         pagination
                         paginationDefaultPage={this.state.page}
                         paginationPerPage={this.state.perPage}
                         paginationServer
                         paginationTotalRows={totalRows}
                         onChangeRowsPerPage={this.handlePerRowsChange}
                         onChangePage={this.handlePageChange}
                         onSort={this.handleSort}
                         sortServer
                         selectableRows
                         selectableRowsComponent={MaterialCheckbox}
                         selectableRowsComponentProps={selectProps}
                         onSelectedRowsChange={handleChange}
                         progressComponent={<DataTableProgressComponent />}
                         noDataComponent={<DataTableNoDataComponent />}
                     />
                 }
               </CardBody>
             </div>


            </Card>
          </Col>

          <Modal
              open={this.state.refresh}
              onClose={() => this.setState(item => ({...item, refresh: false}))}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h5" component="h2">
                Cashback Granted for the payment successfully. New Transaction ID is {this.state.refreshId}
              </Typography>
            </Box>
          </Modal>
        </Row>
      </div>
    )
  }
}

Payments.propTypes = {
  data: PropTypes.array,
  banks: PropTypes.array,
  session: PropTypes.any
}

function mapStateToProps(state) {
  console.log('STATE')
    console.log(state);
    return {
      //payments: state.payments,
      data: (typeof(state.payments)!=='undefined' && typeof(state.payments.result)!=='undefined') ? state.payments.result.items : [],
      banks: (typeof(state.banks)!=='undefined' && typeof(state.banks.result)!=='undefined') ? state.banks.result.items : [],
      session: state.session
    }
  };

const mapDispatchToProps = dispatch => bindActionCreators({getPayment, getPayments, getBanks, checkAuth}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payments)