// Dependencies
import axios from 'axios';
import { API_URL, X_API_KEY } from '../conf.js';
import { getCookie } from '../shared/utils/cookies';
import { axiosInterceptors } from '../shared/utils/axiosInterceptors';

// Action Types
import {
  GET_BANK_REQUEST,
  GET_BANK_SUCCESS,
  GET_BANK_ERROR,
  GET_BANKS_REQUEST,
  GET_BANKS_SUCCESS,
  GET_BANKS_ERROR,
  ADD_BANK_REQUEST,
  ADD_BANK_SUCCESS,
  ADD_BANK_ERROR,
  UPDATE_BANK_REQUEST,
  UPDATE_BANK_SUCCESS,
  UPDATE_BANK_ERROR
} from './actionTypes';

// Base Actions
import { request, received, error } from '../shared/redux/baseActions';

const getBank = (params) => dispatch => {
  dispatch(request(GET_BANK_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: API_URL + '/banks/' + id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_BANK_SUCCESS, response.data)))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_BANK_ERROR));
    });
};

const getBanks = (params) => dispatch => {
  dispatch(request(GET_BANKS_REQUEST));
  var perPage = 10;
  var page = 1;
  console.log('GET_BANKS');
  console.log(params);
  var url = API_URL + '/banks?';
  if (typeof (params) != 'undefined') {
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    console.log(queryString);
    url += queryString;
  }
  // Axios Data
  const axiosData = {
    method: 'GET',
    url: url,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    }
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(GET_BANKS_SUCCESS, { items: response.data['items'], meta: response.data["meta"] })))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(GET_BANKS_ERROR));
    });
};

const addBank = (params) => dispatch => {
  dispatch(request(ADD_BANK_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('ADD_BANK');
  params.address_attributes = params.address
  params.contact_attributes = params.contact
  params.payments_linked = params.payments_enabled && params.payments_linked

  if ((params.skipped_brands || []).length) {
    params.skip_brand_ids = params.skipped_brands.map(item => item.id)
  }

  console.log(params);
  // Axios Data
  const axiosData = {
    method: 'POST',
    url: API_URL + '/banks',
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: params
  };

  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(ADD_BANK_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(ADD_BANK_ERROR, err.response));
    });
};

const updateBank = (params) => dispatch => {
  dispatch(request(UPDATE_BANK_REQUEST));
  var id = '';
  if (typeof (params) !== 'undefined') {
    id = params;
  }
  console.log('UPDATE_BANK')

  params = { ...params, ...{ address_attributes: params.address } }
  params = { ...params, ...{ contact_attributes: params.contact } }
  params = { ...params, ...{ payments_linked: params.payments_enabled && params.payments_linked } }

  if ((params.skipped_brands || []).length) {
    params.skip_brand_ids = params.skipped_brands.map(item => item.id)
  } else params.skip_brand_ids = [];

  // console.log(params);
  // Axios Data
  const axiosData = {
    method: 'PATCH',
    url: API_URL + '/banks/' + params.id,
    headers: {
      //Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': X_API_KEY,
      'Authorization': 'Bearer ' + getCookie("_str")
    },
    data: params
  };
  const axiosInstance = axios.create();
  axiosInterceptors(axiosInstance);
  return axiosInstance(axiosData)
    .then(response => dispatch(received(UPDATE_BANK_SUCCESS, response.data['form'])))
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log('AXIOS ERROR:', err.response);
      dispatch(error(UPDATE_BANK_ERROR, err.response));
    });
};

const resetBank = () => dispatch => {
  dispatch(received(ADD_BANK_SUCCESS, []))
};

export { getBank, getBanks, addBank, updateBank, resetBank };
