import React, {useState} from 'react';
import DataTable from 'react-data-table-component';
import BrandLogPopup from './BrandLogPopup';

function Log({data}) {
    const [rowList, setRowList ]  = useState({});

    return (
        <div className={'log'}>
            <DataTable
                theme="solarized"
                columns={[
                    {
                        name: 'Title',
                        selector: row => row.title,
                    },
                    {
                        name: 'UserID',
                        selector: row => row.user_id,
                    },
                    {
                        name: 'Time',
                        selector: row => row.created_at.split('T')[0],
                    },
                    {
                        name: 'Details',
                        selector: row => JSON.stringify(row.details),
                    },
                ]}
                data={data?.items}
                pagination
                paginationTotalRows={data?.meta?.total_items}
                onRowClicked={(e) => setRowList(e)}
                paginationRowsPerPageOptions={[10, 50, 100]}
            />
            {
                Object.values(rowList).length > 0 && <BrandLogPopup data={rowList}/>
            }
        </div>
    );
}

export default React.memo(Log);