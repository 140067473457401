import React, {PropTypes, Component} from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import { logInUser } from '../../actions/sessionActions';
import { getAccountDetails } from '../../actions/accountActions';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { AppAsideToggler, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../shared/images/logo.png'
import { BrowserRouter, Route, Link } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import Home from '../home/Home';
import './LogInPage.css';

class LogInPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      credentials: {message: '', email: '', password: ''},
      childBrand: false
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.keyPress = this.keyPress.bind(this);
    document.body.classList.add(localStorage.getItem('theme')==null ? 'dark-mode' : localStorage.getItem('theme')+'-mode');
  }

  onChange(event) {
    const field = event.target.name;
    const credentials = this.state.credentials;
    credentials[field] = event.target.value;
    return this.setState({credentials: credentials});
  }

  onSubmit(event) {
    event.preventDefault();
    const {logInUser} = this.props;
    logInUser(this.state.credentials);
    let isLoggedIn = this.props.logged_in;
    if (isLoggedIn.error && !isLoggedIn.success) {
      console.log(isLoggedIn.error);
      this.setState({message: isLoggedIn.error});
    }
  }

  componentDidUpdate() {
    let isLoggedIn = this.props.logged_in;
    if(isLoggedIn && isLoggedIn.error==null && !this.state.getAccountDetails) {
      const {getAccountDetails} = this.props;
      getAccountDetails();
      this.setState({ getAccountDetails: true });
    }
  }

  keyPress(e){
      if(e.keyCode == 13){
         this.onSubmit(e);
      }
   }

  render() {

    const isLoggedIn = this.props.logged_in;
    const account = this.props.account;
    let button;

    if (isLoggedIn.error && !isLoggedIn.success) {
      //console.log(isLoggedIn.error);
    }

    if(isLoggedIn.success && account.success && (account.user.role === 'SuperAdmin' || account.user.role === 'SubAdmin')) {
      this.state.childBrand = false;
      return (
          <Redirect to='/' />
          // <BrowserRouter>
          //   <Route path='/' component={Home} />
          // </BrowserRouter>
      )
    } else if(isLoggedIn.success && account.success) {
      this.state.childBrand = true;
    }

    // if(isLoggedIn.success && account.success) {
    //   return <Redirect to='/' />
    //   return (
    //       <BrowserRouter>
    //         <Route path='/' component={Home} />
    //       </BrowserRouter>
    //   )
    // }
    return (

      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup className="login-container">
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <h1>Benfido CLO</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <p>{this.state.message}</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-envelope"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" autoFocus={true} placeholder="Email" onKeyDown={this.keyPress} name="email" autoComplete="username" onChange={this.onChange}/>
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Password" onKeyDown={this.keyPress} name="password" autoComplete="current-password" onChange={this.onChange}/>
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4" onClick={this.onSubmit}>Sign in</Button>
                        </Col>
                      </Row>
                      <Row>
                        {
                          this.state?.childBrand &&
                              <Col xs="12" className="mt-2" style={{ position: 'absolute'}}>
                                You do not have access
                                {/*<Button color="link" className="px-0">Forgot password?</Button>*/}
                              </Col>
                        }
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none login-right" style={{ width: '44%' }}>
                  <CardBody className="text-center">
                    <div className="login-page-logo">
                      <AppNavbarBrand
                        full={{ src: logo, width: 130, height: 30, alt: 'Benfido Logo' }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
  );
  }
}
function mapStateToProps(state) {
  console.log(state);
  return {
    isMobile: state.device.isMobile,
    logged_in: state.session,
    account: state.accounts
  };
}
const mapDispatchToProps = dispatch => bindActionCreators({logInUser, getAccountDetails}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogInPage));
