import React from 'react';
import {setCookie, getCookie} from '../../utils/cookies';



class Countdown extends React.Component {

  constructor(props) {
    super(props);
    this.state = {countdown: null, seconds: props.seconds};
    this.countdown = this.countdown.bind(this);
  }

  componentDidMount() {
    this.countdown(this.props.seconds);
  }

  componentDidUpdate(prevProps) {
    if(this.state.seconds != prevProps.seconds) {
      //this.setState({seconds: this.props.seconds});
      //this.countdown(this.props.seconds);
    }
  }

  countdown = (seconds) => {

    // Update the count down every 1 second
    var x = setInterval( () => {

    // Set the date we're counting down to
    //var countDownDate = new Date("Jan 5, 2021 15:37:25").getTime();
  
    var countDownDate = seconds;

    countDownDate = getCookie('_strexp')*1000;

      // Get today's date and time
      var now = new Date().getTime();
        
      // Find the distance between now and the count down date
      var distance = countDownDate - now;
        
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
      // Output the result in an element with id="demo"
      this.setState({countdown: minutes + "m " + seconds + "s "});
        
      // If the count down is over, write some text 
      if (distance < 0) {
        clearInterval(x);
        this.setState({countdown: 'EXPIRED'});
        window.location.replace("/login");
      }
    }, 1000);

  }


  render() {
    return (
      <div>{this.state.countdown}</div>
    )
  }


}

export default Countdown;