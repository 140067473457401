import React, {Component} from 'react';
import { Field } from 'redux-form'
import { Form, Input, Button, Card, CardTitle, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { renderField, renderTextareaField, renderSelectField, renderCheckbox } from '../form-field/ReduxFormFields';


class Address extends Component {

  render() {
    return (
			<Card>
				<CardBody>
					<CardTitle>Address</CardTitle>
					<Field
					  name="title"
					  type="text"
					  component={renderField}
					  label="Title"
					  className="form-control"
					/>
					<Field
					  name="street"
					  type="text"
					  component={renderField}
					  label="Street"
					  className="form-control"
					/>
					<Row>
					  <Col xs="4">
					    <Field
					      name="zip"
					      type="text"
					      component={renderField}
					      label="ZIP"
					      className="form-control"
					    />
					  </Col>
					  <Col xs="8">
					    <Field
					      name="city"
					      type="text"
					      component={renderField}
					      label="City"
					      className="form-control"
					    />
					  </Col>
					</Row>
					<Field
					  name="country"
					  type="text"
					  component={renderField}
					  label="Country"
					  className="form-control"
					/>
				</CardBody>
			</Card>
    )
  }
}

export default Address;