import React, {memo, useCallback, useEffect, useState, useRef} from 'react';
import {Card, CardBody, Col, Row} from 'reactstrap';
import AddBlockList from '../AddBlockList';
import Checkbox from '@material-ui/core/Checkbox';
import {translateApi} from '../../shared/utils/translateApi';

function BrandInfo({brand, newBrand = false, getDataChild, error = {}}) {
    const desEn = useRef(null);

    let {
        active = false,
        aliases = [],
        description_translations = {},
        approval_duration = '',
        name = ''
    } = brand

    const [inputValues, setInputValues] = useState({
        active,
        aliases,
        description_translations,
        approval_duration,
        name
    });

    const getAliases = useCallback((aliases) => {
        setInputValues(item => ({...item, aliases}))
    }, [])

    const handleTranslate = useCallback(async (e) => {
        e.preventDefault();

        const text = inputValues['description[de]'] || description_translations?.de

        const myHeaders = new Headers();
        myHeaders.append("accept", "*/*");

        const formdata = new FormData();
        formdata.append("key", "318ae2802b754541b3fd2a1ac403c525");
        formdata.append("text", text);
        formdata.append("target_lang", "en");

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
        };

        const request = await fetch(`https://brandportal.benfido.io/actions/base-module/api/translate`, requestOptions);

        const { translations } = await request.json();

        desEn.current.value = translations[0]?.text || ''


        setInputValues(item => ({...item, 'description[en]' : desEn.current.value }));

    }, [inputValues, desEn, brand]);

    const handleInputChange = useCallback(e => {
        e.persist();
        const {target} = e;
        if (!target) return
        const {name, value, checked} = target;
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: name === 'active' ? checked : value,
        }));
    }, [inputValues]);


    useEffect(() => Object.keys(inputValues).length > 0 && getDataChild(inputValues), [inputValues])

    aliases = !Array.isArray(aliases) ? [aliases] : aliases
    //
    // if(newBrand && Object.keys(brand).length > 0) {
    //     description_translations.de = description_translations.de
    //     'description_translations[en]' = description_translations.en
    //
    // }

    return (
        <Card>
            <CardBody>
                <div className="d-inline card-title">Brand</div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="mb-2 mr-2">
                            <label>Brand Name <span className="text-danger">*</span></label>
                            <div>
                                <input name="name" onChange={handleInputChange} type="text" defaultValue={name}
                                       className="form-control"/>
                                {(Object.keys(error).length > 0 && error['name']) &&
                                    <span style={{
                                        position: 'absolute',
                                        marginTop: '-4px',
                                        color: 'red'
                                    }}> {error['name'][0]} </span>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <AddBlockList
                    getData={getAliases}
                    data={aliases}
                    title={'Aliases'}
                />

                <div className="mb-2 mr-2">
                    <div className={'flex mb-2 align-items-center'}>
                        <label style={{
                            margin: 0
                        }}>Description DE </label>
                        {
                            (description_translations?.de || inputValues['description[de]']) &&
                            <span className={'btn btn-primary ml-3'} onClick={handleTranslate}>Translate</span>
                        }
                    </div>
                    <div>
                      <textarea name="description[de]"
                                onChange={handleInputChange}
                                defaultValue={description_translations?.de}
                                placeholder="Description DE"
                                className="form-control"/>
                    </div>
                </div>
                <div className="mb-2 mr-2">
                    <label>Description EN </label>
                    <div>
                        <textarea onChange={handleInputChange} ref={desEn} name="description[en]"
                                  placeholder="Description EN" defaultValue={description_translations?.en || ''}
                                  className="form-control"/>
                    </div>
                </div>
                <label>
                    Active:
                    <Checkbox
                        onChange={handleInputChange}
                        name={'active'}
                        defaultChecked={active}
                    />
                </label>
                <div className="row">
                    <div className="col-12">
                        <div className="mb-2 mr-2">
                            <label>Approval Duration </label>
                            <div>
                                <input onChange={handleInputChange} name="approval_duration" type="text"
                                       defaultValue={approval_duration} className="form-control"/>
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default memo(BrandInfo);