import {API_URL, X_API_KEY, X_API_VERSION} from '../conf';
import {getCookie} from '../shared/utils/cookies';
import axios from 'axios';
import {axiosInterceptors} from '../shared/utils/axiosInterceptors';
import {error, received, request} from '../shared/redux/baseActions';
import {
    ADD_BRAND_ERROR,
    ADD_BRAND_REQUEST, ADD_BRAND_SUCCESS,
    GET_BANKS_ERROR,
    GET_BANKS_REQUEST, GET_BANKS_SUCCESS,
    GET_BRANDS_ERROR,
    GET_BRANDS_REQUEST,
    GET_BRANDS_SUCCESS,
    GET_CATEGORIES_ERROR,
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_SUCCESS, GET_CONTRACT_TYPES_ERROR,
    GET_CONTRACT_TYPES_REQUEST, GET_CONTRACT_TYPES_SUCCESS,
    UPDATE_BRAND_ERROR,
    UPDATE_BRAND_REQUEST,
    UPDATE_BRAND_SUCCESS
} from './actionTypes';
import {getStorageItem, setStorageItem} from '../shared/utils/localStorage';

const getUpdateBrand = (id) => {
    // Axios Data
    const axiosData = {
        method: 'GET',
        url: API_URL + '/brands/' + id,
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': X_API_KEY,
            'X-Api-Version' : X_API_VERSION,
            'Authorization': 'Bearer ' + getCookie("_str")
        }
    };
    const axiosInstance = axios.create();
    axiosInterceptors(axiosInstance);
    return axiosInstance(axiosData)
        .then(response => (response.data))
        .catch(err => {
            console.log('AXIOS ERROR:', err.response);
            return err.response
        });
};

const getUsersList = (paramsObject) => {
    function objectToQueryString(obj) {
        let queryString = '';
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (queryString.length > 0) {
                    queryString += '&';
                }
                queryString += encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
            }
        }
        return queryString;
    }

    let queryParams = objectToQueryString(paramsObject);
    // Axios Data
    const axiosData = {
        method: 'GET',
        url: API_URL + `/brand_admins?` + queryParams,
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': X_API_KEY,
            'X-Api-Version' : X_API_VERSION,
            'Authorization': 'Bearer ' + getCookie("_str")
        }
    };
    const axiosInstance = axios.create();
    axiosInterceptors(axiosInstance);
    return axiosInstance(axiosData)
        .then(response => (response.data))
        .catch(err => {
            console.log('AXIOS ERROR:', err.response);
            return err.response
        });
};

const updateUser = (id) => {
    // Axios Data
    const axiosData = {
        method: 'GET',
        url: API_URL + '/brands/' + id,
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': X_API_KEY,
            'X-Api-Version' : X_API_VERSION,
            'Authorization': 'Bearer ' + getCookie("_str")
        }
    };
    const axiosInstance = axios.create();
    axiosInterceptors(axiosInstance);
    return axiosInstance(axiosData)
        .then(response => (response.data))
        .catch(err => {
            console.log('AXIOS ERROR:', err.response);
            return err.response
        });
};



const getContractTypes = () => {
    let url = API_URL + '/contract_types?';
    // Axios Data
    const axiosData = {
        method: 'GET',
        url: url,
        headers: {
            //Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Api-Key': X_API_KEY,
            'X-Api-Version' : X_API_VERSION,
            'Authorization': 'Bearer ' + getCookie("_str")
        }
    };
    const axiosInstance = axios.create();
    axiosInterceptors(axiosInstance);
    // If everything is correct we dispatch our received action
    // otherwise our error action.
    return axiosInstance(axiosData)
        .then(response => {
            return response.data['items']
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log('AXIOS ERROR:', err.response || err);
        });
};

const updateBrandList = (params) => {
    const formData = new FormData();

    if (params['description[de]'] || params['description[de]'] === '') {
        formData.append('description[de]', params['description[de]'])
    } else if(params?.description_translations?.de) {
        formData.append('description[de]',params.description_translations.de)
    }

    if (params['description[en]'] || params['description[en]'] === '') {
        formData.append('description[en]', params['description[en]'])
    } else if(params?.description_translations?.en) {
        formData.append('description[en]',params.description_translations.en)
    }

    delete params.description_translations

    for (const key in params) {
        if (key === 'contract types') {
            params[key].forEach(item => {
                formData.append(`contractTypes[][id]`, item.id);
                formData.append(`contractTypes[][name]`, item.name);
                formData.append(`contract_types[][id]`, item.id);
                formData.append(`contract_types[][name]`, item.name);
                formData.append(`contract_type_ids[]`, item.id);
            })
        }

        if(key === 'active' && !params.active) {
            formData.append('active', params.active);
        }

        if(key === 'meinplus' && !params.meinplus) {
            formData.append('meinplus', params.meinplus);
        }

        if (key === 'tags') {
            const tabsName = params[key].filter(item => !item.delete).map(child => child.name).join();
            formData.append('tags', tabsName);
        }

        if (key === 'aliases') {
            params[key].forEach((item, i) => {
                if (!item.status && !item.delete) {
                    formData.append(`aliases[${i}][id]`, item.id);
                    formData.append(`aliases[${i}][name]`, item.name);
                }

                if (item.delete) {
                    formData.append(`aliases[${i}][id]`, item.id);
                    formData.append(`aliases[${i}][_destroy]`, true);
                }

                if (item.status) {
                    formData.append(`aliases[${i}][name]`, item.name);
                }
            });
        } else {
            // eslint-disable-next-line no-unused-expressions
            (key === 'contract types' || key === 'categories' || key === 'tags' || key === 'terminal_ids') ? null : params[key] ? formData.append(key, params[key]) : null;
        }

        if (key === 'categories') {
            params[key].forEach((item, i) => {
                formData.append(`categories[][id]`, item.id);
                formData.append(`categories[][name]`, item.name);
                formData.append(`category_ids[]`, item.id);
            });
        }

        if (key === 'terminal_ids') {
            params[key].forEach((item, i) => {
                formData.append(`terminal_ids[]`, item);
            });
        }
    }


    console.log(params);


    const axiosData = {
        method: 'PATCH',
        url: API_URL + '/brands/' + params.id,
        headers: {
            //Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'X-Api-Key': X_API_KEY,
            'X-Api-Version': X_API_VERSION,
            'Authorization': 'Bearer ' + getCookie("_str")
        },
        data: formData
    };
    const axiosInstance = axios.create();
    axiosInterceptors(axiosInstance);
    return axiosInstance(axiosData)
        .then(response => (response))
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log('AXIOS ERROR:', err.response);
        });
};

const getBrands = (name) => {
    const getParams = name ? `&search[name]=${encodeURIComponent(name)}` : ''
    let url = API_URL + `/brands?per_page=100${getParams}`;

    // Axios Data
    const axiosData = {
        method: 'GET',
        url: url,
        headers: {
            //Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Api-Key': X_API_KEY,
            'X-Api-Version' : X_API_VERSION,
            'Authorization': 'Bearer ' + getCookie("_str")
        }
    };
    const axiosInstance = axios.create();
    axiosInterceptors(axiosInstance);
    // If everything is correct we dispatch our received action
    // otherwise our error action.
    return axiosInstance(axiosData)
        .then(response => ( response.data['items'] ))
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log('AXIOS ERROR:', err.response);
        });
};

const getCategories = (params) => {
    var url = API_URL + '/categories?';
    // Axios Data
    const axiosData = {
        method: 'GET',
        url: url,
        headers: {
            //Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Api-Key': X_API_KEY,
            'X-Api-Version' : X_API_VERSION,
            'Authorization': 'Bearer ' + getCookie("_str")
        }
    };
    const axiosInstance = axios.create();
    axiosInterceptors(axiosInstance);
    return axiosInstance(axiosData)
        .then(response => {
            return response.data['items']
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log('AXIOS ERROR:', err.response);
        });
};

const getBanks = () => {
    let url = API_URL + '/banks?';
    // Axios Data
    const axiosData = {
        method: 'GET',
        url: url,
        headers: {
            //Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Api-Key': X_API_KEY,
            'Authorization': 'Bearer ' + getCookie("_str")
        }
    };
    const axiosInstance = axios.create();
    axiosInterceptors(axiosInstance);
    return axiosInstance(axiosData)
        .then(response =>  response.data['items'])
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log('AXIOS ERROR:', err.response);
        });
};

const addBrand = (params) => {
    const formData = new FormData();

    if (params['description[de]'] || params['description[de]'] === '') {
        formData.append('description[de]', params['description[de]'])
    } else if(params.description_translations.de) {
        formData.append('description[de]',params.description_translations.de)
    }

    if (params['description[en]'] || params['description[en]'] === '') {
        formData.append('description[en]', params['description[en]'])
    } else if(params.description_translations.en) {
        formData.append('description[en]',params.description_translations.en)
    }

    delete params.description_translations

    for (const key in params) {
        if(key === 'contract types') {
            params[key].forEach(item => {
                formData.append(`contractTypes[][id]`, item.id);
                formData.append(`contractTypes[][name]`, item.name);
                formData.append(`contract_types[][id]`, item.id);
                formData.append(`contract_types[][name]`, item.name);
                formData.append(`contract_type_ids[]`, item.id);
            })
        }

        if (key === 'tags') {
            const tabsName = params[key].filter(item => !item.delete).map(child => child.name).join();
            console.log(tabsName);
            formData.append('tags', tabsName);
        }

        if (key === 'aliases') {
            params[key].forEach((item, i) => {
                if(!item.status && !item.delete) {
                    formData.append(`aliases[${i}][id]`, item.id);
                    formData.append(`aliases[${i}][name]`, item.name);
                }

                if (item.delete) {
                    formData.append(`aliases[${i}][id]`, item.id);
                    formData.append(`aliases[${i}][_destroy]`, true);
                }

                if (item.status) {
                    formData.append(`aliases[${i}][name]`, item.name);
                }
            });
        } else {
            // eslint-disable-next-line no-unused-expressions
            (key === 'contract types' || key === 'categories' || key === 'tags' || key === 'terminal_ids' ) ? null : params[key] ? formData.append(key, params[key]) : null;
        }

        if(key === 'categories') {
            params[key].forEach((item, i) => {
                formData.append(`categories[][id]`, item.id);
                formData.append(`categories[][name]`, item.name);
                formData.append(`category_ids[]`, item.id);
            });
        }

        if(key === 'terminal_ids') {
            params[key].forEach((item, i) => {
                formData.append(`terminal_ids[]`, item);
            });
        }
    }

    const axiosData = {
        method: 'POST',
        url: API_URL + '/brands',
        headers: {
            //Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'X-Api-Key': X_API_KEY,
            'X-Api-Version' : X_API_VERSION,
            'Authorization': 'Bearer ' + getCookie("_str")
        },
        data: formData
    };
    const axiosInstance = axios.create();
    axiosInterceptors(axiosInstance);
    return axiosInstance(axiosData)
        .then(response =>  (response))
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log('AXIOS ERROR:', err.response);
        });
};

const createEditAdmin = (data, option = '') => {
    const { id = '' } = data

    console.log(id);

    let url = API_URL + '/brand_admins/' + (id ? id : '');

    console.log(data, url);

    const formData = new FormData();

    for (const key in data) {
        console.log(key, data[key]);
        formData.append(key, data[key])
    }

    // Axios Data
    const axiosData = {
        method: option ? 'POST' : 'PATCH',
        url,
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Api-Key': X_API_KEY,
            'X-Api-Version' : X_API_VERSION,
            'Authorization': 'Bearer ' + getCookie("_str")
        },
        data: formData
    };
    const axiosInstance = axios.create();
    axiosInterceptors(axiosInstance);
    // If everything is correct we dispatch our received action
    // otherwise our error action.
    return axiosInstance(axiosData)
        .then(response => {
            return response
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log('AXIOS ERROR:', err.response || err);
        });
}

const deleteAdmin = (id) => {


    let url = API_URL + '/brand_admins/' + id;


    // const formData = new FormData();
    //
    // for (const key in data) {
    //     console.log(key, data[key]);
    //     formData.append(key, data[key])
    // }

    // Axios Data
    const axiosData = {
        method: 'DELETE',
        url,
        headers: {
            // 'Content-Type': 'multipart/form-data',
            'X-Api-Key': X_API_KEY,
            'X-Api-Version' : X_API_VERSION,
            'Authorization': 'Bearer ' + getCookie("_str")
        },
    };
    const axiosInstance = axios.create();
    axiosInterceptors(axiosInstance);
    // If everything is correct we dispatch our received action
    // otherwise our error action.
    return axiosInstance(axiosData)
        .then(response => {
            return response
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.log('AXIOS ERROR:', err.response || err);
        });
}

export { deleteAdmin, createEditAdmin, getUsersList, updateUser,  getBanks, getCategories, getContractTypes, getUpdateBrand, updateBrandList, getBrands, addBrand }