import React, {Component} from 'react';
import { Field } from 'redux-form'
import { Form, Input, Button, Card, CardTitle, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { renderField, renderTextareaField, renderSelectField, renderCheckbox } from '../form-field/ReduxFormFields';


class Contact extends Component { 
  
  render() {
    return (
      <Card>
        <CardBody>
          <CardTitle>Contact</CardTitle>
          <Row>
            <Col xs="12" lg="6"> 
              <Field
                name="position"
                type="text"
                component={renderField}
                label="Position"
                className="form-control"
              />
            </Col>
            <Col xs="12" lg="6"> 
              <Field
                name="title"
                type="text"
                component={renderField}
                label="Title"
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <Field
                name="first_name"
                type="text"
                component={renderField}
                label="First name"
                className="form-control"
              />
            </Col>
            <Col xs="12" lg="6">
              <Field
                name="last_name"
                type="text"
                component={renderField}
                label="Last name"
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <Field
                name="email"
                type="text"
                component={renderField}
                label="Email"
                className="form-control"
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="6">
              <Field
                name="phone"
                type="text"
                component={renderField}
                label="Phone"
                className="form-control"
              />
            </Col>
            <Col xs="12" lg="6">
              <Field
                name="fax"
                type="text"
                component={renderField}
                label="Fax"
                className="form-control"
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}

export default Contact;